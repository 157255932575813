import {
  AccountAnswerableModel,
  AccountJuridicalModel,
  AccountNaturalModel,
} from '@app/core/accounts';
import { FileItem } from '@app/core/models';
import { createAction, props } from '@ngrx/store';
import { Register } from '../models';

export const register = createAction('register', props<Register>());

export const registerClear = createAction('register/clear');

export const updateAccount = createAction(
  'register/updateAccount',
  props<{ idAccount: string; idPerson: string }>()
);

export const updateAccountAnswerable = createAction(
  'register/updateAccountAnswerable',
  props<{ idPersonAnswerable: string }>()
);

export const files = createAction(
  'register/files',
  props<{ files: Array<FileItem> }>()
);

// Natural

export const natural = createAction(
  'register/natural',
  props<{ data: AccountNaturalModel }>()
);

export const naturalTermOfUse = createAction(
  'register/natural/termofuse',
  props<{ termofuse: boolean; data: AccountNaturalModel }>()
);

export const naturalTaxId = createAction(
  'register/natural/taxid',
  props<{ taxId: string; authorize: boolean; referralId: string | undefined}>()
);

export const naturalData = createAction(
  'register/natural/data',
  props<{
    id: string;
    personId: string;
    status: string;
    name: string;
    taxId: string;
    birth: Date;
    mother: string;
    nationality: string;
    gender: string | undefined;
    pep: boolean;
    phoneId: string;
    phone: string | undefined;
    phoneValidated: boolean;
    contactId: string;
    contact: string | undefined;
    contactValidated: boolean;
  }>()
);

export const naturalAddress = createAction(
  'register/natural/address',
  props<{
    id: string,
    zipCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
  }>()
);

//TODO: Remover
export const naturalSelf = createAction(
  'register/natural/self',
  props<{ self: FileItem }>()
);

// Jurídico

export const juridical = createAction(
  'register/juridical',
  props<{ data: AccountJuridicalModel }>()
);

export const juridicalTermOfUse = createAction(
  'register/juridical/termofuse',
  props<{
    termofuse: boolean;
    data: AccountJuridicalModel;
    answerable: AccountAnswerableModel | undefined;
  }>()
);

export const juridicalTaxId = createAction(
  'register/juridical/taxid',
  props<{ taxId: string; authorize: boolean; referralId: string | undefined }>()
);

export const juridicalData = createAction(
  'register/juridical/data',
  props<{
    id: string;
    personId: string;
    status: string;
    acceptedTerm: boolean;
    name: string;
    birth: Date;
    tradeName: string;
    taxRegime: string;
    legalNature: string;
    registration: string;
    phoneId: string;
    phone: string | undefined;
    phoneValidated: boolean;
    contactId: string;
    contact: string | undefined;
    contactValidated: boolean;
  }>()
);

export const juridicalAddress = createAction(
  'register/juridical/address',
  props<{
    id: string,
    zipCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
  }>()
);

export const answerable = createAction(
  'register/juridical/answerable',
  props<AccountAnswerableModel>()
);

export const answerableTaxId = createAction(
  'register/juridical/answerable/taxid',
  props<{ taxId: string; authorize: boolean }>()
);

export const answerableAccount = createAction(
  'register/juridical/termofuse',
  props<{ accountId: string; personId: string }>()
);

export const answerableData = createAction(
  'register/juridical/answerable/data',
  props<{
    personId: string;
    name: string;
    birth: Date;
    mother: string;
    nationality: string | undefined | null;
    gender: string;
    pep: boolean;

    phoneId: string;
    phone: string;
    phoneValidated: boolean;

    contactId: string;
    contact: string;
    contactValidated: boolean;
  }>()
);

export const answerableAddress = createAction(
  'register/juridical/answerable/address',
  props<{
    zipCode: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
  }>()
);
