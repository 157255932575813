import { Component, OnInit } from '@angular/core';

import { AuthMenu, Config } from '@app/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-main-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  theme: string = environment.theme;
  name: string = environment.nameMerchant;
  recommendationEnable: boolean = false;
  consolidationEnabled: boolean = false;
  acquirerEnabled: boolean = false;
  apiEnabled: boolean = false;

  version: string = `v${environment.version}`;

  constructor(private store: Store<{ config: Config }>) {}

  ngOnInit(): void {
    this.store.select('config').subscribe({
      next: (model) => {
        this.recommendationEnable = model.transactions?.recommendationEnable; 
        this.consolidationEnabled = model.consolidationEnable;
        this.acquirerEnabled = model.acquirerEnable;
        this.apiEnabled = model.apiEnable;
        this._menus = this.getMenus;
      },
    });
  }

  _menus: Array<AuthMenu> | undefined;
  get menus(): Array<AuthMenu> {
    return this._menus ?? [];
  }

  private get getMenus(): Array<AuthMenu> {
    return [
      {
        enabled: true,
        icon: 'home',
        description: 'Página Inicial',
        title: 'Início',
        route: '/account/home',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: true,
        icon: 'extract',
        description: 'Extrato',
        title: 'Extrato',
        route: '/account/transactions/extract',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: true,
        icon: 'pix',
        description: 'PIX',
        title: 'PIX',
        route: '/account/pix',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: true,
        icon: 'transfer_h',
        description: 'Transferência',
        title: 'Transferência',
        route: '/account/transactions/transfer',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: true,
        icon: 'payment',
        description: 'Pagamento',
        title: 'Pagamento',
        route: '/account/transactions/payment',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: true,
        icon: 'billing',
        description: 'Cobrança',
        title: 'Cobrança',
        route: '/account/transactions/billing',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: this.acquirerEnabled,
        icon: 'acquirer',
        description: 'Adquirência',
        title: 'Adquirência',
        route: '/account/acquirers',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: this.consolidationEnabled,
        icon: 'consolidation',
        description: 'Consolidação',
        title: 'Consolidação',
        route: '/account/consolidations',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      },
      {
        enabled: this.apiEnabled,
        icon: 'api',
        description: 'API',
        title: 'API',
        route: undefined,
        hasRoute: false,
        hasChildren: true,
        children: [
          {
            enabled: true,
            icon: 'credential',
            description: 'Credencial',
            title: 'Credencial',
            route: '/account/credentials',
            hasRoute: true,
            hasChildren: false,
            children: undefined,
          },
          {
            enabled: true,
            icon: 'webhook',
            description: 'Webhooks',
            title: 'Webhooks',
            route: '/account/webhooks',
            hasRoute: true,
            hasChildren: false,
            children: undefined,
          },
        ],
      },
      {
        enabled: this.recommendationEnable,
        icon: 'hand-point-right',
        description: 'Indique um amigo',
        title: 'Indique um amigo',
        route: '/account/indicate',
        hasRoute: true,
        hasChildren: false,
        children: undefined,
      }
    ];
  }
}
