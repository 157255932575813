import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { DateParserFormatter } from '@app/shared/adapters';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskService } from 'ngx-mask';

@Component({
  selector: 'app-calendar',
  template: `
    <div class="input-group">
      <input
        class="form-control"
        type="text"
        [displayMonths]="displayMonths"
        [navigation]="navigation"
        [outsideDays]="outsideDays"
        [showWeekNumbers]="showWeekNumbers"
        ngbDatepicker
        #dueDate="ngbDatepicker"
        [minDate]="minDatepicker"
        [maxDate]="maxDatepicker"
        [(ngModel)]="modelDatepicker"
        [placeholder]="placeholderDatepicker"
        [disabled]="disabled"
        [readOnly]="readonly"
      />
      <button
        class="btn btn-outline-secondary bi bi-calendar3"
        (click)="dueDate.toggle()"
        type="button"
      >
        <i class="fas fa-calendar-alt"></i>
      </button>
    </div>
  `,
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarControl),
      multi: true,
    },
  ],
})
export class CalendarControl implements ControlValueAccessor {
  constructor(private maskService: NgxMaskService) {}

  minDatepicker: NgbDate | null = null;
  maxDatepicker: NgbDate | null = null;
  placeholderDatepicker: string = 'DD/MM/YYYY';

  _model: NgbDate | null = null;

  set modelDatepicker(value: any) {
    //console.debug('modelDatepicker', value, typeof value);
    this._model = null;
    switch (typeof value) {
      case 'string':
        value = this.maskService.applyMask(value, '00/00/0000');
        var split = value.split('/');
        if (split.length === 3 && split[2].length === 4) {
          this._model = {
            day: parseInt(split[0], 10),
            month: parseInt(split[1], 10),
            year: parseInt(split[2], 10),
          }as NgbDate;
        }
        break;
      case 'object':
        this._model = value;
        break;
    }
    if (this._model) {
      const date = this.ngbDateToDate(this._model);
      this.onChange(date);
    }
  }

  get modelDatepicker(): NgbDate | null {
    return this._model;
  }

  @Input() displayMonths: number = 1;
  @Input() navigation: string = 'select';
  @Input() outsideDays: string = 'visible';
  @Input() showWeekNumbers: boolean = false;

  @Input() set minDate(value: Date | null | undefined) {
    switch (typeof value) {
      case 'object':
        this.minDatepicker = value ? this.dateToNgbDate(value) : null;
        break;
      default:
        this.minDatepicker = null;
        break;
    }
  }

  @Input() set maxDate(value: Date | null) {
    switch (typeof value) {
      case 'object':
        this.maxDatepicker = value ? this.dateToNgbDate(value) : null;
        break;
      default:
        this.maxDatepicker = null;
        break;
    }
  }

  @Input() set placeholder(value: string) {
    this.placeholderDatepicker = value;
  }

  @Input('readOnly') readonly: boolean = false;

  @Output() change = new EventEmitter<Date | null>();

  onChange = (value: any) => {};
  onTouched = (value: any) => {};
  disabled = false;

  writeValue(value: any): void {
    //console.debug('writeValue', value, typeof value);
    if (!value) return;
    switch (typeof value) {
      case 'string':
        var split = value.split('-');
        if (split.length === 3 && split[0].length === 4) {
          this._model = {
            day: parseInt(split[2], 10),
            month: parseInt(split[1], 10),
            year: parseInt(split[0], 10),
          } as NgbDate;
        }
        break;
      case 'object':
        this._model = this.dateToNgbDate(value);
        break;
    }
    if (this._model) {
      const date = this.ngbDateToDate(this._model);
      this.onChange(date);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  dateToNgbDate(value: Date): NgbDate {
    const day = value.getDate();
    const month = value.getMonth() + 1;
    const year = value.getFullYear();
    const date = { year: year, month: month, day: day } as NgbDate;
    return date;
  }

  ngbDateToDate(value: NgbDateStruct): Date | null | undefined {
    const str = `${String(value.year).padStart(4, '0')}-${String(
      value.month
    ).padStart(2, '0')}-${String(value.day).padStart(2, '0')}T12:00:00`;
    const parse = Date.parse(str);
    let date = Number.isNaN(parse) ? null : new Date(parse);
    if (date) date = new Date(date.setDate(date.getDate()));
    if (this.change) this.change.emit(date);

    return date;
  }
}
