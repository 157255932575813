export interface FileItem {
  name: string;
  file: string | undefined;
  content_type: string | undefined;
  required?: boolean;
  juridical?: boolean;
}

export function base64toFile(item: FileItem): File {
  const base64 = item.file!.substring(item.file!.indexOf(';base64,') + 8);
  const byteString = window.atob(base64);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: item.content_type });
  let imageName = item.name;
  switch (item.content_type) {
    case 'image/jpeg':
    case 'image/jpg':
      imageName = `${imageName}.jpg`;
      break;
    case 'image/png':
      imageName = `${imageName}.png`;
      break;
    case 'application/pdf':
      imageName = `${imageName}.pdf`;
      break;
  }
  return new File([blob], imageName, { type: item.content_type });
}
