import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule, provideStore } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  LAZYLOAD_IMAGE_HOOKS,
  LazyLoadImageModule,
  ScrollHooks,
} from 'ng-lazyload-image';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { environment } from '@environment';
import { cookieConfig } from './shared';

import { AppComponent } from './app.component';

registerLocaleData(localept, environment.locale);

export function translateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  LazyLoadImageModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [HttpClient],
    },
    useDefaultLang: true,
    defaultLanguage: environment.locale,
  }),
  NgcCookieConsentModule.forRoot(cookieConfig),
  StoreModule.forRoot({}),
  StoreDevtoolsModule.instrument({
    name: environment.name,
    maxAge: 25,
    logOnly: !environment.production,
  }),
  CoreModule,
  SharedModule,
  AppRoutingModule,
];

const PROVIDERS = [
  { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  { provide: LOCALE_ID, useValue: environment.locale },
  provideStore(),
];

@NgModule({
  declarations: [AppComponent],
  imports: [...MODULES],
  providers: [...PROVIDERS],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
