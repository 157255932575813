import { Component, Input } from '@angular/core';
import { InternalMenuItem } from './internal-menu-item.model';

@Component({
  selector: 'app-internal-menu',
  templateUrl: './internal-menu.component.html',
})
export class InternalMenuComponent {
  @Input()
  menus: Array<InternalMenuItem> = [];

  constructor() {}
}
