import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-status',
  template: `
    <span ngbTooltip="{{ 'lists.status.' + status | translate }}">
      <ng-container [ngSwitch]="status">
        <ng-container *ngSwitchCase="'PROCESSING'">
          <span class="text-primary">
            <i class="fas fa-circle-radiation fa-spin"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'REGISTERING'">
          <span class="text-primary">
            <i class="fas fa-circle-radiation fa-spin"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="'ACTIVATED'">
          <span class="text-success">
            <i class="fas fa-circle-check"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'CREATED'">
          <span class="text-success">
            <i class="fas fa-circle-check"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'PAID'">
          <span class="text-success">
            <i class="fas fa-circle-check"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'RECEIVED'">
          <span class="text-success">
            <i class="fas fa-circle-check"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'CONFIRMED'">
          <span class="text-success">
            <i class="fas fa-circle-check"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'COMPLETED'">
          <span class="text-success">
            <i class="fas fa-circle-check"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="'ENQUEUED'">
          <span class="text-primary">
            <i class="fas fa-diagram-predecessor"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="'SCHEDULED'">
          <span class="text-primary">
            <i class="fas fa-clock"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="'CANCELED'">
          <span class="text-warning">
            <i class="fas fa-ban"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="'REJECTED'">
          <span class="text-warning">
            <i class="fas fa-ban"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="'ERROR'">
          <span class="text-danger">
            <i class="fas fa-circle-exclamation"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="'OVERDUE'">
          <span style="color: #007bff;">
            <i class="fas fa-stopwatch"></i>
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <span style="color: #007bff;">
            {{ 'lists.status.' + status | translate }}
          </span>
        </ng-container>
      </ng-container>
    </span>
  `,
})
export class IconStatusComponent {
  @Input() status: string = 'ENQUEUED';
}
