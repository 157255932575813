import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Payload } from '../models';
import {
  AccountPaymentBillingPIXQrCodeModel,
  CreateAccountBillingPIXDynamicRequest,
  CreateAccountBillingPIXStaticRequest,
  CreateAccountTransferRequest,
  PayAccountPaymentPIXQrCodeRequest,
  PIXDICTModel,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class TransactionPIXService {
  private get url(): string {
    return `/api/Transactions`;
  }

  constructor(private api: ApiService) {}

  dict(
    accountId: string,
    productId: string,
    key: string
  ): Observable<PIXDICTModel> {
    const headers = new HttpHeaders().set('api-version', '2.0');
    return this.api
      .post<PIXDICTModel>(
        `${this.url}/PIX/DICT`,
        {
          accountId: accountId,
          productId: productId,
          key: key,
        },
        { headers: headers }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  emv(
    accountId: string,
    productId: string,
    emv: string
  ): Observable<AccountPaymentBillingPIXQrCodeModel> {
    const headers = new HttpHeaders().set('api-version', '2.0');
    return this.api
      .post<AccountPaymentBillingPIXQrCodeModel>(
        `${this.url}/PIX/Emv`,
        {
          accountId: accountId,
          productId: productId,
          emv: emv,
        },
        { headers: headers }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  transfer(request: CreateAccountTransferRequest): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Transfers/PIX`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  billingDynamic(
    request: CreateAccountBillingPIXDynamicRequest
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Billings/PIX/Dynamic`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  billingStatic(
    request: CreateAccountBillingPIXStaticRequest
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Billings/PIX/Static`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  image(billingId: string, type: string, size: number): string {
    const timestamp = new Date().getTime();
    return this.api.url(
      `${this.url}/Billings/${billingId}/PIX/${type}/${size}?at=${this.api.auth?.access_token}&${timestamp}`
    );
  }

  payQrCode(
    request: PayAccountPaymentPIXQrCodeRequest
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Payments/PIX/Pay`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
