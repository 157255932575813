import { Component } from '@angular/core';
import { CommonService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-attachment-view',
    template: `

<div class="modal-header">
    <h5 class="modal-title">Termo de Uso</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()"><span
            aria-hidden="true">&times;</span></button>
</div>

<div class="modal-body">
    <ngx-doc-viewer [url]="document" viewer="url" style="width:100%;height:80vh;"></ngx-doc-viewer>
</div>

    `,
})
export class TermsOfUseComponent {

    document: string = '';

    constructor(
        private activeModal: NgbActiveModal,
        private service: CommonService,) { }

    dismiss(): void { this.activeModal.dismiss(); }

    set merchantId(value: string) {
      this.service.termsOfUse().subscribe((url) => {
        this.document = url;
      });
    }
}
