import { createAction, props } from '@ngrx/store';
import { AccountBalanceModel, AuthUser } from '@app/core';

export const clearAccount = createAction(
  'account/clear'
);

export const updateUser = createAction(
  'account/user/update',
  props<{ user: AuthUser }>()
);

export const updateImage = createAction(
  'account/image/update',
  props<{ image: string }>()
);

export const updateBalance = createAction(
  'account/balance/update',
  props<{ balance: AccountBalanceModel }>()
);
