<div [formGroup]="form">

  <div class="row">
    <div class="col">
      <div class="form-group mb-3">
        <label for="userName">{{'labels.userName' | translate}}</label>
        <input id="userName" class="form-control" type="text" placeholder="{{'placeholders.userName' | translate}}" formControlName="userName" autocomplete="off" readonly />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group mb-3">
        <label for="name">{{'labels.name' | translate}}</label>
        <input id="name" class="form-control" type="text" placeholder="{{'placeholders.name' | translate}}" formControlName="name"
          [app-validated]="form" maxlength="32" />
        <div *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
          <small class="text-danger" *ngIf="form.controls['name'].hasError('required')">{{'profile.inputs.name.errors.required' | translate}}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group mb-3">
        <label for="email">{{'labels.email' | translate}}</label>
        <input id="email" class="form-control" type="email" placeholder="{{'placeholders.email' | translate}}" formControlName="email"
          [app-validated]="form" maxlength="64" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group mb-3">
        <label for="phone">{{'labels.phone' | translate}}</label>
        <input id="phone" class="form-control" type="phone" placeholder="{{'placeholders.phone' | translate}}" formControlName="phone"
          [app-validated]="form" maxlength="16" mask="+00 00 00000-0000" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group mb-3">
        <div class="custom-control custom-switch">
          <input id="a2FEnable" type="checkbox" class="custom-control-input" formControlName="a2FEnable">
          <label class="custom-control-label" for="a2FEnable">{{'labels.althentication_multifactor' | translate}}</label>
        </div>
      </div>
    </div>
  </div>

</div>
