import { CreditorModel } from './creditor-model';

export class AccountReceiveModel {
  id: string = '';
  accountId: string = '';
  productId: string = '';
  type: string = '';
  currency: string = '';
  amount: number = 0;
  description: string = '';
  creditor: CreditorModel = new CreditorModel();
  status: string | null = null;
  executeIn: Date = new Date();
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  tags: Array<string> = [];
  message: string | null = null;
  requestClientId: string = '';
  userId: string | null = null;
  userName: string | null = null;
  canReverse: boolean = false;
}
