<form [formGroup]="form" (ngSubmit)="onSubmit();">
  <div class="modal-header">
    <h5 class="modal-title">{{'headers.reverse' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()"><span
        aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="amount" class="form-label">{{'labels.amount' | translate}}</label>
          <input id="amount" class="form-control" type="text" formControlName="amount" currencyMask
            [app-validated]="form" readonly="true"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group mb-3">
          <label for="reason">{{'labels.reason' | translate}}</label>
          <ng-select id="reason" formControlName="reason" [app-validated]="form"
            placeholder="{{'placeholders.reverse_reason' | translate}}"
            [items]="reasons | async" bindLabel="key" bindValue="value">
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="description" class="form-label">{{'labels.description' | translate}}</label>
          <input id="description" class="form-control" type="text" formControlName="description" maxlength="32"
            [app-validated]="form" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="d-flex justify-content-end">
      <button type="submit" class="btn btn-primary mr-2" [disabled]="!form.valid">{{'buttons.reverse' |
        translate}}</button>
      <button type="button" class="btn btn-secondary" (click)="dismiss();">{{'buttons.cancel' |
        translate}}</button>
    </div>
  </div>
</form>
<app-loading [start]="loading"></app-loading>
