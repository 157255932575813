import { dateToString } from '@app/core/global';
import { Pagination } from '@app/core/models';

export class GetAccountTransactionsRequest extends Pagination {
  launch: string | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;

  override get parameters(): string {
    let params = super.parameters;
    if (this.launch) params += `&launch=${this.launch}`;
    if (this.startDate) params += `&startDate=${dateToString(this.startDate)}`;
    if (this.endDate) params += `&endDate=${dateToString(this.endDate)}`;
    return params;
  }
}
