import { Component, Input } from '@angular/core';

import { PayloadList } from '@app/core';

@Component({
  selector: 'app-grid-count',
  templateUrl: './grid-count.component.html',
})
export class GridCountComponent {
  from: number = 0;
  to: number = 0;
  total: number = 0;

  @Input()
  set payloadList(value: PayloadList<any>) {
    this.from = 0;
    this.to = 0;
    this.total = 0;

    if (value.totalCount > 0) {
      this.from =
        value.currentPage > 1
          ? (value.currentPage - 1) * value.pageSize
          : value.currentPage;
      this.to = value.pageSize * value.currentPage;
      this.to = this.to > value.totalCount ? value.totalCount : this.to;
      this.total = value.totalCount;
    }
  }
}
