import {
    Directive,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    ControlValueAccessor,
} from '@angular/forms';
import { NgxMaskService } from 'ngx-mask';

@Directive({
  selector: 'input[taxid-mask]',
})
export class TaxIdMaskDirective implements ControlValueAccessor, OnChanges {
  @Input('taxid-mask') maskType: string | null = null;

  private maskedValue: string | null = null;
  private unmaskedValue: string | null = null;

  constructor(private maskService: NgxMaskService) {}

  onChange = (value: string | number | undefined | null) => {};
  onTouched = (value: string | number | undefined | null) => {};

  onModelChange(value: string | number | undefined | null): void {
    console.debug(value);
  }

  disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    console.debug(changes);
  }

  writeValue(value: string | number | undefined | null): void {
    console.debug(value);
  }

  registerOnChange(fn: typeof this.onChange): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _maskValue(value: string): string {
    let mask = '000.000.000-00';

    switch (this.maskType) {
      case 'cpf':
        mask = '000.000.000-00';
        break;
      case 'cnpj':
        mask = '00.000.000/0000-00';
        break;
      default:
        if (value.length > 11) mask = '00.000.000/0000-00';
        break;
    }

    return this.maskService.applyMask(value, mask);
  }
}
