<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="modal-header">
    <h5 class="modal-title">{{'headers.change_password' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()"><span
        aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body">
    <label class="my-3">{{'labels.must_have_password' | translate}}</label>
    <div class="row">
      <div class="col">
        <div class="form-group mb-3">
          <label for="currentPassword">{{'labels.current_password' | translate}}</label>
          <div class="input-group">
            <input id="currentPassword" class="form-control" type="password" maxlength="6"
              placeholder="{{'placeholders.current_password' | translate}}" formControlName="currentPassword"
              [app-validated]="form" autocomplete="off" app-see-password only-numbers />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group mb-3">
          <label for="newPassword">{{'labels.new_password' | translate}}</label>
          <div class="input-group">
            <input id="newPassword" class="form-control" type="password" maxlength="6"
              placeholder="{{'placeholders.new_password' | translate}}" formControlName="newPassword"
              [app-validated]="form" autocomplete="off" app-see-password only-numbers />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-group mb-3">
          <label for="newPasswordConfirm">{{'labels.confirm_password' | translate}}</label>
          <div class="input-group">
            <input id="newPasswordConfirm" class="form-control" type="password" maxlength="6"
              placeholder="{{'placeholders.confirm_password' | translate}}" formControlName="newPasswordConfirm"
              [app-validated]="form" autocomplete="off" app-see-password only-numbers />
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer d-flex justify-content-end">
    <button type="submit" class="btn btn-primary mr-2" [disabled]="!form.valid">{{'buttons.save' | translate}}</button>
  </div>

</form>
<app-loading [start]="loading"></app-loading>