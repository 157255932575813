<div [formGroup]="form">
  <div class="row">
    <div class="col-sm-2">
      <div class="form-group mb-3">
        <label for="ddi">{{'labels.ddi' | translate}}</label>
        <ng-select id="ddi" clearable="false" formControlName="ddi" [app-validated]="form">
          <ng-option *ngFor="let item of ddis | async" [value]="item.value">{{item.value}} - {{item.key |
            translate}}</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="col-sm-1">
      <div class="form-group mb-3">
        <label for="ddd">{{'labels.ddd' | translate}}</label>
        <input id="ddd" class="form-control" type="text" placeholder="{{'placeholders.ddd' | translate}}"
          formControlName="ddd" [app-validated]="form" mask="000" />
        <div *ngIf="form.controls['ddd'].invalid && (form.controls['ddd'].dirty || form.controls['ddd'].touched)">
          <small class="text-danger" *ngIf="form.controls['ddd'].hasError('required')">{{'messages.error_ddd' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group mb-3">
        <label for="number">{{'labels.phone' | translate}}</label>
        <input id="number" class="form-control" type="text" placeholder="{{'placeholders.phone' | translate}}"
          formControlName="number" [app-validated]="form" maxlength="11" mask="0 0000-0000||0000-0000" />
        <div
          *ngIf="form.controls['number'].invalid && (form.controls['number'].dirty || form.controls['number'].touched)">
          <small class="text-danger" *ngIf="form.controls['number'].hasError('required')">{{'messages.error_number' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group mb-3">
        <label for="type">{{'labels.type' | translate}}</label>
        <ng-select id="type" formControlName="type" [app-validated]="form"
          placeholder="{{'lists.type_phone.select' | translate}}" [items]="phoneTypes | async" bindValue="value">
          <ng-template ng-option-tmp ng-label-tmp let-item="item">{{'lists.type_phone.' + item.value | translate}}
          </ng-template>
        </ng-select>
        <div *ngIf="form.controls['type'].touched">
          <small class="text-danger" *ngIf="form.controls['type'].hasError('required')">{{'messages.error_phone_type' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-1 d-flex align-items-end">
      <div class="form-group mb-3 ml-3">
        <div class="form-check">
          <input id="isMain" class="form-check-input" type="checkbox" formControlName="isMain" readonly />
          <label for="isMain" class="form-check-label">{{'headers.main' | translate}}</label>
        </div>
      </div>
    </div>

    <!-- <div class="col-sm-1 d-flex align-items-end" *ngIf="validateClick.observed && form.controls['id'].value">
      <div class="form-group">
        <button *ngIf="form.controls['validated'].value == false" ngbTooltip="Validar telefone" type="button"
          class="btn btn-primary" (click)="onValidate();">{{'buttons.valid' | translate}}</button>
        <i *ngIf="form.controls['validated'].value == true" ngbTooltip="Validado" class="fas fa-check text-green"></i>
      </div>
    </div> -->
  </div>
</div>