import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Payload, PayloadList } from '../models';
import {
  AccountBillingModel,
  CreateAccountBillingBankSlipRequest,
  CreateAccountBillingQrCodeRequest,
  GetAccountBillingsRequest,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class TransactionBillingService {
  private get url(): string {
    return `/api/Transactions`;
  }

  constructor(private api: ApiService) {}

  get(
    pagination: GetAccountBillingsRequest
  ): Observable<PayloadList<AccountBillingModel>> {
    return this.api
      .get<PayloadList<AccountBillingModel>>(
        `${this.url}/Billings?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getById(billingId: string): Observable<AccountBillingModel> {
    return this.api
      .get<AccountBillingModel>(`${this.url}/Billings/${billingId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  cancel(billingId: string): Observable<Payload<any>> {
    return this.api
      .delete<Payload<any>>(`${this.url}/Billings/${billingId}/Cancel`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  createQrCode(
    request: CreateAccountBillingQrCodeRequest
  ): Observable<Payload<any>> {
    return this.api.post<Payload<any>>(`${this.url}/Billings/QrCode`, request).pipe(
      map((response) => {
        return response;
      })
    );
  }

  imageQrCode(billingId: string, type: string, size: number): string{
    const timestamp = new Date().getTime();
    return this.api.url(
      `${this.url}/Billings/${billingId}/QrCode/${type}/${size}?at=${this.api.auth?.access_token}&${timestamp}`
    );
  }

  createBankSlip(
    request: CreateAccountBillingBankSlipRequest
  ): Observable<Payload<any>> {
    return this.api.post<Payload<any>>(`${this.url}/Billings/BankSlip`, request).pipe(
      map((response) => {
        return response;
      })
    );
  }

  pdfBankSlip(billingId: string): string{
    const timestamp = new Date().getTime();
    return this.api.url(
      `${this.url}/Billings/${billingId}/BankSlip/PDF?at=${this.api.auth?.access_token}&${timestamp}`
    );
  }
}
