import { Component, Input } from '@angular/core';

@Component({
  selector: 'ch-icon',
  template: `<img width="16" height="16" class="{{ class }}" src="{{ image }}" />`,
  styleUrls: ['./icon.component.scss'],
})
export class SvgComponent {
  get image(): string {
    return `/assets/images/${this.type}.svg`;
  }

  @Input() type: string = '';
  @Input() class: string | undefined;
}
