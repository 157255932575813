import { Injectable } from '@angular/core';

import { ApiService } from '../api.service';
import { Observable, map } from 'rxjs';
import { AccountTransferModel, GetTransactionsRequest } from './models';
import { AccountTransactionModel } from '../accounts';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private get url(): string {
    return `/api/Transactions`;
  }

  constructor(private api: ApiService) {}

  search(pagination: GetTransactionsRequest): Observable<AccountTransactionModel> {
    return this.api
      .get<any>(`${this.url}/Search?${pagination.parameters}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
