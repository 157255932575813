<nav class="main-header navbar navbar-expand navbar-white navbar-light top-header-height">

  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" role="button">
        <i class="fas fa-bars"></i>
      </a>
    </li>
  </ul>

  <ul class="navbar-nav d-flex align-items-center ml-auto">

    <li class="nav-item dropdown" ngbTooltip="{{'titles.message' | translate}}">
      <a class="nav-link" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
        <i class="far fa-bell"></i>
        <span class="badge badge-warning navbar-badge">{{ totalMessages }}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-message dropdown-menu-right">
        <div class="form-check my-2 form-switch text-end">
          <span class="float-right mr-2 text-sm ">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
              (click)="onShowAllMessages($event.target.checked)">
          </span>
          <span class="float-right mr-5 text-sm ">
            <label class="form-check-label" for="flexSwitchCheckDefault">{{'headers.show_all_messages' |
              translate}}</label>
          </span>

        </div>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item" *ngFor="let item of messages.items">
          <!-- Message Start -->
          <div class="media">
            <i [ngClass]="item.isRead ? 'fas fa-envelope-open text-muted mr-2 mt-1': 'fas fa-envelope mr-2 mt-1'"></i>
            <div class="media-body">
              <h3 class="dropdown-item-title">
                <span [ngClass]="{'text-muted': item.isRead}">{{item.title}}</span>
                <button *ngIf="item.isRead == false && item.isReading == false" type="button" class="btn float-right mx-2 mt-1 text-sm no-padding"
                  (click)="onMarkMessageRead(item.id)" ngbTooltip="{{'titles.messageRead' | translate}}">
                  <i class="fas fa-envelope-open read-hover"></i>
                </button>

                <button *ngIf="item.isReading == true" type="button" class="btn float-right mx-2 mt-1 text-sm no-padding"
                  (click)="onMarkMessageRead(item.id)" ngbTooltip="{{'titles.messageRead' | translate}}">
                  <i class="fas fa-envelope-open read-hover text-success"></i>
                </button>

                <button *ngIf="item.isModify == false" type="button"
                  class="btn float-right mr-2 mt-1 text-sm no-padding" (click)="onDeleteMessage(item.id)"
                  ngbTooltip="{{'titles.delete' | translate}}">
                  <i class="fas fa-trash remove-hover"></i>
                </button>
              </h3>
              <p
                [ngClass]="item.isRead ? 'text-sm text-wrap text-muted text-dropdown-message' : 'text-sm text-wrap text-dropdown-message' ">
                {{ item.text}}<br>{{ item.createdAt | date:'dd/MM/yy HH:mm:ss'}} </p>
                <h5 ngClass="text-sm text-wrap text-danger" *ngIf="item.isModify == true">
                  Excluindo...</h5>
                  <h5 ngClass="text-sm text-wrap text-danger" *ngIf="item.isReading == true">
                    Alterando mensagem...</h5>  
                <p class="text-sm text-muted text-end"><i class="far fa-clock mr-1"></i> {{ item.createdAt | dateAgo }}
              </p>
              </div>
          </div>
          <div class="dropdown-divider"></div>
          <!-- Message End -->
        </div>
        <app-loading [start]="loadings"></app-loading>
      </div>
    </li>

    <ng-container *ngIf="balance">

      <li class="nav-item" ngbTooltip="{{'titles.vivibility' | translate}}">
        <div class="nav-link">
          <span *ngIf="!visibility"><strong>Saldo:</strong></span>
          &nbsp;
          <span>{{balance.balance | currency_format}}</span>
        </div>
      </li>

      <li class="nav-item mr-4" *ngIf="balance">
        <ng-container *ngIf="visibility">
          <button type="button" class="btn nav-link p-0" (click)="changeVisibily();"><i
              class="fa-solid fa-eye"></i></button>
        </ng-container>
        <ng-container *ngIf="!visibility">
          <button type="button" class="btn nav-link p-0" (click)="changeVisibily();"><i
              class="fa-solid fa-eye-slash"></i></button>
        </ng-container>
      </li>
    </ng-container>

    <li class="nav-item mr-2 nav-item_lg" *ngIf="balance?.name">
      <div class="text-right">
        <div><strong>{{balance.name}}</strong></div>
        <div>
          <span>AG:&nbsp;{{balance.branch}}</span>&nbsp;
          <span>C/C:&nbsp;{{balance.number | account_format}}</span>
        </div>
      </div>
    </li>

    <li class="nav-item dropdown" *ngIf="user">
      <button type="button" id="menuDropdown" class="btn nav-link user-image" data-bs-toggle="dropdown"
        aria-expanded="false">
        <img [src]="image" />
      </button>

      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="menuDropdown">

        <li ngbTooltip="{{'headers.profile' | translate}}">
          <button type="button" class="dropdown-item d-flex" routerLink="/account/profile/details">
            <div>{{'headers.profile' | translate}}</div>
            <img src="assets/images/profile.svg" class="icon-menu-primary ms-auto">
          </button>
        </li>

        <li *ngIf="limitsEnabled" ngbTooltip="{{'headers.limits' | translate}}">
          <a class="dropdown-item d-flex" routerLink="/account/profile/limits">
            <div>{{'headers.limits' | translate}}</div>
            <img src="assets/images/limit.svg" class="icon-menu-primary ms-auto">
          </a>
        </li>

        <li ngbTooltip="{{'headers.change_password' | translate}}">
          <button type="button" class="dropdown-item d-flex" (click)="changePassword();">
            <div>{{'headers.change_password' | translate}}</div>
            <img src="assets/images/key.svg" class="icon-menu-primary ms-auto">
          </button>
        </li>

        <li>
          <hr class="dropdown-divider">
        </li>

        <li ngbTooltip="{{'headers.termofuse' | translate}}">
          <button type="button" class="dropdown-item d-flex" (click)="viewTermsOfUse();">
            <div>{{'headers.termofuse' | translate}}</div>
            <img src="assets/images/handshake.svg" class="icon-menu-primary ms-auto">
          </button>
        </li>

        <li>
          <hr class="dropdown-divider">
        </li>

        <li ngbTooltip="{{'headers.exit' | translate}}">
          <button type="button" class="dropdown-item d-flex" (click)="logout();">
            <div>{{'headers.exit' | translate}}</div>
            <img src="assets/images/sign-out-alt.svg" class="icon-menu-primary ms-auto">
          </button>
        </li>
      </ul>
    </li>
  </ul>
</nav>