import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'account_format',
})
export class AccountFormat implements PipeTransform {
  transform(value: string, digit: boolean = true): string {
    if (!digit) return value;
    var aux = value.length - 1;
    return `${value.substring(0, aux)}-${value.substring(aux, value.length)}`;
  }
}
