<div [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="name">{{'labels.corporate_name' | translate}}</label>
        <input id="name" class="form-control" type="text" maxlength="128"
          placeholder="{{'placeholders.corporate_name' | translate}}" formControlName="name"
          [app-validated]="form" />
        <div *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
          <small class="text-danger"
            *ngIf="form.controls['name'].hasError('required')">{{'messages.error_corporate_name' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="form-group mb-3">
        <label for="tradeName">{{'labels.trade_name' | translate}}</label>
        <input id="tradeName" class="form-control" type="text" maxlength="32"
          placeholder="{{'placeholders.tradeName' | translate}}" formControlName="tradeName" />
      </div>
    </div>

    <div class="col-sm-2">
      <div class="form-group mb-3">
        <label for="birth">{{'labels.birth' | translate}}</label>
        <app-calendar id="birth" formControlName="birth" [app-validated]="form"></app-calendar>
        <div *ngIf="form.controls['birth'].touched">
          <small class="text-danger"
            *ngIf="form.controls['birth'].hasError('required')">{{'errors.person-juridical.birth' |
            translate}}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4">
      <div class="form-group mb-3">
        <label for="taxRegime">{{'labels.taxRegime' | translate}}</label>
        <ng-select id="taxRegime" clearable="false" formControlName="taxRegime" [app-validated]="form">
          <ng-option value="">{{'lists.taxRegime.selectedTaxRegime' | translate}}</ng-option>
          <ng-option *ngFor="let item of taxRegimes | async" [value]="item.value">{{'lists.taxRegime.'+item.key | translate}}</ng-option>
        </ng-select>
        <div *ngIf="form.controls['taxRegime'].touched">
          <small class="text-danger"
            *ngIf="form.controls['taxRegime'].hasError('required')">{{'errors.person-juridical.taxRegime' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="form-group mb-3">
        <label for="legalNature">{{'labels.legalNature' | translate}}</label>
        <ng-select id="legalNature" clearable="false" formControlName="legalNature" [app-validated]="form">
          <ng-option value="">{{'lists.legalNature.selectedLegalNature' | translate}}</ng-option>
          <ng-option *ngFor="let item of legalNatures | async" [value]="item.value">{{'lists.legalNature.'+item.key | translate}}</ng-option>
        </ng-select>
        <div *ngIf="form.controls['legalNature'].touched">
          <small class="text-danger"
            *ngIf="form.controls['legalNature'].hasError('required')">{{'errors.person-juridical.legalNature' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="form-group mb-3">
        <label for="registration">{{'labels.registration' | translate}}</label>
        <input id="registration" class="form-control" type="text"
          placeholder="{{'placeholders.registration' | translate}}" formControlName="registration" />
      </div>
    </div>
  </div>
</div>
