import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    return super.parse(url.toLowerCase());
  }
}

export let lowerCaseUrlProvider = {
  provide: UrlSerializer,
  useClass: LowerCaseUrlSerializer
};
