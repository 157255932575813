import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { AccountBillingQuantityReportModel } from './models';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private get url(): string {
    return `/api/Reports`;
  }

  constructor(private api: ApiService) {}

  billingQuantities(
    accountId: string,
    status: string
  ): Observable<AccountBillingQuantityReportModel> {
    return this.api
      .get<AccountBillingQuantityReportModel>(
        `${this.url}/Accounts/${accountId}/Billings/${status}/Quantities`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
