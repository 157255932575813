import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { indexedDBConfig } from './indexeddb';
import { errorProvider, jwtProvider } from './interceptors';
import { notificationProvider } from './notifications';
import { firebaseProvider } from './notifications/firebase.service';

export function tokenGetter() {
  return '';
}

const MODULES = [
  CommonModule,
  HttpClientModule,
  SweetAlert2Module,
  JwtModule.forRoot({
    config: {
      tokenGetter: tokenGetter,
      throwNoTokenError: false,
    },
  }),
  NgxIndexedDBModule.forRoot(indexedDBConfig),
];

const PROVIDERS = [
  jwtProvider,
  errorProvider,
  notificationProvider,
  firebaseProvider,
];

@NgModule({
  imports: [...MODULES],
  providers: [...PROVIDERS],
})
export class CoreModule {}
