import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbItem } from './breadcrumb.item';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  constructor(private router: Router, private route: ActivatedRoute) {}

  set(value: string): void {
    this.route.snapshot.data = {
      ...this.route.snapshot.data,
      breadcrumb: value,
    };
  }

  build(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: BreadcrumbItem[] = []
  ): BreadcrumbItem[] {
    let label = route.snapshot.data['breadcrumb'] ?? '';
    let path = '';

    route.snapshot.url.forEach((segment) => (path += `/${segment.path}`));

    if (path) {
      url += path;
      const active = this.router.url == url;

      if (label) {
        breadcrumbs.push({
          label: label,
          url: active ? '' : url,
          active: active,
        });
      }
    }

    if (route.firstChild) return this.build(route.firstChild, url, breadcrumbs);

    return breadcrumbs;
  }
}
