import { AccountBillingModel } from '@app/core';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-products-billings-view',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">{{ model.drawee!.name }}</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <ng-container [ngSwitch]="model.type">
        <app-products-billings-qrcode-view
          *ngSwitchCase="'QRCODE'"
          [model]="model"
        ></app-products-billings-qrcode-view>
        <app-products-billings-bankslip-view
          *ngSwitchCase="'BANK_SLIP'"
          [model]="model"
        ></app-products-billings-bankslip-view>
        <app-products-billings-pix-view
          *ngSwitchCase="'PIX_DYNAMIC'"
          [model]="model"
        ></app-products-billings-pix-view>
        <app-products-billings-pix-view
          *ngSwitchCase="'PIX_STATIC'"
          [model]="model"
        ></app-products-billings-pix-view>
      </ng-container>
    </div>
  `,
})
export class ProductsBillingsViewComponent {
  constructor(private activeModal: NgbActiveModal) {}

  dismiss(): void {
    this.activeModal.dismiss();
  }

  _model!: AccountBillingModel;

  set model(value: AccountBillingModel) {
    this._model = value;
  }

  get model() {
    return this._model;
  }
}
