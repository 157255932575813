import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-box-layout',
  templateUrl: './box-layout.component.html'
})
export class BoxLayoutComponent implements OnInit, OnDestroy {

  version: string = `v${environment.version}`;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'box-page');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'box-page');
  }
}
