import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { environment } from '@environment';

import { ApiService } from '@app/core/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Payload } from '../models';
import { StorageService } from '../storage';
import { Auth, AuthenticationService } from './../authentications';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private isRefreshToken: boolean = false;
  private timeout: number | null = null;

  constructor(
    private router: Router,
    private modal: NgbModal,
    private jwtHelper: JwtHelperService,
    private storage: StorageService,
    private apiService: ApiService,
    private authService: AuthenticationService
  ) {}

  /**
   * Intercepts jwt interceptor
   * @param request
   * @param next
   * @returns intercept
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes(environment.apiUrl) &&
      !request.headers.get(AuthenticationService.TOKEN_HEADER_KEY)
    ) {
      const auth = this.apiService.auth;
      const token: string | null = auth ? auth.access_token : null;
      const refreshToken: string | null = auth ? auth.refresh_token : null;

      /**
       * Timer para deslogar o usuário após X min de inatividade
       */
      if (token && !this.jwtHelper.isTokenExpired(token)) {
        this.clearTimeout();
        this.timeout = window.setTimeout(
          () => this.execTimeout(token),
          environment.downtime
        );
      }

      if (
        token &&
        refreshToken &&
        this.jwtHelper.isTokenExpired(refreshToken)
      ) {
        this.storage.removeAll();
        this.router.navigate(['/auth/login'], {
          queryParams: { redirect: this.router.url },
        });
        return new Observable();
      } else if (refreshToken && request.url.includes('RefreshToken')) {
        request = this.addTokenHeader(request, refreshToken);
      } else if (token) {
        request = this.addTokenHeader(request, token);
        return next.handle(request).pipe(
          catchError((error) => {
            if (
              !request.url.includes('SignIn') &&
              ((error instanceof HttpErrorResponse && error.status === 401) ||
                error.code === 401)
            ) {
              return this.handle401Error(request, next);
            }
            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(request);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRefreshToken) {
      this.isRefreshToken = true;
      this.refreshTokenSubject.next(null);
      const auth = this.apiService.auth;

      if (auth!.access_token) {
        return this.authService
          .refreshToken({
            token: auth!.access_token,
            expires: auth!.expires_in,
          })
          .pipe(
            switchMap((payload: Payload<Auth>) => {
              this.isRefreshToken = false;
              request = this.addTokenHeader(
                request,
                payload!.data!.access_token ?? ''
              );
              return next.handle(request);
            }),
            catchError((error) => {
              console.error(error);
              this.isRefreshToken = false;
              this.storage.removeAll();
              this.router.navigate(['/auth/login'], {
                queryParams: { redirect: this.router.url },
              });
              return throwError(() => error);
            })
          );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    const auth = this.apiService.auth;

    return request.clone({
      headers: request.headers.set(
        AuthenticationService.TOKEN_HEADER_KEY,
        `${auth?.token_type} ${token}`
      ),
    });
  }

  private async execTimeout(token: string): Promise<void> {
    this.modal.dismissAll();
    this.clearTimeout();
    if (!token) return;

    if (this.jwtHelper.isTokenExpired(token)) this.storage.removeAll();
    else await this.authService.signOut();
    this.router.navigate(['/auth/login'], {
      queryParams: { redirect: this.router.url },
    });
    Swal.fire({
      icon: 'info',
      title:
        'Você foi desconectado por ter ficado um longo tempo sem atividade.',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'OK',
    });
  }

  private clearTimeout(): void {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}

export const jwtProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true,
};
