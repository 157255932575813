<aside class="main-sidebar sidebar-sf elevation-4">

  <a class="brand-link logo-switch top-header-height" ngbTooltip="{{name}}" routerLink="home">
    <ng-container [ngSwitch]="theme">
      <ng-container *ngSwitchCase="'nostrum'">
        <div class="logo-xs">
          <img src='assets/images/nostrum/favicon.svg' alt="{{name}}" />
        </div>
        <div class="logo-xl">
          <img src='assets/images/nostrum/logo_dark_background.svg' alt="{{name}}" />
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'phizpay'">
        <div class="logo-xs">
          <img src='assets/images/phizpay/favicon.svg' alt="{{name}}" />
        </div>
        <div class="logo-xl">
          <img src='assets/images/phizpay/logo_dark_background.svg' alt="{{name}}" />
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'instantepay'">
        <div class="logo-xs">
          <img src='assets/images/instantepay/logo_dark_background.svg' alt="{{name}}" />
        </div>
        <div class="logo-xl">
          <img src='assets/images/instantepay/logo_dark_background.svg' alt="{{name}}" />
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'ecoss'">
        <div class="logo-xs">
          <img src='assets/images/ecoss/favicon.svg' alt="{{name}}" />
        </div>
        <div class="logo-xl">
          <img src='assets/images/ecoss/logo_dark_background_horizontal.svg' alt="{{name}}" />
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'agile'">
        <div class="logo-xs">
          <img src='assets/images/agile/favicon.svg' alt="{{name}}" />
        </div>
        <div class="logo-xl">
          <img src='assets/images/agile/logo_dark_background.svg' alt="{{name}}" />
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div class="logo-xs">
          <img src='assets/images/default/logo_dark_background.svg' alt="{{name}}" />
        </div>
        <div class="logo-xl">
          <img src='assets/images/default/logo_dark_background.svg' alt="{{name}}" />
          <span class="brand-name ml-3">CoreHub</span>
        </div>
      </ng-container>
    </ng-container>
  </a>

  <div class="sidebar">

    <nav class="mt-2">
      <ul class="nav nav-pills flex-column mb-auto">

        <ng-container *ngFor="let nav of menus; let i = index" [ngTemplateOutlet]="sideBarNavItemRef"
          [ngTemplateOutletContext]="{$implicit: nav, idx: i}">
        </ng-container>

      </ul>
    </nav>
    
    <div class="bottom"> {{version}} </div>

  </div>

</aside>

<ng-template #sideBarNavItemRef let-item let-idx="idx">
  <li class="nav-item" *ngIf="item.enabled">
    <a *ngIf="item.hasRoute" class="nav-link" aria-current="page" [routerLink]="item.route" routerLinkActive="active">
      <ch-icon type={{item.icon}} class="icon-white"></ch-icon>
      <span class="mt-1">{{item.title}}</span>
    </a>

    <a *ngIf="!item.hasRoute && item.hasChildren" class="nav-link collapsed" role="button" data-bs-toggle="collapse"
      [attr.data-bs-target]="'#item_'+idx" aria-expanded="false" [attr.aria-controls]="'item_'+idx">
      <ch-icon *ngIf="item.icon" type={{item.icon}} class="icon-white"></ch-icon>
      <span>{{item.title}}</span>
    </a>

    <div *ngIf="item.hasChildren" id="item_{{idx}}" class="collapse">
      <ul class="nav nav-pills flex-column mb-auto forAnimate">
        <ng-container *ngFor="let nav of item.children; let i = index" [ngTemplateOutlet]="sideBarNavItemRef"
          [ngTemplateOutletContext]="{$implicit: nav, idx: 10*idx+i}">
        </ng-container>
      </ul>
    </div>
  </li>
</ng-template>
