<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body p-3">

    <div class="d-flex justify-content-evenly m-2">
        <img src="assets/images/default/safety_security.svg" style="height: 100px;" />
    </div>

    <div class="d-flex justify-content-evenly m-2">
        <span class="text-center">{{message}}</span>
    </div>

    <div class="d-flex justify-content-evenly m-2">

        <ng-container *ngFor="let item of numbers; let i = index; trackBy: trackByIndex">
            <div class="input-group m-1">
                <input id="number_{{ i }}" type="text" class="form-control input-two-factor" mask="0" maxlength="1"
                    [(ngModel)]="numbers[i]" [libFocus]="shouldFocus(i)" (keyup)="changeFocus(i, $event);" />
            </div>
        </ng-container>

    </div>

    <div class="d-flex justify-content-evenly m-3">
        <ng-container *ngIf="timer >= 0; else elseTemplate">
            <span class="text-muted">Reenviar token ({{timer}}s)</span>
        </ng-container>
        <ng-template #elseTemplate>
            <a class="link" (click)="resendToken();">Reenviar token</a>
        </ng-template>
    </div>
</div>
