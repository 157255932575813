import {
  AccountBillingModel,
  TransactionBillingService,
} from '@app/core';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-products-billings-bankslip-view',
  template: `
    <ngx-doc-viewer
      [url]="bankslip"
      viewer="url"
      style="width:100%;height:80vh;"
    ></ngx-doc-viewer>
  `,
})
export class ProductsBillingsBankSlipsViewComponent {
  bankslip: string = '';

  constructor(private service: TransactionBillingService) {}

  @Input() set model(value: AccountBillingModel) {
    this.bankslip = this.service.pdfBankSlip(value.id);
  }
}
