import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { AutoComplete, CommonService } from '@app/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-person-natural',
  templateUrl: './person-natural.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonNaturalComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonNaturalComponent),
      multi: true
    }
  ]
})
export class PersonNaturalComponent {

  @Input('formGroup') form: FormGroup = new FormGroup({});

  genders: Observable<Array<AutoComplete<string, string>>> = this.service.genders();
  nationalities: Observable<Array<AutoComplete<string, string>>> = this.service.nationalities();

  constructor(
    private service: CommonService) { }

  validate(form: FormControl): ValidationErrors | null {
    return form.valid ? null : form.errors;
  }
}
