import { Pipe, PipeTransform } from '@angular/core';
import { Config } from '@app/core';
import { Store } from '@ngrx/store';

@Pipe({
  name: 'currency_format',
  pure: false,
})
export class CurrencyFormat implements PipeTransform {
  visibility: boolean = true;
  constructor(private store: Store<{ config: Config }>) {
    this.store.select('config').subscribe({
      next: (config) => {
        this.visibility = config.visibility;
      },
    });
  }

  transform(value: number, locale: string = 'BRL', disableMask: boolean = false): string {
    const config = {
      prefix: this.prefix(locale),
      precision: 2,
      thousands: '.',
      decimal: ',',
      chunkLength: 3,
    };

    const result =
      '\\d(?=(\\d{' +
      config.chunkLength +
      '})+' +
      (config.precision > 0 ? '\\D' : '$') +
      ')';

    let num = value.toFixed(config.precision);
    num = config.decimal ? num.replace('.', config.decimal) : num;

    if (!disableMask && !this.visibility) return `${config.prefix} *${config.thousands}***${config.decimal}**`;

    return `${config.prefix} ${num.replace(
      new RegExp(result, 'g'),
      '$&' + config.thousands
    )}`;
  }

  prefix(locale: string): string {
    switch (locale) {
      case 'BRL':
        return 'R$';
      default:
        return '$';
    }
  }
}
