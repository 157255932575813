import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions';
import { Config } from '../models';

const initialConfig: Config = {
  transactions: {},
  acquirerEnable: false,
  apiEnable: false,
  visibility: false,
  loading: true,
} as Config;

export const configReducer = createReducer(
  initialConfig,
  on(actions.modifyVisibility, (state, action) => {
    return {
      ...state,
      visibility: action.visibility,
      loading: false,
    };
  }),
  on(actions.updateMenu, (state, action) => {
    return {
      ...state,
      menus: action.menus,
      loading: false,
    };
  }),
  on(actions.updateConfig, (state, action) => {
    return {
      ...state,
      consolidationEnable: action.consolidationEnable,
      acquirerEnable: action.acquirerEnable,
      apiEnable: action.apiEnable,
      pixKeyEnable: action.pixKeyEnable,
      limitsEnable: action.limitsEnable,
      loading: false,
    };
  }),
  on(actions.updateTransactions, (state, action) => {
    return {
      ...state,
      transactions: action.transactions,
      loading: false,
    };
  })
);
