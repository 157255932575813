import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { PayloadList, Pagination } from '@app/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  private _payloadList: PayloadList<any> = new PayloadList<any>();

  get numPages(): number {
    return window.innerWidth < 300 ? 3 : window.innerWidth < 500 ? 5 : 9;
  }

  pages: number[] = [];

  @Input()
  get payloadList(): PayloadList<any> {
    return this._payloadList;
  }

  set payloadList(value: PayloadList<any>) {
    if (value) {
      const aux = this.numPages;
      const pages = aux < value.totalPages ? aux : value.totalPages;
      let start =
        value.currentPage < Math.trunc(aux / 2)
          ? 1
          : value.currentPage >= value.totalPages - Math.trunc(aux / 2)
          ? value.totalPages - aux + 1
          : value.currentPage - Math.trunc(aux / 2);
      start = start < 1 ? 1 : start;
      this.pages = Array.from({ length: pages }, (_, i) => start + i);
    }
    this._payloadList = value;
  }

  @Output() changePage = new EventEmitter<number>();

  constructor() {}

  onChangePage(value: number): void {
    this.changePage.emit(value);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.payloadList = this._payloadList;
  }
}
