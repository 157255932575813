import { dateToString } from '@app/core/global';
import { Pagination } from '@app/core/models';

export class GetAccountBillingsRequest extends Pagination {
  operationId: string | null = null;
  merchantId: string | null = null;
  branchId: string | null = null;
  accountId: string | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  status: string | null = null;

  override get parameters(): string {
    let params = super.parameters;
    if (this.operationId) params += `&operationId=${this.operationId}`;
    if (this.merchantId) params += `&merchantId=${this.merchantId}`;
    if (this.branchId) params += `&branchId=${this.branchId}`;
    if (this.accountId) params += `&accountId=${this.accountId}`;
    if (this.status) params += `&status=${this.status}`;
    if (this.startDate)
      params += `&startDate=${dateToString(this.startDate)}`;
    if (this.endDate) params += `&endDate=${dateToString(this.endDate)}`;
    return params;
  }
}
