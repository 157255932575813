import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { AutoComplete, CommonService } from '@app/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactComponent),
      multi: true
    }
  ]
})
export class ContactComponent {

  @Input('formGroup') form: FormGroup = new FormGroup({});
  @Output('validateClick') validateClick = new EventEmitter<string>();

  contactTypes: Observable<Array<AutoComplete<string, string>>> = this.service.contactTypes();

  constructor(
    private service: CommonService) { }

  validate(form: FormControl): ValidationErrors | null {
    return form.valid ? null : form.errors;
  }

  onValidate(): void {
    this.validateClick.emit(this.form.controls['id'].value);
  }
}
