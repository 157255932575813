import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[app-validated]'
})
export class ControlValidatedDirective implements OnInit {

  @Input('app-validated') group!: any;

  form!: FormGroup;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.form = this.group as FormGroup;
    this.onTouched();
  }

  @HostListener('focusout')
  @HostListener('keyup')
  @HostListener('change')
  onTouched(): void {
    const input = this.el.nativeElement;
    var control = this.form.controls[input.id];

    if(control == undefined) return;

    if(!control.dirty && !control.touched || control.status == 'DISABLED') return;

    if (control.valid) {
      this.renderer.removeClass(input, 'invalid');
      this.renderer.addClass(input, 'valid');
    }
    else if (control.invalid) {
      this.renderer.removeClass(input, 'valid');
      this.renderer.addClass(input, 'invalid');
    }
  }
}
