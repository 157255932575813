export class AccountAddressModel {
  id: string = '';
  type: string = '';
  zipCode: string = '';
  street: string = '';
  number: string = '';
  complement: string = '';
  neighborhood: string = '';
  city: string = '';
  state: string = '';
  country: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
}
