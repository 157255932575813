import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { environment } from '@environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './approve.component.html',
})
export class TransactionApproveComponent implements OnInit {
  loading: boolean = false;
  enableOptions: boolean = false;
  title: string = '';
  buttonApprove: string = '';
  buttonDisapprove: string = '';
  buttonCancel: string = '';

  numbers: Array<string> = ['', '', '', '', '', ''];

  form: FormGroup = this.formBuilder.group({
    type: 'password',
    password: [null, [Validators.required, Validators.minLength(6)]],
    token: [null, [Validators.required, Validators.minLength(6)]],
    approved: []
  });

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.f['type'].setValue(environment.methodApproveTransaction)
    this.changeType();
  }

  changeType(): void {
    switch (this.f['type'].value) {
      case 'password':
        this.f['password'].enable();
        this.f['token'].disable();
        break;
      case 'token':
        this.f['password'].disable();
        this.f['token'].enable();
        break;
      default:
        this.f['password'].enable();
        this.f['token'].enable();
        break;
    }
  }

  trackByIndex(index: number, number: string): number {
    return index;
  }

  elementFocus: string = '';

  shouldFocus(index: number): boolean {
    if (
      (index > 0 && this.numbers[index - 1] === '') ||
      this.numbers[index] !== ''
    )
      return false;
    return true;
  }

  changeFocus(index: number, event: KeyboardEvent): void {
    let elementName: string | undefined = undefined;
    this.form.controls['token'].setValue(null);

    if (event.key === 'Backspace' && index > 0) {
      elementName = `#number_${index - 1}`;
    } else if (index + 1 < this.numbers.length && this.numbers[index]) {
      elementName = `#number_${index + 1}`;
    } else if (index + 1 === this.numbers.length) {
      this.form.controls['token'].setValue(this.numbers.join(''));
    }

    if (elementName) {
      const input = document.querySelector(elementName) as HTMLInputElement;
      setTimeout(() => input.focus(), 1);
    }
  }

  timer: number = 0;
  sendToken: any;
  submit: any;

  async onSendToken(): Promise<void> {
    this.loading = true;
    this.timer = await this.sendToken();
    this.countdown();
    this.loading = false;
  }

  countdown(): void {
    if (this.timer < 0) return;
    this.timer -= 1;
    setTimeout(this.countdown.bind(this), 1000);
  }

  async onSubmit(approved: boolean): Promise<void> {
    this.loading = true;

    var success = await this.submit(
      this.f['password'].value,
      this.f['token'].value,
      approved
    );
    if (success) this.dismiss();
    this.loading = false;
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }
}
