import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
    selector: '[appDropZone]'
})
export class DropZoneDirective {

    @HostBinding('class.fileover') fileOver: boolean = false;

    @Output('fileDropped') fileDropped = new EventEmitter<Array<File>>();

    @HostListener('drop', ['$event'])
    ondrop(event: DragEvent): void {
        event.preventDefault();
        this.fileOver = false;
        const { dataTransfer } = event;
        if (dataTransfer!.items) {
            const files: Array<File> = [];
            for (let i = 0; i < dataTransfer!.items.length; i++) {
                // If dropped items aren't files, reject them
                if (dataTransfer!.items[i].kind === 'file') {
                    const file = dataTransfer!.items[i]!.getAsFile();
                    if (file)
                        files.push(file);
                }
            }
            dataTransfer!.items.clear();
            this.fileDropped.emit(files);
        } else {
            const files = dataTransfer!.files;
            dataTransfer!.clearData();
            this.fileDropped.emit(Array.from(files));
        }
    }

    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event'])
    onDragLeave(event: DragEvent): void {
        this.fileOver = false;
    }

    @HostListener('body:dragover', ['$event'])
    onBodyDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('body:drop', ['$event'])
    onBodyDrop(event: DragEvent) {
        event.preventDefault();
    }
}