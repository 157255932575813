import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-virtual-keyboard',
  template: `
    <div class="row justify-content-center">
      <label class="col-12 text-center">{{
        'labels.type-epassword' | translate
      }}</label>
      <div class="col-8">
        <div class="d-flex justify-content-evenly m-2">
          <ng-container *ngFor="let item of itemsPassword; let i = index">
            <div class="input-group m-1">
              <input
                readonly
                id="number_{{ i }}"
                type="password"
                class="form-control input-password-item"
                mask="0"
                maxlength="1"
                [ngModel]="model[i]"
                [libFocus]="shouldFocus(i)"
                (keyup)="changeFocus(i, $event)"
              />
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-virtual-keyboard btn-sm btn-primary m-2"
            type="button"
            [disabled]="onDisableButtons()"
            (click)="onPassword(0)"
          >
            {{ this.buttonsPassword[0] }} ou {{ this.buttonsPassword[1] }}
          </button>
          <button
            class="btn btn-virtual-keyboard btn-sm btn-primary m-2"
            type="button"
            [disabled]="onDisableButtons()"
            (click)="onPassword(1)"
          >
            {{ this.buttonsPassword[2] }} ou {{ this.buttonsPassword[3] }}
          </button>
          <button
            class="btn btn-virtual-keyboard btn-sm btn-primary m-2"
            type="button"
            [disabled]="onDisableButtons()"
            (click)="onPassword(2)"
          >
            {{ this.buttonsPassword[4] }} ou {{ this.buttonsPassword[5] }}
          </button>
        </div>
        <div class="d-flex justify-content-center">
          <button
            class="btn btn-virtual-keyboard btn-sm btn-primary m-2"
            type="button"
            [disabled]="onDisableButtons()"
            (click)="onPassword(3)"
          >
            {{ this.buttonsPassword[6] }} ou {{ this.buttonsPassword[7] }}
          </button>
          <button
            class="btn btn-virtual-keyboard btn-sm btn-primary m-2"
            type="button"
            [disabled]="onDisableButtons()"
            (click)="onPassword(4)"
          >
            {{ this.buttonsPassword[8] }} ou {{ this.buttonsPassword[9] }}
          </button>
          <button
            class="btn btn-virtual-keyboard btn-sm btn-primary m-2"
            type="button"
            (click)="onClearPassword()"
          >
            {{ 'buttons.clear' | translate }}
          </button>
        </div>
      </div>
    </div>
  `,
})
export class VirtualKeyboardComponent implements OnInit {
  @Output() onTypePassword: EventEmitter<Array<string>> = new EventEmitter();

  itemsPassword: Array<string> = ['', '', '', '', '', ''];
  buttonsPassword: Array<number> = [];
  password: Array<string> = [];
  model: Array<string> = [];

  ngOnInit(): void {
    for (let index = 0; this.buttonsPassword.length < 10; index++) {
      var number = this.randomNumber(0, 9);
      if (this.buttonsPassword.find((x) => x == number) == undefined) {
        this.buttonsPassword.push(number);
      }
    }
  }

  randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  shouldFocus(index: number): boolean {
    if (
      (index > 0 && this.itemsPassword[index - 1] === '') ||
      this.itemsPassword[index] !== ''
    )
      return false;

    return true;
  }

  changeFocus(index: number, event: KeyboardEvent): void {
    let elementName: string | undefined = undefined;

    if (event.key === 'Backspace' && index > 0) {
      elementName = `#number_${index - 1}`;
    } else if (
      index + 1 < this.itemsPassword.length &&
      this.itemsPassword[index]
    ) {
      elementName = `#number_${index + 1}`;
      this.itemsPassword[index] = `index${index}`;
    }

    if (elementName) {
      const input = document.querySelector(elementName) as HTMLInputElement;
      setTimeout(() => input.focus(), 1);
    }
  }

  onPassword(indexButton: number): void {
    this.model.push('0');
    switch (indexButton) {
      case 0:
        this.password.push(
          `${this.buttonsPassword[0]},${this.buttonsPassword[1]}`
        );
        break;
      case 1:
        this.password.push(
          `${this.buttonsPassword[2]},${this.buttonsPassword[3]}`
        );
        break;
      case 2:
        this.password.push(
          `${this.buttonsPassword[4]},${this.buttonsPassword[5]}`
        );
        break;
      case 3:
        this.password.push(
          `${this.buttonsPassword[6]},${this.buttonsPassword[7]}`
        );
        break;
      case 4:
        this.password.push(
          `${this.buttonsPassword[8]},${this.buttonsPassword[9]}`
        );
        break;
      case 4:
        this.password.push(
          `${this.buttonsPassword[8]},${this.buttonsPassword[9]}`
        );
        break;
    }
    if (this.password.length == 6) this.onTypePassword.emit(this.password);
  }

  onClearPassword(): void {
    this.itemsPassword = ['', '', '', '', '', ''];
    this.password = [];
    this.model = [];
    this.onTypePassword.emit(this.password);
  }

  onDisableButtons(): boolean {
    return this.password.length == 6;
  }
}
