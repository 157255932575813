import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AccountService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Attachment } from './attachment.model';

@Component({
    selector: 'app-attachment-view',
    template: `

    <div class="modal-header">
        <h5 class="modal-title">{{'lists.attach_type.' + attachment.name | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()"><span
                aria-hidden="true">&times;</span></button>
    </div>
    
    <div class="modal-body">
        <ng-container *ngIf="attachment.contentType === 'application/pdf'">
            <ngx-doc-viewer [url]="document" viewer="url" style="width:100%;height:80vh;"></ngx-doc-viewer>
        </ng-container>
    
        <ng-container *ngIf="attachment.contentType !== 'application/pdf'">
            <img defaultImage='{{ "assets/images/" + theme + "/logo_light_background.svg" }}' [lazyLoad]="document" [useSrcset]="true" />
        </ng-container>
    </div>
    
        `,
})
export class AttachmentViewComponent {

    document: string = '';

    constructor(
        private activeModal: NgbActiveModal,
        private service: AccountService,) { }

    dismiss(): void { this.activeModal.dismiss(); }

    _attachment!: Attachment;

    set attachment(value: Attachment) {
        this._attachment = value;
        this.document = this.service.file(this.attachment.id)
    }

    get attachment() {
        return this._attachment;
    }
}
