import { environment } from '@environment';
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { merge } from 'lodash';

const storageName = `@${environment.name}:__config_storage__`;

function getStorage(): any {
  try {
    const base64 = localStorage.getItem(storageName) ?? '';
    const json = window.atob(base64);
    return json ? JSON.parse(json) : {};
  } catch (error) {
    console.debug(error);
    return {};
  }
}

function setStorage(state: any) {
  const json = JSON.stringify(state);
  const base64 = window.btoa(json);
  localStorage.setItem(storageName, base64);
}

function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  let onInit = true;
  return function (state: any, action: Action): any {
    const nextState = reducer(state, action);
    if (onInit) {
      onInit = false;
      const savedState = getStorage();
      return merge(nextState, savedState);
    }
    setStorage(nextState);
    return nextState;
  };
}

export const configMetaReducers: MetaReducer<any>[] = [metaReducer];
