import { Component, forwardRef, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProfileComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProfileComponent),
      multi: true,
    },
  ],
})
export class ProfileComponent {
  @Input('formGroup') form: FormGroup = new FormGroup({});

  constructor() {}

  validate(form: FormControl): ValidationErrors | null {
    return form.valid ? null : form.errors;
  }
}
