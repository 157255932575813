import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions';
import { AccountData } from '../models';

const initialAccount: AccountData = {
  image: undefined,
  user: undefined,
  balance: undefined,
  loading: true,
} as AccountData;

export const accountReducer = createReducer(
  initialAccount,
  on(actions.clearAccount, (state, action) => {
    return {
      ...initialAccount,
    };
  }),
  on(actions.updateUser, (state, action) => {
    return {
      ...state,
      user: action.user,
      loading: false,
    };
  }),
  on(actions.updateUser, (state, action) => {
    return {
      ...state,
      user: action.user,
      loading: false,
    };
  }),
  on(actions.updateImage, (state, action) => {
    return {
      ...state,
      image: action.image,
      loading: false,
    };
  }),
  on(actions.updateBalance, (state, action) => {
    return {
      ...state,
      balance: action.balance,
      loading: false,
    };
  })
);
