import { Directive, ElementRef, EventEmitter, HostListener, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[uppercase]'
})
export class UppercaseDirective {

  @Output() valueChange = new EventEmitter();
  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this._el.nativeElement.value;
    const newValue = initalValue.toUpperCase();
    this._el.nativeElement.value = newValue;
    this.valueChange.emit(newValue);
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  
  @HostListener('blur', ['$event']) onBlur(event: any) {
    const initalValue = this._el.nativeElement.value;
    const newValue = initalValue.toUpperCase();
    this._el.nativeElement.value = newValue;
    this.valueChange.emit(newValue);
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
