import {
  Directive,
} from '@angular/core';

@Directive({
  selector: '[account-mask]',
})
export class AccountMaskDirective
{
}
