import { CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const toastrConfig = {
  timeOut: 20000,
  closeButton: true,
  progressBar: true,
  preventDuplicates: true,
  positionClass: 'toast-bottom-right',
  toastClass: 'ngx-toastr',
  iconClasses: {
    error: 'toast-error',
    info: 'toast-info',
    success: 'toast-success',
    warning: 'toast-warning',
  },
};

export const currencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: `${window.location.hostname}`,
  },
  position: 'bottom-left',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#169CD8',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#ec6a1e',
      text: '#ffffff',
      border: 'transparent',
    },

  },
  type: 'info',
  content: {
    message:
      'Este site usa cookies para garantir que você obtenha a melhor experiência.',
    dismiss: 'Entendi!',
    link: 'Política de Privacidade',
    href: 'https://www.starfusion.com.br/termos-de-privacidade.html',
    policy: 'Política de Cookie',
  },
};
