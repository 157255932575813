import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { ChangePasswordComponent } from '@app/shared/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

import {
  AccountBalanceModel,
  AccountData,
  AccountService,
  AuthenticationService,
  AuthUser,
  clearAccount,
  Config,
  FirebaseService,
  modifyVisibility,
  NotificationService,
  PayloadList,
  StorageService,
  updateBalance,
  updateConfig,
  updateImage,
  updateTransactions,
  updateUser,
} from '@app/core';
import { TermsOfUseComponent } from '@app/shared/components';
import { MessageModel } from '@app/core/accounts/models/message.model';
import { GetMessagesRequest } from '@app/core/accounts/models/get-messages-request';
import { forEach } from 'lodash';
import { exit } from 'process';

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() loading = new EventEmitter<boolean>();
  
  loadings: boolean = false;
  image: string | undefined;
  user: AuthUser | undefined;
  balance: AccountBalanceModel | undefined;
  visibility: boolean = true;
  isSmallScreen: boolean = false;
  limitsEnabled: boolean = false;
  totalMessages: number = 0;
  messages: PayloadList<MessageModel> = new PayloadList<MessageModel>();
  isShowAllMessage: boolean | undefined = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modal: NgbModal,
    private notification: NotificationService,
    private firebase: FirebaseService,
    private storage: StorageService,
    private authService: AuthenticationService,
    private accountService: AccountService,
    private store: Store<{ config: Config; account: AccountData }>
  ) {
    this.store.select('account').subscribe((model) => {
      this.image = model.image;
      this.user = model.user;
      this.balance = model.balance;
    });
    this.store.select('config').subscribe((model) => {
      this.visibility = model.visibility;
    });
  }

  async ngOnInit() {
    this.isSmallScreen = window.innerWidth <= 412;

    await this.authService.onLoad();
    const user = this.storage.user;
    if (user) {
      if (user.changePassword) {
        const title = this.translate.instant('titles.warning');
        const message = this.translate.instant('messages.need_change_password');
        this.toastr.warning(message, title);
        this.changePassword();
      }
      this.onLoad(user);
    }
  }

  ngAfterViewInit(): void {
    this.firebase.start();
  }

  onLoad(user: AuthUser): void {
    this.notification.connect();
    this.store.dispatch(updateUser({ user: user }));
    this.store.dispatch(
      updateImage({
        image: this.accountService.image(user.accountId, user.personId),
      })
    );
    this.accountService.balance(user.accountId).subscribe({
      next: (payload) => {
        this.store.dispatch(updateBalance({ balance: payload }));
      },
    });
    this.accountService.getConfig(user.accountId).subscribe({
      next: (payload) => {
        this.store.dispatch(
          updateConfig({
            consolidationEnable: payload.config.consolidationEnable,
            acquirerEnable: payload.config.acquirerEnable,
            apiEnable: payload.config.apiEnable,
            pixKeyEnable: payload.config.pixKeyEnable,
            limitsEnable: payload.config.limitsEnable,
          })
        );
        this.store.dispatch(
          updateTransactions({ transactions: payload.config })
        );

        this.limitsEnabled = payload.config.limitsEnable;
      },
    });
    this.onLoadMessages(false);
  }

  ngOnDestroy(): void {
    this.notification.disconnect();
    this.store.dispatch(clearAccount());
  }

  onLoadMessages(isRead: boolean | undefined): void {
     try {
      var request = new GetMessagesRequest();
      request.read = isRead;
  
      this.accountService.getMessages(this.user!.accountId, request).subscribe({
        next: (payload) => {
          this.messages = payload;
          this.messages.items.forEach(item =>{
              item.isModify = false;
              item.isReading = false;
          });
          this.totalMessages = this.messages.totalCount;
          this.loadings= false;
        },
      });    
    } catch (error) {
      this.loadings= false;
    }
  }

  changePassword(): void {
    const modal: NgbModalRef = this.modal.open(ChangePasswordComponent, {
      centered: true,
    });
    modal.closed.subscribe(() => this.ngOnInit());
    modal.dismissed.subscribe(() => this.ngOnInit());
  }

  async logout() {
    this.loading.emit(true);
    await this.authService.signOut();
    this.router.navigate(['/auth/login']);
  }

  changeVisibily(): void {
    this.store.dispatch(modifyVisibility({ visibility: !this.visibility }));
  }

  viewTermsOfUse(): void {
    const modal: NgbModalRef = this.modal.open(TermsOfUseComponent, {
      size: 'xl',
      centered: true,
      scrollable: true,
    });
    modal.componentInstance.merchantId = this.user?.merchantId;
  }

  onShowAllMessages(checked: boolean): void {
    this.isShowAllMessage = checked ? undefined : false;
    this.onLoadMessages(this.isShowAllMessage);
  }

  onMarkMessageRead(message: any): void {
    this.loadings= true;
    for (let i = 0; i < this.messages.items.length; i++) {
      if(this.messages.items[i].id === message)
      {
        this.messages.items[i].isReading = true;
        i = this.messages.items.length+1;
        break;
      }
    }
    this.accountService.markMessageRead(message).subscribe({
      next: (payload) => {
        this.onLoadMessages(this.isShowAllMessage);
      },
      error: (err) => {
        this.loadings= false;
      },
    });
  }

  onDeleteMessage(message: any): void {
    this.loadings= true;
    for (let i = 0; i < this.messages.items.length; i++) {
      if(this.messages.items[i].id === message)
      {
        this.messages.items[i].isModify = true;
        i = this.messages.items.length+1;
        break;
      }
    }
    try {
      this.accountService.deleteMessage(message).subscribe({
        next: (payload) => {
          this.onLoadMessages(this.isShowAllMessage);
        },
        error: (err) => {},
      });
    } catch (error) {
      this.loadings= false;
    }
  }

}
