import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-transaction-merchant-party',
  template: `
    <div *ngIf="party.name">{{ 'labels.name' | translate }} {{ party.name }}</div>
    <div *ngIf="party.taxId">{{ 'labels.taxid.cnpj' | translate }} {{ party.taxId | taxid_format }}</div>
  `,
})
export class TransactionMerchantPartyComponent {
  @Input() party: any;
}
