import { Component, Input } from '@angular/core';
import { CommonService } from '@app/core';

@Component({
  selector: 'app-transaction-receiver-party',
  template: `
    <div>{{ 'labels.name' | translate }} {{ party.name }}</div>
    <div [ngSwitch]="party.personType">
      <ng-container *ngSwitchCase="'N'"
        >{{ 'labels.taxid.cpf' | translate }}
        {{ party.taxId | taxid_format : true }}</ng-container
      >
      <ng-container *ngSwitchCase="'J'"
        >{{ 'labels.taxid.cnpj' | translate }}
        {{ party.taxId | taxid_format : true }}</ng-container
      >
    </div>
    <div *ngIf="party.key">
      {{ 'labels.key_pix' | translate }} {{ party.key }}
    </div>
    <div *ngIf="party.taxId">
        {{ 'labels.taxid.accounts' | translate }} {{ party.taxId | taxid_format : true}}
      </div>
    <div *ngIf="participantsSTR">
      {{ 'labels.bank' | translate }} {{ participantsSTR }}
    </div>
    <ng-container *ngIf="!participantsSTR">
      <div *ngIf="party.bank">
        {{ 'labels.bank' | translate }} {{ party.bank }}
      </div>
      <div *ngIf="party.compe">
        {{ 'labels.compe' | translate }} {{ party.compe }}
      </div>
      <div *ngIf="party.ispb">
        {{ 'labels.ispb' | translate }} {{ party.ispb }}
      </div>
    </ng-container>
    <div *ngIf="party.branch">
      {{ 'labels.branch' | translate }} {{ party.branch | mask : '0000' }}
    </div>
    <div *ngIf="party.account">
      {{
        party.accountType
          ? party.accountType + ':'
          : ('labels.checking_account' | translate)
      }}
      {{ party.account | account_format }}
    </div>
  `,
})
export class TransactionReceiverPartyComponent {
  @Input() party: any;
  participantsSTR: string | undefined;
  loading: boolean = false;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.searchParticipantSTR();
  }

  searchParticipantSTR(): void {
    this.commonService.getParticipantSTRByISPB(this.party.ispb).subscribe({
      next: (models) => {
        if (models.length > 0) {
          this.participantsSTR = models[0].name;
        } else {
          this.participantsSTR = '';
        }
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }
}
