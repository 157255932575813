<footer class="main-footer footer-sf d-flex">

  <div class="flex-grow-1 text-limit">
    <span>
      <strong>Copyright &copy; 2022/2023 <a class="a-light" href="{{siteMerchant}}">{{nameMerchant}}</a>.</strong>
      {{'messages.rights_reserved' | translate}}
    </span>
    <span>{{'messages.developed_by' | translate}} <a class="a-light" href="https://www.starfusion.com.br">StarFusion</a></span>
  </div>
</footer>
