import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  NgbDateStruct,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {
  readonly pipe = new DatePipe(environment.locale);
  readonly DELIMITER = '/';

  constructor() {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1] + 1, 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? this.pipe.transform(
          new Date(date.year, date.month - 1, date.day),
          `dd${this.DELIMITER}MM${this.DELIMITER}yyyy`
        ) ?? ''
      : '';
  }
}
