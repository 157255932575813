<div [formGroup]="form">

  <div class="row">

    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="email">{{'labels.email' | translate}}</label>
        <input id="email" class="form-control" type="email" placeholder="{{'placeholders.email' | translate}}"
          formControlName="email" [app-validated]="form" />
        <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
          <small class="text-danger" *ngIf="form.controls['email'].hasError('required')">{{'messages.contact.email'
            | translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group mb-3">
        <label for="type">{{'labels.type' | translate}}</label>
        <ng-select id="type" formControlName="type" [app-validated]="form"
          placeholder="{{'lists.type_contact.select' | translate}}" [items]="contactTypes | async" bindValue="value">
          <ng-template ng-option-tmp ng-label-tmp let-item="item">{{'lists.type_contact.' + item.value | translate}}
          </ng-template>
        </ng-select>
        <div *ngIf="form.controls['type'].touched">
          <small class="text-danger" *ngIf="form.controls['type'].hasError('required')">{{'messages.contact.type' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-1 d-flex align-items-end">
      <div class="form-group mb-3 ml-3">
        <div class="form-check">
          <input id="isMain" class="form-check-input" type="checkbox" formControlName="isMain" readonly />
          <label for="isMain" class="form-check-label">{{'headers.main' | translate}}</label>
        </div>
      </div>
    </div>

    <!-- <div class="col-sm-1 d-flex align-items-end" *ngIf="validateClick.observed && form.controls['id'].value">
      <div class="form-group">
        <button *ngIf="form.controls['validated'].value == false" ngbTooltip="Validar e-mail" type="button"
          class="btn btn-primary" (click)="onValidate();">{{'buttons.valid' | translate}}</button>
        <i *ngIf="form.controls['validated'].value == true" ngbTooltip="Validado" class="fas fa-check text-green"></i>
      </div>
    </div> -->

  </div>

</div>