import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `<div class="overlay-wrapper" [hidden]="!start">
    <div class="overlay">
      <i class="fas fa-3x fa-sync-alt fa-spin"></i>
      <ng-container *ngIf="message">
        <div class="overlay-message mt-3">{{ message }}</div>
      </ng-container>
    </div>
  </div>`,
})
export class LoadingComponent {
  @Input() start: boolean = false;
  @Input() message: string | undefined;
}
