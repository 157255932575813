import { Pipe, PipeTransform } from '@angular/core';
import { NgxMaskService } from 'ngx-mask';
import { Store } from '@ngrx/store';
import { Config } from '@app/core';

@Pipe({
  name: 'taxid_format',
  pure: false,
})
export class TaxIdFormat implements PipeTransform {
  visibility: boolean = true;
  constructor(
    private store: Store<{ config: Config }>,
    private maskService: NgxMaskService
  ) {
    this.store.select('config').subscribe({
      next: (config) => {
        this.visibility = config.visibility;
      },
    });
  }

  transform(value: string, hide: boolean = false): string {
    let valueMasked =
      value.length > 11
        ? this.maskService.applyMask(value, '00.000.000/0000-00')
        : this.maskService.applyMask(value, '000.000.000-00');
    if (hide || !this.visibility) {
      if (value.length > 11) {
        var split = valueMasked.split('.');
        valueMasked = `**.${split[1]}.${split[2].split('/')[0]}/****-**`;
      } else {
        var split = valueMasked.split('.');
        valueMasked = `***.${split[1]}.${split[2].split('-')[0]}-**`;
      }
    }
    return valueMasked;
  }
}
