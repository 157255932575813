import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[app-see-password]'
})
export class SeePasswordDirective implements OnInit {

  private _shown = false;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.setup();
  }

  get showPassword(): string {
    return `<i class="fas fa-eye-slash"></i>`;
  }

  get hidePassword(): string {
    return `<i class="fas fa-eye"></i>`;
  }

  toggle(elm: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      elm.innerHTML = this.hidePassword;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      elm.innerHTML = this.showPassword;
    }
  }

  setup() {
    const input = this.el.nativeElement;
    const parent = input.parentNode;

    // const wrapper = document.createElement('div');
    // wrapper.className = 'input-group';

    const append = document.createElement('div');
    append.className = 'input-group-append';

    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'btn';
    button.innerHTML = this.showPassword;

    // wrapper.appendChild(input);
    // wrapper.appendChild(append);
    append.appendChild(button);

    button.addEventListener('click', (event) => this.toggle(button));

    parent.appendChild(append);
  }
}
