import { Directive, ElementRef, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[copy-input]',
})
export class CopyInputDirective implements OnInit {
  private _shown = false;

  constructor(
    private el: ElementRef,
    private toastr: ToastrService,
    private clipboardService: ClipboardService
  ) {}

  ngOnInit(): void {
    this.setup();
  }

  copy(elm: HTMLInputElement) {
    this.clipboardService.copy(elm.value);
    const message = 'Copiado';
    this.toastr.success(message);
  }

  setup() {
    const input = this.el.nativeElement;
    const parent = input.parentNode;

    const append = document.createElement('div');
    append.className = 'input-group-append';

    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'btn icon-primary';
    button.innerHTML = `<i class="fa fa-copy"></i>`;

    append.appendChild(button);
    button.addEventListener('click', () => this.copy(input));
    parent.appendChild(append);
  }
}
