<div class="card-header card-header-write">
  <div class="card-title"></div>
  <div class="card-tools">
    <ul class="nav nav-pills ml-auto">

      <li class="nav-item ml-2 py-1">
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{'buttons.export' | translate}}
          </button>
          <ul class="dropdown-menu">
            <li><button type="button" class="dropdown-item" (click)="exportTransactions('CSV');"
                ngbTooltip="{{'titles.export' | translate}} CSV">CSV</button></li>
            <li><button type="button" class="dropdown-item" (click)="exportTransactions('PDF');"
                ngbTooltip="{{'titles.export' | translate}} PDF">PDF</button></li>
            <li><button type="button" class="dropdown-item" (click)="exportTransactions('JSON');"
                ngbTooltip="{{'titles.export' | translate}} JSON">JSON</button></li>
          </ul>
        </div>
      </li>

      <li class="nav-item ml-2 py-1">
        <div class="form-group text-nowrap" style="min-width: 100px;">
          <ng-select id="launch" [(ngModel)]="pagination.launch" (ngModelChange)="launchChange($event);"
            placeholder="{{'lists.launchs.all' | translate}}" [items]="launchs | async" bindValue="key">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">{{'lists.launch.'+item.key | translate}}
            </ng-template>
          </ng-select>
        </div>
      </li>

      <li class="nav-item ml-2">
        <app-calendar-range [(startDate)]="pagination.startDate" [(endDate)]="pagination.endDate" [maxDate]="maxDate"
          (ngModelChange)="onLoad();"></app-calendar-range>
      </li>

      <li class="nav-item ml-2 py-1">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="{{'placeholders.search' | translate}}"
            [(ngModel)]="pagination.search" />
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" (click)="onLoad();">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </li>

      <li class="nav-item ml-2 py-1">
        <button type="button" class="btn nav-link" (click)="onLoad();" ngbTooltip="{{'titles.refresh' | translate}}">
          <i class="fas fa-sync-alt icon-button"></i>
        </button>
      </li>
    </ul>
  </div>
</div>

<div class="card-body">

  <div class="table-responsive">
    <table class="table table-hover table-sm" role="grid">

      <thead>
        <tr>
          <th scope="col" class="col-1">{{'columns.date' | translate}}</th>
          <th scope="col" class="col">{{'columns.description' | translate}}</th>
          <th scope="col" class="col-2 text-right">{{'columns.amount' | translate}}</th>
          <th scope="col" class="col-2 text-right">{{'columns.balance' | translate}}</th>
          <th scope="col" class="col-1 text-center">{{'columns.launch' | translate}}</th>
          <th scope="col" class="col-auto"></th>
        </tr>
      </thead>

      <tbody>
        <tr role="row" *ngFor="let item of payload.items">
          <td class="text-nowrap">{{item.executedAt | date:'dd/MM/yyyy H:mm'}}</td>
          <td class="text-limit">{{item.description}}</td>
          <td
            [ngClass]="item.launch == 'CREDIT' ? 'text-nowrap text-right text-success'  : 'text-nowrap text-right text-danger'">
            {{item.amount | currency_format:item.currency}}</td>
          <td class="text-nowrap text-right">
            {{item.balance | currency_format:item.currency}}</td>
          <td class="text-nowrap text-center">
            <span [ngSwitch]="item.launch" ngbTooltip="{{'lists.launch.'+item.launch|translate}}">
              <i class="fas fa-angle-up text-success" *ngSwitchCase="'CREDIT'"></i>
              <i class="fas fa-angle-down text-danger" *ngSwitchCase="'DEBIT'"></i>
            </span>
          </td>
          <td class="text-nowrap">
            <div class="btn-group float-right">
              <button class="btn" ngbTooltip="{{'titles.view_receipt' | translate}}" (click)="openReceipt(item);"
                *ngIf="item.metadata">
                <i class="fas fa-receipt icon-button"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</div>

<div class="card-footer">
  <div class="row">
    <div class="col mb-2">
      <app-grid-count [payloadList]="payload"></app-grid-count>
    </div>
    <div class="col d-flex justify-content-end">
      <app-pagination [payloadList]="payload" (changePage)="changePageNumber($event)"> </app-pagination>
    </div>
  </div>
</div>
<app-loading [start]="loading"></app-loading>