import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AccountTransactionModel, AccountReceiveModel, TransactionReceiveService } from '@app/core';
import { environment } from '@environment';
import { ProductsReceiveReverseComponent } from '@app/pages/account/transactions/extract';

@Component({
  selector: 'app-transaction-receipt',
  templateUrl: './receipt.component.html',
})
export class TransactionReceiptComponent implements OnInit, OnDestroy {
  model: AccountTransactionModel = new AccountTransactionModel();
  modelReverse: AccountReceiveModel | undefined;
  imageUrl: string = 'assets/images/default/logo_light_background.png';

  constructor(
    private activeModal: NgbActiveModal,
    private service: TransactionReceiveService,
    private modal: NgbModal,) {
    this.setTheme(environment.theme);
  }

  ngOnInit(): void {
    //if (this.model.amount < 0) this.model.amount = this.model.amount * -1;

    //verifico se é credito para saber se pode fazer estorno
    if (this.model?.launch == "CREDIT")
      this.onSearchRecive();
  }

  onSearchRecive(): void {
    if (this.modelReverse == null) {
      this.modelReverse = new AccountReceiveModel();
      this.service
        .getById(this.model?.relationId)
        .subscribe((payload) => {
          this.modelReverse = payload;
        });
    }
  }

  reverse(item: AccountTransactionModel): void {
    const modal: NgbModalRef = this.modal.open(
      ProductsReceiveReverseComponent,
      {
        size: 'md',
        centered: true,
      }
    );
    modal.componentInstance.model = this.model;
    modal.result.then(
      () => this.activeModal.close(),
      () => { }
    );
  }

  ngOnDestroy(): void { }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  downloadPDF(): void {
    //let innerWidth = window.innerWidth;
    const DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 190;
      const fileHeight = (694 * fileWidth) / 498;
      const position = 6;
      const FILEURI = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'A4');
      pdf.addImage(FILEURI, 'PNG', position, position, fileWidth, fileHeight);
      pdf.save(`receipt_${this.model.id}.pdf`);
    });
  }

  setTheme(theme: string) {
    this.imageUrl = `assets/images/${theme}/logo_light_background.png`;
  }


  //estorno
}
