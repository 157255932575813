export class AccountWebhookModel {
  id: string = '';
  accountId: string = '';
  type: string = '';
  url: string = '';
  headers: any;
  mTLS: boolean = false;
  isActive: boolean = true;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
}
