<div class="dropzone my-3" appDropZone (fileDropped)="onFileDropped($event)" (click)="fileDropRef.click();"
  [check-role]="addCheckRole">
  <div>
    <i class="fas fa-upload"></i>
    <div class="mt-4">
      <span>{{'labels.drag_files_here' | translate}}</span>
    </div>
  </div>
  <input type="file" #fileDropRef multiple (change)="onFileDropped($event.target.files)" />
</div>

<ng-container *ngIf="files.length > 0">
  <div class="d-flex justify-content-end my-3">
    <button type="button" class="btn btn-primary" (click)="onUploadFiles();"
      [disabled]="uploadStarted">{{'buttons.upload' |
      translate}}</button>
  </div>
  <div class="row mb-3 align-items-center" *ngFor="let item of files">
    <div class="col">
      <ng-select [(ngModel)]="item.name" placeholder="{{'placeholders.file_select' | translate}}">
        <ng-option value="social_contract">{{'lists.attach_type.social_contract' | translate}}</ng-option>
        <ng-option value="card_taxid">{{'lists.attach_type.card_taxid' | translate}}</ng-option>
        <ng-option value="company_balance_sheet">{{'lists.attach_type.company_balance_sheet' | translate}}</ng-option>
        <ng-option value="company_billing_report">{{'lists.attach_type.company_billing_report' | translate}}</ng-option>
        <ng-option value="invoicing">{{'lists.attach_type.invoicing' | translate}}</ng-option>
        <ng-option value="portrait">{{'lists.attach_type.portrait' | translate}}</ng-option>
        <ng-option value="document_front">{{'lists.attach_type.document_front' | translate}}</ng-option>
        <ng-option value="document_verse">{{'lists.attach_type.document_verse' | translate}}</ng-option>
        <ng-option value="selfie">{{'lists.attach_type.selfie' | translate}}</ng-option>
        <ng-option value="kyc">{{'lists.attach_type.kyc' | translate}}</ng-option>
        <ng-option value="proof_residence">{{'lists.attach_type.proof_residence' | translate}}</ng-option>
        <ng-option value="others">{{'lists.attach_type.others' | translate}}</ng-option>
      </ng-select>
    </div>
    <div class="col-2 text-center">{{item.extension}}</div>
    <div class="col-2 text-center">{{item.contentType}}</div>
    <div class="col-2 text-center">{{formatBytes(item.length, 2)}}</div>
    <div class="col-auto float-righ" *ngIf="item.uploadStarted && !item.uploadCompleted">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Uploading...</span>
      </div>
    </div>
    <div class="col-auto float-righ" *ngIf="item.uploadCompleted">
      <i class="fas fa-check-circle fs-3 text-success"></i>
    </div>
    <div class="col-auto btn-group float-righ" *ngIf="!item.uploadStarted && !item.uploadCompleted">
      <a class="btn" ngbTooltip="{{'titles.delete' | translate}}" (click)="onDeleteFile(item);">
        <i class="fas fa-trash"></i>
      </a>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="attachments.length > 0">
  <div class="table-responsive mb-3">
    <table class="table table-hover table-sm mt-4" role="grid">

      <thead>
        <tr>
          <th scope="col" class="col text-nowrap">{{'columns.name' | translate}}</th>
          <th scope="col" class="col-auto text-nowrap text-center">{{'columns.extension' | translate}}</th>
          <th scope="col" class="col-auto text-nowrap text-center">{{'columns.contentType' | translate}}</th>
          <th scope="col" class="col-auto text-nowrap text-center">{{'columns.length' | translate}}</th>
          <th scope="col" class="col-auto text-nowrap text-center">{{'columns.createdAt' | translate}}</th>
          <th scope="col" class="col-auto text-nowrap"></th>
        </tr>
      </thead>

      <tbody>
        <tr role="row" *ngFor="let item of attachments">
          <td class="text-nowrap">{{'lists.attach_type.'+item.name | translate}}</td>
          <td class="text-nowrap text-center">{{item.extension}}</td>
          <td class="text-nowrap text-center">{{item.contentType}}</td>
          <td class="text-nowrap text-center">{{formatBytes(item.length, 2)}}</td>
          <td class="text-nowrap text-center">{{item.createdAt | date:'dd/MM/yyyy H:mm'}}</td>
          <td class="text-nowrap">
            <div class="btn-group float-right">
              <div *ngIf="item.deleteStarted && !item.deleteCompleted">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Uploading...</span>
                </div>
              </div>
              <div *ngIf="item.deleteCompleted">
                <i class="fas fa-check-circle fs-3 text-success"></i>
              </div>

              <a class="btn" ngbTooltip="{{'titles.visualize' | translate}}" (click)="onVisualizeItem(item);"
                *ngIf="!item.deleteStarted && !item.deleteCompleted" [check-role]="viewCheckRole">
                <i class="fas fa-binoculars"></i>
              </a>
              <a class="btn" ngbTooltip="{{'titles.delete' | translate}}" (click)="onDeleteItem(item);"
                *ngIf="!item.deleteStarted && !item.deleteCompleted" [check-role]="deleteCheckRole">
                <i class="fas fa-trash"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</ng-container>
