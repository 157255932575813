import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AccountModel,
  AccountTransactionModel,
  AutoComplete,
  CommonService,
  TransactionReceiveService,
  AccountReceiveModel,
} from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-accounts-products-receive-reverse',
  templateUrl: './reverse.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ProductsReceiveReverseComponent implements OnInit {
  account: AccountModel = new AccountModel();
  model: AccountTransactionModel = new AccountTransactionModel();
  loading: boolean = false;

  form: FormGroup = this.formBuilder.group({
    currency: ['BRL', [Validators.required]],
    amount: [0.0, [Validators.required]],
    reason: [null, [Validators.required]],
    description: null,
  });

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private service: TransactionReceiveService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.model) this.form.patchValue(this.model);
  }

  onSubmit(): void {
    this.loading = true;
    const message = this.translate.instant('messages.sure_reverse_to_receive');
    Swal.fire({
      icon: 'question',
      title: message,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('buttons.cancel'),
      showConfirmButton: true,
      confirmButtonText: this.translate.instant('buttons.reverse'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.service.reverse(this.model.relationId, this.form.value).subscribe({
          next: (payload) => {
            const title = this.translate.instant('titles.success');
            const message = this.translate.instant(
              'messages.success_reverse_receive'
            );
            this.toastr.success(message, title);
            this.loading = false;
            this.activeModal.close();
          },
          error: (err) => {
            this.loading = false;
          },
        });
      }
    });
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  _reasons: Observable<Array<AutoComplete<string, string>>> | undefined;
  get reasons(): Observable<Array<AutoComplete<string, string>>> {
  if (!this._reasons) this._reasons = this.commonService.reverseReasons();
    return this._reasons;
  }
}
