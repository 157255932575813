import { StorageService } from './storage/storage.service';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { environment } from '@environment';
import { Auth } from './authentications';
import { AuthToken, Payload } from './models';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private storage: StorageService
  ) {}

  token(): Observable<AuthToken> {
    let token = this.storage.token;
    if (token == null || this.jwtHelper.isTokenExpired(token.access_token)) {
      const body = new HttpParams()
        .set('client_id', environment.clientId)
        .set('client_secret', environment.clientSecret)
        .set('grant_type', 'client_credentials')
        .set('expires', 3600);
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      const httpOptions = { headers: headers };
      return this.post<Payload<AuthToken>>(
        `/api/Authentication/Token`,
        body,
        httpOptions
      ).pipe(
        map((response) => {
          if (response != null && response.code == 200 && response.data) {
            this.storage.setToken(response.data!);
          }
          return response.data!;
        })
      );
    }
    return new Observable((subscriber) => {
      try {
        subscriber.next(token);
        subscriber.complete();
      } catch (e) {
        subscriber.error(e);
      }
    });
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna o usuário corrente
   */
  get auth(): Auth | undefined {
    try {
      const auth = this.storage.auth;
      return auth;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * @description Concatena a URL da api
   */
  url(api: string): string {
    return `${environment.apiUrl}${api}`;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * @description Faz a chamada da API via POST
   * @param api Caminho da API
   * @param body Corpo da mensagem
   */
  post<T>(
    api: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    const url = this.url(api);
    return this.http.post<T>(url, body, options);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * @description Faz a chamada da API via GET
   * @param api Caminho da API
   */
  get<T>(
    api: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    const url = this.url(api);
    return this.http.get<T>(url, options);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * @description Faz a chamada da API via PUT
   * @param api Caminho da API
   * @param body Corpo da mensagem
   */
  put<T>(
    api: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    const url = this.url(api);
    return this.http.put<T>(url, body, options);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * @description Faz a chamada da API via DELETE
   * @param api Caminho da API
   */
  delete<T>(
    api: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]:
              | string
              | number
              | boolean
              | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      body?: any | null;
    }
  ): Observable<T> {
    const url = this.url(api);
    return this.http.delete<T>(url, options);
  }
}
