import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';

@Directive({
    selector: '[check-role]'
})
export class CheckRoleDirective implements OnInit {

    @Input('check-role') roles: Array<string> = [];

    constructor(
        private el: ElementRef,
        private service: AuthenticationService) { }

    async ngOnInit() {
        const valid = await this.service.checkRoles(this.roles);
        if (valid) return;
        const input = this.el.nativeElement;
        input.remove();
    }
}
