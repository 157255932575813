import { APP_INITIALIZER, Injectable } from '@angular/core';
import { ApiService } from '@app/core/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environment';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AccountService } from '../accounts';
import { AccountData, updateBalance } from '../redux';
import {
  AccountBillingMessage,
  AccountMessage,
  AccountNotificationMessage,
  AccountPaymentMessage,
  AccountTransactionMessage,
  AccountTransferMessage,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private connection!: signalR.HubConnection;

  initialized = false;
  connected = false;
  established = new Subject<boolean>();
  account = new Subject<AccountMessage>();
  accountNotification = new Subject<AccountNotificationMessage>();
  accountTransaction = new Subject<AccountTransactionMessage>();
  accountTransfer = new Subject<AccountTransferMessage>();
  accountPayment = new Subject<AccountPaymentMessage>();
  accountBilling = new Subject<AccountBillingMessage>();

  constructor(
    private api: ApiService,
    private jwtHelper: JwtHelperService,
    private accountService: AccountService,
    private store: Store<{ account: AccountData }>
  ) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.api.url(`/hub/Notification`), {
        accessTokenFactory: () => `${this.api.auth?.access_token}`,
        headers: {
          Accept: 'application/json',
        },
        withCredentials: true,
        logger: environment.production
          ? signalR.LogLevel.Warning
          : signalR.LogLevel.Debug,
        //transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .withHubProtocol(new signalR.JsonHubProtocol())
      .build();
  }

  async connect(): Promise<void> {
    if (
      this.jwtHelper.isTokenExpired(this.api.auth?.access_token ?? '') ||
      this.connected ||
      this.connection.state != 'Disconnected'
    )
      return;
    await this.init();
    this.connection.start().then(() => {
      this.connected = true;
    });
  }

  async disconnect(): Promise<void> {
    if (!this.connected) return;
    this.connection.stop().then(() => {
      this.connected = true;
    });
  }

  async init(): Promise<void> {
    if (this.initialized) return;
    this.initialized = true;

    this.established.subscribe((connect) => {
      if (connect) {
        this.connect();
      } else {
        this.disconnect();
      }
    });

    this.connection.on('Account', (message: AccountMessage) => {
      //console.debug(message);
      this.accountService.balance(message.accountId).subscribe({
        next: (payload) => {
          this.store.dispatch(updateBalance({ balance: payload }));
        },
      });
    });

    this.connection.on(
      'AccountNotification',
      (message: AccountNotificationMessage) => {
        //console.debug(message);
        this.accountNotification.next(message);
      }
    );

    this.connection.on(
      'AccountTransaction',
      (message: AccountTransactionMessage) => {
        //console.debug(message);
        this.accountTransaction.next(message);
        this.accountService.balance(message.accountId).subscribe({
          next: (payload) => {
            this.store.dispatch(updateBalance({ balance: payload }));
          },
        });
      }
    );

    this.connection.on('AccountTransfer', (message: AccountTransferMessage) => {
      //console.debug(message);
      this.accountTransfer.next(message);
    });

    this.connection.on('AccountPayment', (message: AccountPaymentMessage) => {
      //console.debug(message);
      this.accountPayment.next(message);
    });

    this.connection.on('AccountBilling', (message: AccountBillingMessage) => {
      //console.debug(message);
      this.accountBilling.next(message);
    });
  }
}

function initialize(service: NotificationService) {
  return (): Promise<any> => {
    return service.init();
  };
}

export const notificationProvider = {
  provide: APP_INITIALIZER,
  useFactory: initialize,
  deps: [NotificationService],
  multi: false,
};
