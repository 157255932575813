import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any): any {
    var message: string = '';
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) return 'Agora';

      let intervals = new Map<string, number>();
      intervals.set('segundo', 1);
      intervals.set('minuto', 60);
      intervals.set('hora', 3600);
      intervals.set('dia', 86400);
      intervals.set('semana', 604800);
      intervals.set('mês', 2592000);
      intervals.set('ano', 31536000);

      let counter;

      intervals.forEach((value: number, key: string) => {
        counter = Math.floor(seconds / value);

        if (counter > 0) {
          if (counter === 1) {
            message = counter + ' ' + key + ' atrás';
            return message;
          } else {
            message = counter + ' ' + key + ' atrás';
            return message;
          }
        } else {
          return message;
        }
      });
    }

    return message != null ? message : value;
  }
}
