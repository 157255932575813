import { AccountBillingModel, TransactionBillingService } from '@app/core';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-products-billings-qrcode-view',
  template: `
    <div class="row">
      <div class="col d-flex justify-content-center">
        <h5>
          {{ 'labels.duedate' | translate }}
          {{ dueDate | date: 'dd/MM/yyyy' }}
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <img [src]="qrcode" />
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center" *ngIf="amount">
        <h2>
          {{ 'labels.amount' | translate }}
          {{ amount | currency_format: currency }}
        </h2>
      </div>
    </div>
    <div class="row" *ngIf="key">
      <div class="col d-flex justify-content-center">
        <span style="max-width: 70%;" [copy]="key">{{ key }}</span>
      </div>
    </div>
  `,
})
export class ProductsBillingsQrCodeViewComponent {
  dueDate: Date = new Date();
  qrcode: string = '';
  currency: string = 'BRA';
  amount: number = 0;
  key: string | null = null;

  constructor(private service: TransactionBillingService) {}

  @Input() set model(value: AccountBillingModel) {
    this.qrcode = this.service.imageQrCode(value.id, 'PNG', 256);
    this.dueDate = value.dueDate;
    this.currency = value.currency;
    this.amount = value.amount;
    this.key = value.metadata.key;
  }
}
