import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes,
  TitleStrategy,
} from '@angular/router';

import { AuthGuard } from '@app/core';

import { BoxLayoutComponent, MainLayoutComponent } from '@app/shared';
import { PageTitleStrategy } from './shared/page-title.strategy';

const routes: Routes = [
  {
    path: 'auth',
    component: BoxLayoutComponent,
    loadChildren: () => import('./pages/auth').then((m) => m.AuthModule),
  },
  {
    path: 'account',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./pages/account').then((m) => m.AccountModule),
  },
  {
    path: 'register',
    title: 'Registrar',
    component: BoxLayoutComponent,
    loadChildren: () =>
      import('./pages/register').then((m) => m.RegisterModule),
  },
  { path: '**', redirectTo: '/auth' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: !environment.production,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: PageTitleStrategy }],
})
export class AppRoutingModule {}
