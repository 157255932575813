import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { TransactionService } from './transaction.service';
import { Payload, PayloadList } from '../models';
import {
  ReverseAccountReceiveModel,
  AccountReceiveModel,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class TransactionReceiveService {
  private get url(): string {
    return `/api/Transactions`;
  }

  constructor(private api: ApiService) { }

  reverse(
    receiveId: string,
    request: ReverseAccountReceiveModel
  ): Observable<Payload<any>> {
    return this.api
      .put<Payload<any>>(`${this.url}/Receives/${receiveId}/Reverse`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getById(receiveId: string): Observable<AccountReceiveModel> {
    return this.api
      .get<AccountReceiveModel>(`${this.url}/Receives/${receiveId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

}
