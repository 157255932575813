<ng-container *ngIf="model.payer">
  <li>
    <h5>{{'headers.debit_party' | translate}}</h5>
    <app-transaction-account-party [party]="model.payer"></app-transaction-account-party>
  </li>
  <li>
    <hr class="separator">
  </li>
</ng-container>

<ng-container *ngIf="model.receiver">
  <li>
    <h5>{{'headers.credit_party' | translate}}</h5>
    <app-transaction-account-party [party]="model.receiver"></app-transaction-account-party>
  </li>
  <li>
    <hr class="separator">
  </li>
</ng-container>

<ng-container *ngIf="model.endToEndId">
  <li>
    <div>{{'labels.endToEndId' | translate}} {{model.endToEndId}}</div>
  </li>
</ng-container>

<ng-container *ngIf="model.movementId">
  <li>
    <div>{{'labels.movementId' | translate}} {{model.movementId}}</div>
  </li>
</ng-container>
