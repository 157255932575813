export class FileModel {
    name: string;
    readonly extension: string;
    readonly length: number;
    readonly contentType: string;

    uploadStarted: boolean = false;

    constructor(public file: File) {
        const split = file.name.split('.');
        this.name = split[0];
        this.extension = `.${split.pop()}`;
        this.length = file.size;
        this.contentType = file.type;
    }

    uploadCompletedCallback?: (value: FileModel) => void;
    _uploadCompleted: boolean = false;

    set uploadCompleted(value: boolean) {
        this._uploadCompleted = value;
        if (this.uploadCompletedCallback)
            this.uploadCompletedCallback(this);
    }

    get uploadCompleted() {
        return this._uploadCompleted;
    }
}