import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environment';
import { Payload } from '../models';
import { StorageService } from '../storage';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private storage: StorageService,
    private service: ApiService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error) =>
          this.handleError(error, this.router, this.storage, this.service)
        )
      );
  }

  handleError(
    err: HttpErrorResponse,
    router: Router,
    storage: StorageService,
    service: ApiService
  ): Observable<any> {
    if (!environment.production) console.error(err);
    const payload: Payload<any> = err.error;

    if (payload && service.auth && payload.code === 401)
      return throwError(() => payload);

    if (
      err.url?.includes('SignOut') ||
      (payload && payload.errors && payload.errors[0].errorCode === 'CH30019') // O usuário já foi desconectado.
    ) {
      storage.removeAll();
      router.navigate(['/auth/login']);
    } else if (payload && payload.errors) {
      payload.errors.forEach((error: any) => {
        this.toastr.error(error.message, error.errorCode);
      });
    } else {
      Swal.fire({
        title: 'Nosso servidor se comportou muito mal!!!',
        text: 'Mas nossos ninjas já estão trabalhando para corrigir o problema.',
        icon: 'error',
        confirmButtonText: 'OK',
        showCancelButton: false,
        timer: 3000,
      });
    }

    return throwError(() => err);
  }
}

export const errorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
