import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
  UrlSegment,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentications';
import { StorageService } from './storage';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard
  implements CanActivate, CanActivateChild, CanDeactivate<any>, CanLoad
{
  constructor(
    private router: Router,
    private jwtHelper: JwtHelperService,
    private storage: StorageService,
    private service: AuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkToken();
  }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (
      (await this.checkToken()) &&
      this.service.checkRoles(childRoute.data['roles'] ?? [])
    )
      return true;
    return await this.router.navigate(['home']);
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return true;
  }

  async checkToken(): Promise<boolean> {
    const auth = this.storage.auth;
    if (auth) {
      if (this.jwtHelper.isTokenExpired(auth.refresh_token)) {
        await this.storage.removeAll();
      } else return true;
    }
    return await this.router.navigate(['/auth/login']);
  }
}
