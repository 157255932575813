import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { AutoComplete } from '@app/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserComponent),
      multi: true
    }
  ]
})
export class UserComponent {

  @Input('formGroup') form: FormGroup = new FormGroup({});
  @Input('profiles') profiles: Array<AutoComplete<string, string>> = [];
  @Input('readonly') readonly: boolean = false;

  userPattern = { 'X': { pattern: new RegExp('\[a-zA-Z0-9@.!#$%&*=+-_\]') } }
  emailPattern = { 'X': { pattern: new RegExp('\[a-zA-Z0-9@.\]') } }
  passwordPattern = { 'X': { pattern: new RegExp('\[a-zA-Z0-9@!#$%&\]') } }

  constructor() { }

  validate(form: FormControl): ValidationErrors | null {
    return form.valid ? null : form.errors;
  }
}
