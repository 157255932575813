import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-message-validate',
  template: `
    <div *ngIf="submitted && errors" class="text-danger">
      {{ message }}
    </div>
  `,
})
export class MessageValidateControl {
  @Input() submitted: boolean | undefined;
  @Input() errors: boolean = false;
  @Input() message: string | undefined;
  @Input() form: FormData | undefined;

  constructor() {}
}
