import { Pagination } from '@app/core/models';

export class GetMessagesRequest {
  read: boolean | undefined;

   get parameters(): string {
    let params = "";
    if (this.read != undefined) params += `&read=${this.read}`;
    return params;
  }
}
