import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { EncryptStorage } from 'encrypt-storage';
import { environment } from '@environment';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

if (environment.production) {
  enableProdMode();
}

export const encryptStorageLocal = new EncryptStorage(environment.secretKey, {
  prefix: `@${environment.name}`
});

export const encryptStorageSession = new EncryptStorage(environment.secretKey, {
  prefix: `@${environment.name}`,
  storageType: 'sessionStorage',
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
