import { Component } from '@angular/core';

import { environment } from '@environment';

@Component({
  selector: 'app-main-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  version: string = `v${environment.version}`;
  nameMerchant: string = environment.nameMerchant;
  siteMerchant: string = environment.nameMerchant;

  constructor() {}
}
