import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';

import { ApiService } from '../api.service';
import { AuthenticationService } from '../authentications';

import { PersonModel } from '../models/person.model';
import { AutoComplete } from './../models';
import {
  SearchParticipantSTRRequest,
  SearchPeopleRequest,
  ViaCEPModel,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private get url(): string {
    return `/api/Common`;
  }

  constructor(private http: HttpClient, private api: ApiService) {}

  searchPeople(request: SearchPeopleRequest): Observable<Array<PersonModel>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<PersonModel>>(
            `${this.url}/People?${request.parameters}`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  accountTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/AccountTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addressTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/AddressTypes`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  cardTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/CardTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  contactTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/ContactTypes`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  currencies(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/Currencies`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  genders(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/Genders`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  legalNatures(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/LegalNatures`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  modules(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/Modules`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  nationalities(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/Nationalities`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  peopleTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/PeopleTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  phoneTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/PhoneTypes`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  taxRegimes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/TaxRegimes`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  ddis(): Observable<Array<AutoComplete<string, string>>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AutoComplete<string, string>>>(
            `${this.url}/DDIs`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  viaCEP(zipCode: string): Observable<ViaCEPModel> {
    return this.http
      .get<ViaCEPModel>(`https://viacep.com.br/ws/${zipCode}/json`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  productTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/ProductTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  productSubTypes(
    type: string
  ): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(
        `${this.url}/ProductTypes/${type}/Subs`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  daysOfWeek(): Observable<Array<AutoComplete<string, number>>> {
    return this.api
      .get<Array<AutoComplete<string, number>>>(`${this.url}/DaysOfWeek`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  recurrenceTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/RecurrenceTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  chargeTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/ChargeTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchParticipantSTR(
    request: SearchParticipantSTRRequest
  ): Observable<Array<any>> {

    return this.api
      .get<Array<any>>(`${this.url}/ParticipantsSTR?${request.parameters}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  termsOfUse(): Observable<string> {
    return this.api.token().pipe(
      switchMap((token) => {
        const timestamp = new Date().getTime();
        const url = this.api.url(
          `${this.url}/TermsOfUse?at=${token.access_token}&r=${timestamp}`
        );
        return [url];
      })
    );
  }

  billingTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/BillingTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  paymentTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/PaymentTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  transferTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/TransferTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  billingStatus(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/BillingStatus`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  paymentStatus(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/PaymentStatus`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  transferStatus(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/TransferStatus`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  launchTypes(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/LaunchTypes`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getParticipantSTRByISPB(
    ispb: string
  ): Observable<Array<any>> {
    return this.api
      .get<Array<any>>(`${this.url}/ParticipantsSTR/${ispb}/ISPB`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  reverseReasons(): Observable<Array<AutoComplete<string, string>>> {
    return this.api
      .get<Array<AutoComplete<string, string>>>(`${this.url}/ReverseReasons`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
