import { Injectable } from '@angular/core';
import { encryptStorageLocal } from 'src/main';
import { encryptStorageSession } from 'src/main';
import { v4 as uuidv4 } from 'uuid';
import { Auth, AuthUser, SearchAccount } from '../authentications';
import { AuthToken } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storageDevice = `__device_storage__`;
  private _storageSearchAccountName = `__sc_account_storage__`;
  private _storageTokenName = `__token_storage__`;
  private _storageAuthName = `__auth_storage__`;
  private _storageA2F = `__a2f_storage__`;
  private _storageUserName = `__user_storage__`;
  private _storageRoleName = `__roles_storage__`;
  private _storageSaveAccountName = `__sv_account_storage__`;

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna a identificação do equipamento
   */
  get device(): string {
    let value = encryptStorageLocal.getItem<string>(this._storageDevice);
    if (!value) {
      value = uuidv4();
      encryptStorageLocal.setItem(this._storageDevice, value);
    }
    return value ?? "";
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna os dados da conta pesquisada
   */
  get searchAccount(): SearchAccount | undefined {
    const value = encryptStorageSession.getItem<SearchAccount>(
      this._storageSearchAccountName
    );
    return value;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Armazena os dados da conta pesquisada
   */
  setSearchAccount(value: SearchAccount): void {
    encryptStorageSession.setItem(this._storageSearchAccountName, value);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Remove os dados da conta pesquisada
   */
  removeSearchAccount(): void {
    encryptStorageSession.removeItem(this._storageSearchAccountName);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna os dados do token de autenticação
   */
  get token(): AuthToken | undefined {
    const value = encryptStorageSession.getItem<AuthToken>(this._storageTokenName);
    return value;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Armazena os dados do token de autenticação
   */
  setToken(value: AuthToken): void {
    encryptStorageSession.setItem(this._storageTokenName, value);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Remove os dados do token de autenticação
   */
  removeToken(): void {
    encryptStorageSession.removeItem(this._storageTokenName);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna o json do usuário logado
   */
  get auth(): Auth | undefined {
    const value = encryptStorageSession.getItem<Auth>(this._storageAuthName);
    return value;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Insere o json do usuário logado
   */
  setAuth(value: Auth): void {
    encryptStorageSession.setItem(this._storageAuthName, value);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna se o usuário foi autenticado com multifator
   */
  get a2f(): boolean {
    const value = encryptStorageSession.getItem<any>(this._storageA2F) ?? false;
    return value.a2f;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Altera o valor da autenticado multifator
   */
  setA2F(value: boolean): void {
    encryptStorageSession.setItem(this._storageA2F, { a2f: value, date: new Date() });
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna o usuário logado
   */
  get user(): AuthUser | undefined {
    const value = encryptStorageSession.getItem<AuthUser>(this._storageUserName);
    return value;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Insere o as roles do usuário logado
   */
  setUser(value: AuthUser): void {
    encryptStorageSession.setItem(this._storageUserName, value);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna as roles do usuário
   */
  get roles(): Array<string> {
    const value =
      encryptStorageSession.getItem<Array<string>>(this._storageRoleName) ?? [];
    return value;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Insere o as roles do usuário logado
   */
  setRoles(value: Array<string>): void {
    encryptStorageSession.setItem(this._storageRoleName, value);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Remove todos os dados do storage
   */
  removeAll(): void {
    encryptStorageSession.removeItem(this._storageSearchAccountName);
    encryptStorageSession.removeItem(this._storageTokenName);
    encryptStorageSession.removeItem(this._storageAuthName);
    encryptStorageSession.removeItem(this._storageA2F);
    encryptStorageSession.removeItem(this._storageUserName);
    encryptStorageSession.removeItem(this._storageRoleName);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Retorna os dados da conta
   */
  get saveAccount(): any {
    const value = encryptStorageLocal.getItem<any>(this._storageSaveAccountName);
    return value;
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Armazena os dados da conta
   */
  setSaveAccount(value: any): void {
    encryptStorageLocal.setItem(this._storageSaveAccountName, value);
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Remove os dados da conta
   */
  removeSaveAccount(): void {
    encryptStorageLocal.removeItem(this._storageSaveAccountName);
  }
}
