import { Component, Input } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-message',
  template: `
    <ng-container *ngIf="message">
      <a
        [style]="style"
        type="button"
        ngbTooltip="Informações"
        (click)="openMessage()"
      >
        <i class="fas fa-triangle-exclamation"></i>
      </a>
    </ng-container>
  `,
})
export class MessageControl {
  @Input() message: string | null = null;

  style: string = '';

  @Input() set status(value: string | null) {
    switch (value) {
      case 'ERROR':
        this.style = 'color: #dc3545';
        break;
      case 'CANCELED':
        this.style = 'color: #ffc107';
        break;
      default:
        this.style = 'color: #007bff';
        break;
    }
  }

  constructor() {}

  openMessage(): void {
    Swal.fire(this.message!);
  }
}
