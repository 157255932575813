export class AccountPhoneModel {
  id: string = '';
  type: string | undefined | null = null;
  isMain: boolean = true;
  ddi: string = '055';
  ddd: string = '';
  number: string = '';
  validated: boolean = false;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
}
