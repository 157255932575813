<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
  </div>

  <div class="modal-body">

    <div class="row" *ngIf="enableOptions">
      <div class="col">
        <div class="form-group d-flex justify-content-evenly">
          <div class="form-check">
            <input class="form-check-input" type="radio" id="type1" formControlName="type" value="password"
              (change)="changeType();">
            <label class="form-check-label" for="type1">{{'labels.password_radio'|translate}}</label>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="radio" id="type2" formControlName="type" value="token"
              (change)="changeType();">
            <label class="form-check-label" for="type2">{{'labels.token_radio'|translate}}</label>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="radio" id="type3" formControlName="type" value="password_token"
              (change)="changeType();">
            <label class="form-check-label" for="type3">{{'labels.password_token_radio'|translate}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="f['type'].value === 'password' || f['type'].value === 'password_token'">
      <label *ngIf="f['type'].value === 'password'" class="my-3">{{'labels.must_have_password' | translate}}</label>
      <div class="col">
        <div class="form-group">
          <label for="password" class="form-label">{{'labels.password' | translate}}</label>
          <div class="input-group">
            <input id="password" class="form-control" type="password" maxlength="6"
              placeholder="{{'placeholders.password' | translate}}" formControlName="password" [app-validated]="form"
              app-see-password only-numbers />
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="f['type'].value === 'token' || f['type'].value === 'password_token'">
      <div class="col">
        <div class="form-group">
          <label for="token">{{'labels.token' | translate}}</label>
          <div class="input-group-two-factor d-flex justify-content-evenly">
            <ng-container *ngFor="let item of numbers; let i = index; trackBy: trackByIndex">
              <input id="number_{{ i }}" type="text" class="form-control m-1" mask="0" minlength="1" maxlength="1"
                required [(ngModel)]="numbers[i]" [libFocus]="shouldFocus(i)" (keyup)="changeFocus(i, $event);"
                [ngModelOptions]="{standalone: true}" />
            </ng-container>

            <input id="token" type="hidden" formControlName="token" [app-validated]="form" />

            <button type="button" class="btn btn-outline-info ml-1" (click)="onSendToken()" [disabled]="timer > 0">
              <ng-container *ngIf="timer > 0">{{timer}}</ng-container>
              <ng-container *ngIf="timer <= 0">{{'buttons.send_token'|translate}}</ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer d-flex justify-content-end">
    <button *ngIf="buttonApprove" type="button" (click)="onSubmit(true)" class="btn btn-primary mr-2"
      [disabled]="!form.valid">{{buttonApprove|translate}}</button>
    <button *ngIf="buttonDisapprove" type="button" (click)="onSubmit(false)" class="btn btn-primary mr-2"
      [disabled]="!form.valid">{{buttonDisapprove|translate}}</button>
    <button *ngIf="buttonCancel" type="button" class="btn btn-secondary"
      (click)="dismiss()">{{'buttons.cancel'|translate}}</button>
  </div>
  <app-loading [start]="loading"></app-loading>
</form>