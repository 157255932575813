import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[copy]',
})
export class CopyValueDirective implements OnInit {
  private _shown = false;
  @Input('copy') value: any;

  constructor(
    private el: ElementRef,
    private toastr: ToastrService,
    private clipboardService: ClipboardService
  ) {}

  ngOnInit(): void {
    this.setup();
  }

  copy() {
    this.clipboardService.copy(this.value);
    const message = 'Copiado';
    this.toastr.success(message);
  }

  setup() {
    const element = this.el.nativeElement;
    const parent = element.parentNode;

    element.className = 'copy';

    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'btn';
    button.innerHTML = `<i class="fa fa-copy"></i>`;

    parent.appendChild(button);
    button.addEventListener('click', () => this.copy());
  }
}
