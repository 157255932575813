import { Component, forwardRef, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { AutoComplete, CommonService } from '@app/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
  ],
})
export class AddressComponent {
  @Input('formGroup') form: FormGroup = new FormGroup({});
  loading: boolean = false;
  zipCode: string | undefined;

  addressTypes: Observable<Array<AutoComplete<string, string>>> =
    this.service.addressTypes();

  constructor(private service: CommonService) {}

  validate(form: FormControl): ValidationErrors | null {
    return form.valid ? null : form.errors;
  }

  zipCodeChange(value: string): void {
    if (!this.loading && value && value.length >= 8 && this.zipCode != value) {
      this.zipCode = value;
      this.loading = true;
      this.service.viaCEP(value).subscribe((model) => {
        if (model && !model.erro) {
          this.form.controls['street'].setValue(model.logradouro);
          this.form.controls['complement'].setValue(model.complemento);
          this.form.controls['neighborhood'].setValue(model.bairro);
          this.form.controls['city'].setValue(model.localidade);
          this.form.controls['state'].setValue(model.uf);
          this.form.controls['country'].setValue('BR');
        }
        this.loading = false;
      });
    }
  }
}
