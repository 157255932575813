import { PayloadList } from './../models/payload-list.model';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { dateToString } from '@app/core/global';
import { ApiService } from '../api.service';
import { ConsolidationReportModel } from './models';

@Injectable({
  providedIn: 'root',
})
export class ConsolidationService {
  private get url(): string {
    return `/api/Consolidations`;
  }

  constructor(private api: ApiService) {}

  accounts(accountId: string, start: Date, end: Date): Observable<PayloadList<ConsolidationReportModel>> {
    return this.api
      .get<PayloadList<ConsolidationReportModel>>(`${this.url}/Accounts/${accountId}?startDate=${dateToString(start)}&endDate=${dateToString(end)}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  accountsChart(accountId: string, start: Date, end: Date): Observable<any> {
    return this.api
      .get<any>(
        `${this.url}/Accounts/${accountId}/Chart?startDate=${dateToString(start)}&endDate=${dateToString(end)}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
