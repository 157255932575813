import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@environment';
import {
  NgcCookieConsentService,
  NgcInitializationErrorEvent,
  NgcInitializingEvent,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { FirebaseService } from './core';
import { stepper } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [stepper],
})
export class AppComponent implements OnInit, OnDestroy {
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(
    private renderer: Renderer2,
    private ccService: NgcCookieConsentService,
    private firebase: FirebaseService
  ) {
    this.setUpClarity();
    this.setTheme();
    this.setTitle();
    this.setIcon();
    this.setInitialLogo();
  }

  setUpClarity(): void {
    const head = document.getElementsByTagName('head')[0];
    const clarity = this.renderer.createElement('script');
    clarity.type = 'text/javascript';
    clarity.text = `
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", '${environment.clarityId}');
    `;

    this.renderer.appendChild(head, clarity);
  }

  setTheme() {
    const style = document.querySelector(
      'link[href="default.css"]'
    ) as HTMLLinkElement;

    if (style == null) {
      const head = document.getElementsByTagName('head')[0];
      const style = this.renderer.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${environment.theme}.css`;
      this.renderer.appendChild(head, style);
    } else {
      style.href = `${environment.theme}.css`;
    }
  }

  setTitle() {
    document.title = `Account ${environment.nameMerchant}`;
  }

  setIcon(): void {
    var icon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    switch (environment.theme) {
      case 'agile':
        icon?.setAttribute('href', `assets/images/agile/favicon.svg`);
        break;
      case 'ecoss':
        icon?.setAttribute('href', `assets/images/ecoss/favicon.svg`);
        break;
      case 'instantepay':
        icon?.setAttribute('href', `assets/images/instantepay/favicon.svg`);
        break;
      case 'nostrum':
        icon?.setAttribute('href', `assets/images/nostrum/favicon.svg`);
        break;
      case 'phizpay':
        icon?.setAttribute('href', `assets/images/phizpay/favicon.svg`);
        break;
      default:
        icon?.setAttribute('href', `assets/images/default/favicon.ico`);
        break;
    }
  }

  setInitialLogo(): void {
    var logo = document.getElementById('logo');
    logo?.setAttribute(
      'src',
      `assets/images/${environment.theme}/logo_dark_background.svg`
    );
  }

  ngOnInit() {
    this.firebase.init();

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`

      }
    );

    this.initializedSubscription = this.ccService.initialized$.subscribe(() => {
      // the cookieconsent has been successfully initialized.
      // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...

    });

    this.initializationErrorSubscription =
      this.ccService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          // the cookieconsent has failed to initialize...

        }
      );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
