import { AccountAnswerableModel, AccountModel } from '@app/core/accounts';
import { FileItem } from '@app/core/models';

export interface Register {
  account: AccountModel | undefined;
  answerable: AccountAnswerableModel | undefined;
  files: Array<FileItem>;
  idAccount: string | undefined;
  idPerson: string | undefined;
  idPersonAnswerable: string | undefined;
  referralId: string | undefined;
  authorize: boolean;
  termofuse: boolean;
  loading: boolean;
}

export const initialRegister: Register = {
  account: undefined,
  answerable: undefined,
  files: [],
  answerableFiles: [],
  idAccount: undefined,
  idPerson: undefined,
  idPersonAnswerable: undefined,
  referralId: undefined,
  authorize: false,
  termofuse: false,
  loading: true,
} as Register;
