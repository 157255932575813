<app-main-header (loading)="loading = $event;"></app-main-header>

<app-main-sidebar></app-main-sidebar>

<main class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>

  <section class="content">
    <section class="container-fluid">
      <router-outlet #outlet="outlet"></router-outlet>
    </section>
  </section>
</main>

<app-main-footer></app-main-footer>
<app-loading [start]="loading"></app-loading>
