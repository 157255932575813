import { AuthMenu } from '@app/core/authentications/models';
import { createAction, props } from '@ngrx/store';
import { TransactionsConfig } from '../models';

export const modifyVisibility = createAction(
  'config/visibility/modify',
  props<{ visibility: boolean }>()
);

export const updateMenu = createAction(
  'config/menu/update',
  props<{ menus: Array<AuthMenu> }>()
);

export const updateConfig = createAction(
  'config/update',
  props<{
    consolidationEnable: boolean,
    acquirerEnable: boolean,
    apiEnable: boolean,
    pixKeyEnable: boolean,
    limitsEnable: boolean,
  }>()
);

export const updateTransactions = createAction(
  'config/transactions/update',
  props<{ transactions: TransactionsConfig }>()
);
