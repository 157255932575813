import {
  AccountBillingModel,
  TransactionPIXService,
} from '@app/core';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-products-billings-pix-view',
  template: `
    <div class="row">
      <div class="col d-flex justify-content-center">
        <h5 *ngIf="dueDate != undefined">
          {{ 'labels.duedate' | translate }}
          {{ dueDate | date: 'dd/MM/yyyy' }}
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <img [src]="qrcode" />
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center" *ngIf="amount">
        <h2>
          {{ 'labels.amount' | translate }}
          {{ amount | currency_format: currency }}
        </h2>
      </div>
    </div>
    <div class="row" *ngIf="emv">
      <div class="col d-flex justify-content-center">
        <span style="max-width: 70%;" [copy]="emv">{{ emv }}</span>
      </div>
    </div>
  `,
})
export class ProductsBillingsPIXViewComponent {
  dueDate: Date | undefined;
  qrcode: string = '';
  currency: string = 'BRA';
  amount: number = 0;
  emv: string | null = null;

  constructor(private service: TransactionPIXService) {}

  @Input() set model(value: AccountBillingModel) {

    this.qrcode = this.service.image(value.id, 'PNG', 256);
    this.dueDate = value.dueDate.toString() == '0001-01-01' ? undefined : value.dueDate;
    this.currency = value.currency;
    this.amount = value.amount;
    this.emv = value.metadata.emv;
  }
}
