export const environment = {
  name: 'staging',
  nameMerchant: 'CoreHub',
  siteMerchant: 'https://www.starfusion.com.br',
  production: false,
  version: `${require('../../package.json').version}--qa`,
  theme: 'default',
  locale: 'pt-BR',
  currency: 'BRL',
  loginExpires: 1200,
  downtime: 3600000,
  secretKey: '19f66a4d19fd6b11.backoffice.staging',
  clarityId: 'fcx3b84nn6',
  firebasePublicKey: 'BEczkzt3k9dQv03gqGVlChCh7IApu-8k6cM5e2E5e62DLYNpiMkn4Qiux8qLcy2nxbbF-H0sZT1z0GQpMgdd-30',
  recaptchaSiteKey: '6Lc6C-0nAAAAAHuepAkIoI5WdMClwtZq4A9BhL_i',
  faceMatchScore: 75,
  methodApproveTransaction: 'password',
  //Informações do corehub
  apiUrl: 'https://service.staging.corehub.com.br',
  clientId: '9AS3YLLMVGTOZNGS3V4TEDP3RX82NEHJ',
  clientSecret: '2c3fofjp8m7eaygrg6v22bupierd3z0n27lqeamfj14nu756kpgblhzneqgne7h6',
  branchId: 'a2e56648-d86a-475d-a1e1-98a00cff0bfa',
  accountNaturalId: 'f95bb482-2194-4b15-929a-71b0804a8298',
  accountJuridicalId: 'f95bb482-2194-4b15-929a-71b0804a8298',  
};
