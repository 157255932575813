export class AccountBalanceModel {
  id: string = '';
  name: string = '';
  taxId: string = '';
  personType: string = '';
  bank: string = '';
  compe: string = '';
  ispb: string = '';
  branch: string = '';
  number: string = '';
  accountType: string = '';
  pixKeyType: string = '';
  pixKey: string = '';
  currency: string = 'BRL';
  balance: number = 0;
  blockedCreditBalance: number = 0;
  blockedDebitBalance: number = 0;
  availableBalance: number = 0;
  canNegativeBalance: boolean = false;
  status: string = '';
  locked: boolean = false;
  message: string = '';
}
