<div [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="name">{{'labels.name' | translate}}</label>
        <input id="name" class="form-control" type="text" maxlength="128"
          placeholder="{{'placeholders.name' | translate}}" formControlName="name" [app-validated]="form" />
        <div *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
          <small class="text-danger" *ngIf="form.controls['name'].hasError('required')">{{'messages.error_name_bucket' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm" *ngIf="form.get('mother')">
      <div class="form-group mb-3">
        <label for="mother">{{'labels.mother' | translate}}</label>
        <input id="mother" class="form-control" type="text" maxlength="128"
          placeholder="{{'placeholders.mother' | translate}}" formControlName="mother" [app-validated]="form" />
      </div>
    </div>

    <div class="col-sm" *ngIf="form.get('father')">
      <div class="form-group mb-3">
        <label for="father">{{'labels.father' | translate}}</label>
        <input id="father" class="form-control" type="text" maxlength="128"
          placeholder="{{'placeholders.father' | translate}}" formControlName="father" [app-validated]="form" />
      </div>
    </div>

    <div class="col-sm" *ngIf="form.get('displayName')">
      <div class="form-group mb-3">
        <label for="displayName">{{'labels.displayName' | translate}}</label>
        <input id="displayName" class="form-control" type="text" maxlength="32"
          placeholder="{{'placeholders.displayName' | translate}}" formControlName="displayName" />
      </div>
    </div>
  </div>

  <div class="row" *ngIf="form.get('socialSecurityNumber') || form.get('voterId')">
    <div class="col-sm" *ngIf="form.get('socialSecurityNumber')">
      <div class="form-group mb-3">
        <label for="socialSecurityNumber">{{'labels.socialSecurityNumber' | translate}}</label>
        <input id="socialSecurityNumber" class="form-control" type="text" maxlength="16"
          placeholder="{{'placeholders.socialSecurityNumber' | translate}}" formControlName="socialSecurityNumber"
          [app-validated]="form" />
      </div>
    </div>

    <div class="col-sm" *ngIf="form.get('voterId')">
      <div class="form-group mb-3">
        <label for="voterId">{{'labels.voterId' | translate}}</label>
        <input id="voterId" class="form-control" type="text" maxlength="12"
          placeholder="{{'placeholders.voterId' | translate}}" formControlName="voterId" [app-validated]="form" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4">
      <div class="form-group mb-3">
        <label for="birth">{{'labels.birthDate' | translate}}</label>
        <app-calendar id="birth" formControlName="birth"></app-calendar>
        <div *ngIf="form.controls['birth'].touched">
          <small class="text-danger" *ngIf="form.controls['birth'].hasError('required')">{{'messages.error_birth_date' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-4" *ngIf="form.get('gender')">
      <div class="form-group mb-3">
        <label for="gender">{{'labels.gender' | translate}}</label>
        <ng-select id="gender" formControlName="gender" [app-validated]="form"
          placeholder="{{'lists.gender.select' | translate}}" [items]="genders | async" bindValue="value">
          <ng-template ng-option-tmp ng-label-tmp let-item="item">{{'lists.gender.'+ item.value | translate}}
          </ng-template>
        </ng-select>
        <div *ngIf="form.controls['gender'].touched">
          <small class="text-danger" *ngIf="form.controls['gender'].hasError('required')">{{'messages.error_gender' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-4" *ngIf="form.get('nationality')">
      <div class="form-group mb-3">
        <label for="nationality">{{'labels.nationality' | translate}}</label>
        <ng-select id="nationality" formControlName="nationality" [app-validated]="form"
          placeholder="{{'lists.nationality.select' | translate}}" [items]="nationalities | async" bindValue="value">
          <ng-template ng-option-tmp ng-label-tmp let-item="item">{{'lists.nationality.'+item.key | translate}}
          </ng-template>
        </ng-select>
        <div *ngIf="form.controls['nationality'].touched">
          <small class="text-danger"
            *ngIf="form.controls['nationality'].hasError('required')">{{'messages.error_nationality' |
            translate}}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="form.get('pep')">
    <div class="col-sm-1 d-flex align-items-end">
      <div class="form-group mb-3">
        <div class="form-check">
          <input id="pep" class="form-check-input" type="checkbox" formControlName="pep" />
          <label for="pep" class="form-check-label">{{'labels.pep' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>