<ul class="pagination">
  <li class="paginate_button page-item previous" [ngClass]="{'disabled': !payloadList.hasPrevious}">
    <button type="button" class="page-link" (click)="onChangePage(1);">
      <span aria-hidden="true">&laquo;</span>
    </button>
  </li>
  <li class="paginate_button page-item previous" [ngClass]="{'disabled': !payloadList.hasPrevious}">
    <button type="button" class="page-link" (click)="onChangePage(payloadList.currentPage-1);">
      <span aria-hidden="true">&lsaquo;</span>
    </button>
  </li>
  <ng-container *ngFor="let item of pages">
    <button type="button" class="paginate_button page-item active" *ngIf="payloadList.currentPage == item">
      <span class="page-link">{{item}}</span>
    </button>
    <li class="paginate_button page-item" *ngIf="payloadList.currentPage != item">
      <button type="button" class="page-link" (click)="onChangePage(item);">{{item}}</button>
    </li>
  </ng-container>
  <li class="paginate_button page-item previous" [ngClass]="{'disabled': !payloadList.hasNext}">
    <button type="button" class="page-link" (click)="onChangePage(payloadList.currentPage+1);">
      <span aria-hidden="true">&rsaquo;</span>
    </button>
  </li>
  <li class="paginate_button page-item previous" [ngClass]="{'disabled': !payloadList.hasNext}">
    <button type="button" class="page-link" (click)="onChangePage(payloadList.totalPages);">
      <span aria-hidden="true">&raquo;</span>
    </button>
  </li>
</ul>
