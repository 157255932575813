import { environment } from '@environment';
import { DBConfig } from 'ngx-indexed-db';
import { Register } from './redux/models';

const DB_NAMES: Array<string> = ['register'];

const request = window.indexedDB.open(environment.name, 1);

request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
  const target = event.target as IDBOpenDBRequest;
  IndexedDB.request = target;
  IndexedDB.db = target.result;
  for (let dbName of DB_NAMES) {
    if (!IndexedDB.db!.objectStoreNames.contains(dbName))
      IndexedDB.db!.createObjectStore(dbName, {});
  }
};

request.onsuccess = (event) => {

  const target = event.target as IDBOpenDBRequest;
  IndexedDB.request = target;
  IndexedDB.db = target.result;
};

request.onerror = (event) => {
  console.error('onerror', event);
};

export class IndexedDB {
  static request: IDBOpenDBRequest | undefined;
  static db: IDBDatabase | undefined;

  static register: Register | undefined;

  static transaction(
    storeName: string,
    mode: IDBTransactionMode
  ): IDBObjectStore | undefined {
    if (IndexedDB.db) {
      const store = IndexedDB.db
        .transaction(storeName, mode)
        .objectStore(storeName);
      return store;
    }
    return undefined;
  }

  static set<T>(storeName: string, uniqueKey: string, value: T): boolean {
    const store = IndexedDB.transaction(storeName, 'readwrite');
    if (store) {
      const request = store.put(value, uniqueKey);
    }
    return true;
  }
}

export const indexedDBConfig: DBConfig = {
  name: environment.name,
  version: 1,
  objectStoresMeta: [
    {
      store: 'register',
      storeConfig: { keyPath: 'idAccount', autoIncrement: false },
      storeSchema: [],
    },
  ],
  migrationFactory,
};

export function migrationFactory() {
  return {
    1: (db: IDBDatabase, transaction: IDBTransaction) => {
      const store = transaction.objectStore('register');
      if (store) {
        const request = store.get('709b5982-5080-4b34-a662-f385ab8e6be4');
        request.onsuccess = (event) => {
          const target = event.target as IDBRequest;
          IndexedDB.register = {
            ...IndexedDB.register,
            ...target.result,
            loading: false,
          };
        };
      }
      IndexedDB.db = db;
    },
  };
}
