<div [formGroup]="form">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="name">{{'labels.name' | translate}}</label>
        <input id="name" class="form-control" type="text" placeholder="{{'placeholders.name' | translate}}"
          formControlName="name" [app-validated]="form" maxlength="32" />
        <div *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
          <small class="text-danger" *ngIf="form.controls['name'].hasError('required')">{{'errors.users.name' |
            translate}}</small>
          <small class="text-danger"
            *ngIf="form.controls['name'].hasError('minlength')">{{'errors.users.minlength' | translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="userName">{{'labels.userName' | translate}}</label>
        <input id="userName" class="form-control" type="text" placeholder="{{'placeholders.userName' | translate}}"
          formControlName="userName" [app-validated]="form" maxlength="64" autocomplete="off"
          [dropSpecialCharacters]="false" [patterns]="userPattern" mask="X*" [readonly]="readonly" />
        <div
          *ngIf="form.controls['userName'].invalid && (form.controls['userName'].dirty || form.controls['userName'].touched)">
          <small class="text-danger" *ngIf="form.controls['userName'].hasError('required')">{{'errors.userName' |
            translate}}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="email">{{'labels.email' | translate}}</label>
        <input id="email" class="form-control" type="email" placeholder="{{'placeholders.email' | translate}}"
          formControlName="email" [app-validated]="form" maxlength="64" />
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="phone">{{'labels.phone' | translate}}</label>
        <input id="phone" class="form-control" type="phone" placeholder="{{'placeholders.phone' | translate}}"
          formControlName="phone" [app-validated]="form" maxlength="17" mask="+00 00 0000-0000||+00 00 00000-0000" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="profileId">{{'labels.profile' | translate}}</label>
        <select id="profileId" class="form-select" formControlName="profileId" [app-validated]="form">
          <option *ngFor="let item of profiles" [value]="item.value">{{item.key}}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="password">{{'labels.password' | translate}}</label>
        <div class="input-group">
          <input id="password" class="form-control" type="password" maxlength="16"
            placeholder="{{'placeholders.password' | translate}}" formControlName="password" [app-validated]="form"
            [dropSpecialCharacters]="false" [patterns]="passwordPattern" mask="X*" autocomplete="off"
            app-see-password />
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="passwordConfirm">{{'labels.passwordconfirm' | translate}}</label>
        <div class="input-group">
          <input id="passwordConfirm" class="form-control" type="password" maxlength="16"
            placeholder="{{'placeholders.passwordconfirm' | translate}}" formControlName="passwordConfirm"
            [app-validated]="form" autocomplete="off" app-see-password />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-auto">
      <div class="form-group mb-3">
        <div class="custom-control custom-switch">
          <input id="changePassword" type="checkbox" class="custom-control-input" formControlName="changePassword">
          <label class="custom-control-label" for="changePassword">{{'labels.changepassword' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="col-auto">
      <div class="form-group mb-3">
        <div class="custom-control custom-switch">
          <input id="a2FEnable" type="checkbox" class="custom-control-input" formControlName="a2FEnable">
          <label class="custom-control-label" for="a2FEnable">{{'labels.althentication_multifactor' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
