
export class SearchAccountsRequest {
  taxId: string | undefined;
  branch: string | undefined;
  account: string | undefined;
  merchantId: string | undefined;

  get parameters(): string {
      let params: string = `merchantId=${this.merchantId}`;

      if (this.taxId)
          params += `&taxId=${this.taxId}`;

      if (this.branch)
          params += `&branch=${this.branch}`;

      if (this.account)
          params += `&account=${this.account}`;

      return params;
  }
}
