export class SearchPeopleRequest {
    taxId: string = '';

    get parameters(): string {
        let params: string = ``;
        if (this.taxId)
            params += `taxId=${this.taxId}`;
        return params;
    }
}
