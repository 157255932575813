import { Component, OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { Payload } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html'
})
export class TwoFactorComponent implements OnInit, AfterViewInit {

  _startTime: number = 0;
  _elapsedTime: number = 0;

  title: string = 'Token de Validação';
  message: string | undefined;
  numbers: Array<string> = ['', '', '', '', '', ''];
  timer: number = 60;

  sendToken: Observable<Payload<number>> | undefined;

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService) {
    this._startTime = new Date().getTime();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(this.countdown.bind(this), 1000);
  }

  countdown(): void {
    if (this.timer < 0) return;
    this.timer -= 1;
    setTimeout(this.countdown.bind(this), 1000);
  }

  trackByIndex(index: number, number: string): number {
    return index;
  }

  elementFocus: string = '';

  shouldFocus(index: number): boolean {
    if (index > 0 && this.numbers[index - 1] === '' || this.numbers[index] !== '')
      return false;
    return true;
  }

  changeFocus(index: number, event: KeyboardEvent): void {
    let elementName: string | undefined = undefined;

    if (event.key === 'Backspace' && index > 0) {
      elementName = `#number_${index - 1}`;
    } else if (index + 1 < this.numbers.length && this.numbers[index]) {
      elementName = `#number_${index + 1}`;
    } else if (index + 1 === this.numbers.length) {
      this.timer = -1;
      this.activeModal.close(this.numbers.join(''));
    }

    if (elementName) {
      const input = document.querySelector(elementName) as HTMLInputElement;
      setTimeout(() => input.focus(), 1);
    }
  }

  resendToken(): void {
    this.sendToken?.subscribe({
      next: payload => {
        switch (payload.code) {
          case 200:
            this.timer = payload.data!;
            setTimeout(this.countdown.bind(this), 1000);
            break;
          default:
            payload.errors?.forEach(error => {
              this.toastr.error(error.message, error.errorCode);
            });
            break;
        }
      },
      error: error => {
        console.error(error);
        const title = 'Error';
        const message = 'Erro no envio do token.';
        this.toastr.error(message, title);
      }
    });
  }

  dismiss(): void { this.activeModal.dismiss(); }
}
