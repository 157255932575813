<ng-container *ngIf="model.key">
  <li>
    <div>{{'labels.key' | translate}} {{model.key}}</div>
  </li>
  <li>
    <hr class="separator">
  </li>
</ng-container>

<ng-container #payer *ngIf="model.payer || model.debtor">
  <li>
    <h5>{{'headers.payer' | translate}}</h5>
    <app-transaction-payer-party [party]="model.payer ?? model.debtor"></app-transaction-payer-party>
  </li>
  <li>
    <hr class="separator">
  </li>
</ng-container>

<ng-container *ngIf="model.receiver">
  <li>
    <h5>{{'headers.receiver' | translate}}</h5>
    <app-transaction-receiver-party [party]="model.receiver"></app-transaction-receiver-party>
  </li>
  <li>
    <hr class="separator">
  </li>
</ng-container>

<ng-container *ngIf="model.endToEndId">
  <li>
    <div>{{'labels.endToEndId' | translate}} {{model.endToEndId}}</div>
  </li>
</ng-container>

<ng-container *ngIf="model.movementId">
  <li>
    <div>{{'labels.movementId' | translate}} {{model.movementId}}</div>
  </li>
</ng-container>
