import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { BreadcrumbItem } from './breadcrumb.item';
import { BreadCrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Array<BreadcrumbItem> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadCrumb: BreadCrumbService
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(filter(() => true)).subscribe(() =>
      setTimeout(() => {
        this.breadcrumbs = this.breadCrumb.build(this.activatedRoute.root);
      }, 100)
    );
  }
}
