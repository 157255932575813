import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { AccountService, ConnectDeviceAccountRequest } from '../accounts';
import { AccountData } from '../redux';
import { StorageService } from '../storage';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private initialized = false;
  private started = false;
  private connected = false;

  constructor(
    private http: HttpClient,
    private toast: ToastrService,
    private device: DeviceDetectorService,
    private storage: StorageService,
    private service: AccountService,
    private store: Store<{ account: AccountData }>
  ) {}

  async init(): Promise<void> {
    if (this.initialized) return;
    this.initialized = true;
    var options = await lastValueFrom(
      this.http.get('/assets/json/firebase.config.json')
    );

    initializeApp(options);
    getAnalytics();
  }

  async start(): Promise<void> {
    if (this.started) return;
    this.started = true;
    this.store.select('account').subscribe((model) => {
      if (!this.connected && model!.user) {
        this.connected = true;
        const accountId: string = model.user?.accountId ?? '';

        if (Notification.permission === 'default') {
          Notification.requestPermission((permission) => {
            console.debug(permission);
            if (permission === 'granted') this.requestPermission(accountId);
          });
        } else {
          this.requestPermission(accountId);
        }
      }
    });
  }

  async requestPermission(accountId: string): Promise<void> {
    await this.init();
    const messaging = getMessaging();

    getToken(messaging, {
      vapidKey: environment.firebasePublicKey,
    })
      .then((currentToken) => {
        const request: ConnectDeviceAccountRequest = {
          name: this.storage.device,
          os: this.device!.os,
          model: this.device!.userAgent,
          brand: this.device!.browser,
          deviceType: this.device!.deviceType,
          token: currentToken,
        };
        this.service
          .connectDevice(accountId, request)
          .subscribe({ next: (payload) => {} });
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
      });

    this.listen();
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      //console.debug(payload);
      const notification = payload.notification;
      if (notification?.body) {
        this.toast.info(notification.body, notification.title);
      }
    });
  }
}

function initialize(service: FirebaseService) {
  return (): Promise<any> => {
    return service.init();
  };
}

export const firebaseProvider = {
  provide: APP_INITIALIZER,
  useFactory: initialize,
  deps: [FirebaseService],
  multi: false,
};
