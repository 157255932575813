import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { AccountData } from '@app/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  private name: string | undefined;
  constructor(
    private readonly title: Title,
    private store: Store<{ account: AccountData }>
  ) {
    super();
    this.store.select('account').subscribe((model) => {
      if (this.name || !model?.user?.name) return;
      this.name = model.user.name;
      this.title.setTitle(this.name);
    });
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    if (this.name) {
      this.title.setTitle(this.name);
    } else {
      let title = this.buildTitle(routerState);

      if (title !== undefined) {
        title = `${environment.nameMerchant} - ${title}`;
      } else {
        title = `${environment.nameMerchant}`;
      }

      this.title.setTitle(`${title}`);
    }
  }
}
