import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  loading: boolean = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'main-page');
    this.renderer.addClass(this.document.body, 'sidebar-mini');
    this.renderer.addClass(this.document.body, 'layout-fixed');
    this.renderer.addClass(this.document.body, 'hold-transition');
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'main-page');
    this.renderer.removeClass(this.document.body, 'sidebar-mini');
    this.renderer.removeClass(this.document.body, 'layout-fixed');
    this.renderer.removeClass(this.document.body, 'hold-transition');
  }

  // ngOnChanges() { }

  // ngDoCheck() { }

  // ngAfterContentInit() { }

  // ngAfterViewInit() { }

  // ngAfterViewChecked() { }
}
