export class AccountTransactionModel {
  id: string = '';
  accountId: string = '';
  currency: string = 'BRL';
  amount: number = 0.0;
  balance: number = 0.0;
  launch: string = '';
  description: string = '';
  entity: string = '';
  metadata: any | null = null;
  executedAt: Date | undefined;
  relationId: string = '';
  canReverse: boolean = false;
}
