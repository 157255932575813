import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { TransactionService } from './transaction.service';
import { Payload, PayloadList } from '../models';
import {
  AccountTransferModel,
  CreateAccountTransferRequest,
  GetAccountTransfersRequest,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class TransactionTransferService {
  private get url(): string {
    return `/api/Transactions`;
  }

  constructor(private api: ApiService) {}

  get(
    pagination: GetAccountTransfersRequest
  ): Observable<PayloadList<AccountTransferModel>> {
    return this.api
      .get<PayloadList<AccountTransferModel>>(
        `${this.url}/Transfers?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getById(transferId: string): Observable<AccountTransferModel> {
    return this.api
      .get<AccountTransferModel>(`${this.url}/Transfers/${transferId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  create(request: CreateAccountTransferRequest): Observable<Payload<any>> {
    return this.api.post<Payload<any>>(`${this.url}/Transfers`, request).pipe(
      map((response) => {
        return response;
      })
    );
  }

  approval(
    transferId: string,
    approved: boolean,
    password: string | undefined | null,
    token: string | undefined | null,
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Transfers/${transferId}/Approval`, {
        approved: approved,
        password: password,
        token: token,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  cancel(transferId: string): Observable<Payload<any>> {
    return this.api
      .delete<Payload<any>>(`${this.url}/Transfers/${transferId}/Cancel`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

}
