import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { DateParserFormatter } from '../adapters';

@Component({
  selector: 'app-calendar-range',
  template: `
    <div class="row">
      <div class="col">
        <div class="dp-hidden position-absolute">
          <div class="input-group">
            <input
              name="datepicker"
              class="form-control"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              [autoClose]="'outside'"
              (dateSelect)="onDateSelection($event)"
              [displayMonths]="2"
              [dayTemplate]="t"
              outsideDays="hidden"
              [startDate]="fromDate!"
              tabindex="-1"
              [minDate]="minNgbDate"
              [maxDate]="maxNgbDate"
            />
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="input-group">
          <input
            #dpFromDate
            class="form-control"
            name="dpFromDate"
            [value]="formatter.format(fromDate)"
            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            [placeholder]="placeholderDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="datepicker.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <input
            #dpToDate
            class="form-control"
            name="dpToDate"
            [value]="formatter.format(toDate)"
            (input)="toDate = validateInput(toDate, dpToDate.value)"
            [placeholder]="placeholderDatepicker"
          />
          <button
            class="btn btn-outline-secondary bi bi-calendar3"
            (click)="datepicker.toggle()"
            type="button"
          >
            <i class="fas fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .dp-hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
      .custom-day.focused {
        background-color: #e6e6e6;
      }
      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }
      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarRangeControl),
      multi: true,
    },
  ],
})
export class CalendarRangeControl {
  hoveredDate: NgbDate | null = null;
  placeholderDatepicker: string | null | undefined = 'DD/MM/YYYY';

  @Output() startDateChange = new EventEmitter<Date | null>();
  @Input() set startDate(value: Date | null) {
    if (value) {
      this.fromDate = this.dateToNgbDate(value);
    } else {
      this.fromDate = null;
    }
  }

  @Output() endDateChange = new EventEmitter<Date | null>();
  @Input() set endDate(value: Date | null) {
    if (value) {
      this.toDate = this.dateToNgbDate(value);
    } else {
      this.toDate = null;
    }
  }

  minNgbDate: NgbDate | null = null;
  @Input() set minDate(value: Date | null) {
    if (value) {
      this.minNgbDate = this.dateToNgbDate(value);
    } else {
      this.minNgbDate = null;
    }
  }

  maxNgbDate: NgbDate | null = null;
  @Input() set maxDate(value: Date | null) {
    if (value) {
      this.maxNgbDate = this.dateToNgbDate(value);
    } else {
      this.maxNgbDate = null;
    }
  }

  @Output('ngModelChange') change = new EventEmitter<{
    startDate: Date | null;
    endDate: Date | null;
  }>();

  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    this.toDate = calendar.getToday();
    this.fromDate = calendar.getNext(calendar.getToday(), 'd', -7);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate && this.toDate) {
      const from = this.ngbDateToDate(this.fromDate);
      const to = this.ngbDateToDate(this.toDate);
      this.startDateChange.emit(from);
      this.endDateChange.emit(to);
      this.change.emit({ startDate: from, endDate: to });
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  dateToNgbDate(value: Date): NgbDate {
    const day = value.getDate();
    const month = value.getMonth() + 1;
    const year = value.getFullYear();
    const date = { year: year, month: month, day: day } as NgbDate;
    return date;
  }

  ngbDateToDate(value: NgbDateStruct): Date | null {
    const str = `${String(value.year).padStart(4, '0')}-${String(
      value.month
    ).padStart(2, '0')}-${String(value.day).padStart(2, '0')}T12:00:00`;
    const parse = Date.parse(str);
    let date = Number.isNaN(parse) ? null : new Date(parse);
    if (date) date = new Date(date.setDate(date.getDate()));
    return date;
  }
}
