import { CreateUpdateAccountAddressModel } from "./create-update-account-address.model";
import { CreateUpdateAccountContactModel } from "./create-update-account-contact.model";
import { CreateUpdateAccountPhoneModel } from "./create-update-account-phone.model";

export class UpdateAccountAnswerableRequest {
  name: string | undefined;
  birth: Date| undefined;
  mother: string| undefined;
  nationality: string| undefined;
  gender: string | undefined;
  pep: boolean| undefined;
  isPartner: boolean| undefined;

  phones: Array<CreateUpdateAccountPhoneModel> | undefined;
  contacts: Array<CreateUpdateAccountContactModel> | undefined;
  addresses: Array<CreateUpdateAccountAddressModel> | undefined;
}
