import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AccountData,
  AccountService,
  AccountTransactionModel,
  AuthUser,
  AutoComplete,
  CommonService,
  dateToString,
  ExportAccountTransactionsRequest,
  GetAccountTransactionsRequest,
  PayloadList,
} from '@app/core';
import { TransactionReceiptComponent } from '@app/shared';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-account-transactions-extract',
  templateUrl: './extract.component.html',
})
export class ExtractComponent implements OnInit {
  user: AuthUser | undefined;
  payload: PayloadList<AccountTransactionModel> =
    new PayloadList<AccountTransactionModel>();
  pagination: GetAccountTransactionsRequest =
    new GetAccountTransactionsRequest();
  loading: boolean = true;
  maxDate: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private service: AccountService,
    private common: CommonService,
    private store: Store<{ account: AccountData }>
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.store.select('account').subscribe({
      next: (model) => {
        if (model.user) {
          this.user = model.user;
          const current = new Date();
          this.pagination.startDate = new Date(new Date().setDate(new Date().getDate() - 7));
          this.pagination.endDate = new Date(current);
          this.onLoad();
        }
      },
    });
  }

  onLoad(): void {
    if (!this.user) return;
    this.loading = true;
    this.service
      .getTransactions(this.user.accountId, this.pagination)
      .subscribe((payload) => {
        this.payload = payload;
        this.pagination.pageNumber = payload.currentPage;
        this.pagination.pageSize = payload.pageSize;
        this.loading = false;
      });
  }

  exportTransactions(type: string) {
    if (!this.user) return;
    this.loading = true;
    const search: ExportAccountTransactionsRequest =
      new ExportAccountTransactionsRequest();
    search.launch = this.pagination.launch;
    search.startDate = this.pagination.startDate;
    search.endDate = this.pagination.endDate;

    this.service
      .exportTransactions(this.user.accountId, type, search)
      .subscribe({
        next: (data) => {
          var downloadURL = window.URL.createObjectURL(data);
          var link = document.createElement('a');
          link.href = downloadURL;
          let name = `extrato_${dateToString(new Date())}`;

          switch (type) {
            case 'CSV':
              name = `${name}.csv`;
              break;
            case 'PDF':
              name = `${name}.pdf`;
              break;
            case 'JSON':
              name = `${name}.json`;
              break;
          }

          link.download = name;
          link.click();
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
      });
  }

  changePageNumber(pageNumber: number): void {
    this.pagination.pageNumber = pageNumber;
    this.onLoad();
  }

  openReceipt(item: AccountTransactionModel): void {

    const modal: NgbModalRef = this.modal.open(TransactionReceiptComponent, {
      centered: true,
    });
    modal.componentInstance.model = item;
    modal.result.then(
      () => {
        this.onLoad();
      },
      () => { }
    );
  }

  _launchs: Observable<Array<AutoComplete<string, string>>> | undefined;
  get launchs(): Observable<Array<AutoComplete<string, string>>> {
    if (!this._launchs) this._launchs = this.common.launchTypes();
    return this._launchs ?? new Observable();
  }

  launchChange(value: string | null): void {
    this.onLoad();
  }
}
