import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core';
import { CustomValidators } from '@app/shared/validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent {

  loading: boolean = false;

  form: FormGroup = this.formBuilder.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', Validators.required],
    newPasswordConfirm: ['', Validators.required]
  }, { validators: [CustomValidators.matchPassword('newPassword', 'newPasswordConfirm')] });

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private service: AuthenticationService) {
  }

  onSubmit(): void {
    this.loading = true;
    this.service.updatePassword(this.form.value).subscribe(payload => {
      switch (payload.code) {
        case 200:
          let titulo = this.translate.instant('titles.success');
          let sucesso = this.translate.instant('messages.password_changed_success');
          this.toastr.success(sucesso, titulo);
          this.activeModal.close(payload);
          break;
        default:
          payload.errors?.forEach(error => {
            this.toastr.error(error.message, error.errorCode);
          });
          break;
      }
      this.loading = false;
    });
  }

  dismiss(): void { this.activeModal.dismiss(); }
}
