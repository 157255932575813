export class SearchParticipantSTRRequest {
  search: string = '';
  isCompe: boolean | null = null;

  get parameters(): string {
    let params: string = '';
    if (this.search) params += `search=${this.search}`;
    if (this.isCompe) params += `IsCompe=${this.isCompe}`;
    return params;
  }
}
