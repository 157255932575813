<nav aria-label="breadcrumb">
  <ol class="breadcrumb d-flex flex-nowrap">
    <ng-container *ngFor="let item of breadcrumbs">
      <li *ngIf="!item.active" class="breadcrumb-item text-limit" [ngClass]="{'active': item.active}">
        <a [routerLink]="item.url">{{item.label | translate}}</a>
      </li>
      <li *ngIf="item.active" class="breadcrumb-item active text-limit" aria-current="page">
        <span>{{item.label | translate}}</span>
      </li>
    </ng-container>
  </ol>
</nav>
