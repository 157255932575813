import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Payload, PayloadList } from '../models';
import {
  AccountPaymentBillingBankSlipModel,
  AccountPaymentBillingQrCodeModel,
  AccountPaymentBillingUtilityModel,
  AccountPaymentModel,
  GetAccountPaymentsRequest,
  PayAccountPaymentBankSlipRequest,
  PayAccountPaymentQrCodeRequest,
  PayAccountPaymentUtilityRequest,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class TransactionPaymentService {
  private get url(): string {
    return `/api/Transactions`;
  }

  constructor(private api: ApiService) {}

  get(
    pagination: GetAccountPaymentsRequest
  ): Observable<PayloadList<AccountPaymentModel>> {
    return this.api
      .get<PayloadList<AccountPaymentModel>>(
        `${this.url}/Payments?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getById(paymentId: string): Observable<AccountPaymentModel> {
    return this.api
      .get<AccountPaymentModel>(`${this.url}/Payments/${paymentId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  approval(
    paymentId: string,
    approved: boolean,
    password: string|undefined|null,
    token: string|undefined|null,
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Payments/${paymentId}/Approval`, {
        approved: approved,
        password: password,
        token: token,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  reverse(paymentId: string): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Payments/${paymentId}/Reverse`, null)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchQrCode(key: string): Observable<AccountPaymentBillingQrCodeModel> {
    return this.api
      .get<AccountPaymentBillingQrCodeModel>(
        `${this.url}/Payments/QrCode/Search?key=${key}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  payQrCode(request: PayAccountPaymentQrCodeRequest): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Payments/QrCode/Pay`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchBankSlip(productId: string, type: string, digitableLine: string): Observable<AccountPaymentBillingBankSlipModel> {
    return this.api
      .get<AccountPaymentBillingBankSlipModel>(
        `${this.url}/Payments/BankSlip/Search?productId=${productId}&type=${type}&search=${digitableLine}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  payBankSlip(
    request: PayAccountPaymentBankSlipRequest
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Payments/BankSlip/Pay`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchUtility(productId: string, type: string, digitableLine: string): Observable<AccountPaymentBillingUtilityModel> {
    return this.api
      .get<AccountPaymentBillingUtilityModel>(
        `${this.url}/Payments/Utility/Search?productId=${productId}&type=${type}&search=${digitableLine}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  payUtility(
    request: PayAccountPaymentUtilityRequest
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Payments/Utility/Pay`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  cancel(paymentId: string): Observable<Payload<any>> {
    return this.api
      .delete<Payload<any>>(`${this.url}/Payments/${paymentId}/Cancel`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
