<div class="modal-header">
  <h5 class="modal-title"></h5>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()"><span
      aria-hidden="true">&times;</span></button>
</div>

<div class="modal-body" #htmlData id="htmlData">
  <ul class="receipt">

    <li class="d-flex justify-content-end mr-4 mb-4" style="height: 45px;">
      <img src='{{imageUrl}}' height="45px" alt="CoreHub" />
    </li>

    <li class="d-flex justify-content-center mt-3">
      <h3>{{model.description}}</h3>
    </li>

    <li class="d-flex justify-content-center">
      <ng-container [ngSwitch]="model.launch">
        <ng-container *ngSwitchCase="'DEBIT'">
          <h1>{{model.amount*-1 | currency_format:model.currency:true}}</h1>
        </ng-container>
        <ng-container *ngSwitchCase="'CREDIT'">
          <h1>{{model.amount | currency_format:model.currency:true}}</h1>
        </ng-container>
      </ng-container>
    </li>

    <li>
      <div *ngIf="model.executedAt != undefined">{{'labels.executed_at'|translate}} {{model.executedAt |
        date:'dd/MM/yyyy H:mm:ss'}}</div>
        <div *ngIf="model.metadata?.dueDate != null">{{'labels.duedate'|translate}}
          {{model.metadata?.dueDate | date:'dd/MM/yyyy'}}</div>
      <div class="font-smal" *ngIf="model.metadata?.digitableLine != null">{{'labels.digitable_line'|translate}}
        {{model.metadata?.digitableLine}}</div>
    </li>


    <li>
      <hr class="separator">
    </li>

    <ng-container *ngIf="model.metadata" [ngSwitch]="model.entity">
      <app-transaction-transfer-receipt *ngSwitchCase="'TRANSFER_INTERNAL'" [model]="model.metadata">
      </app-transaction-transfer-receipt>

      <app-transaction-transfer-receipt *ngSwitchCase="'TRANSFER_EXTERNAL'" [model]="model.metadata">
      </app-transaction-transfer-receipt>

      <app-transaction-transfer-receipt *ngSwitchCase="'TRANSFER_PIX'" [model]="model.metadata">
      </app-transaction-transfer-receipt>

      <app-transaction-payment-receipt *ngSwitchCase="'PAYMENT_QRCODE'" [model]="model.metadata">
      </app-transaction-payment-receipt>

      <app-transaction-payment-receipt *ngSwitchCase="'PAYMENT_BANK_SLIP'" [model]="model.metadata">
      </app-transaction-payment-receipt>

      <app-transaction-payment-receipt *ngSwitchCase="'PAYMENT_UTILITY'" [model]="model.metadata">
      </app-transaction-payment-receipt>

      <app-transaction-payment-receipt *ngSwitchCase="'PAYMENT_PIX'" [model]="model.metadata">
      </app-transaction-payment-receipt>

      <app-transaction-billing-receipt *ngSwitchCase="'BILLING_QRCODE'" [model]="model.metadata">
      </app-transaction-billing-receipt>

      <app-transaction-billing-receipt *ngSwitchCase="'BILLING_BANK_SLIP'" [model]="model.metadata">
      </app-transaction-billing-receipt>

      <app-transaction-billing-receipt *ngSwitchCase="'BILLING_PIX'" [model]="model.metadata">
      </app-transaction-billing-receipt>

      <app-transaction-payment-fee-receipt *ngSwitchCase="'PAYMENT_FEE'" [model]="model.metadata">
      </app-transaction-payment-fee-receipt>

      <app-transaction-receive-fee-receipt *ngSwitchCase="'RECEIVE_FEE'" [model]="model.metadata">
      </app-transaction-receive-fee-receipt>

      <app-transaction-receive-receipt *ngSwitchCase="'RECEIVE_PIX'" [model]="model.metadata">
      </app-transaction-receive-receipt>
    </ng-container>
  </ul>
</div>

<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-primary mr-2" (click)="downloadPDF();">{{'buttons.pdf' | translate}}</button>
    <button type="button" class="btn btn-primary mr-2" (click)="reverse();" *ngIf="modelReverse?.canReverse">{{'buttons.reverse' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="dismiss();">{{'buttons.close' | translate}}</button>
  </div>
</div>