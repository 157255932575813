import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StorageService } from './../storage/storage.service';

import { HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from '../api.service';
import { Payload } from '../models';
import {
  Auth,
  AuthMenu,
  AuthUser,
  AuthenticateRequest,
  GetTokenRequest,
  RefreshTokenRequest,
  SearchAccount,
  UpdateAuthenticationUserPasswordRequest,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public static TOKEN_HEADER_KEY: string = 'Authorization';
  private get url(): string {
    return `/api/Authentication`;
  }

  constructor(
    private jwtHelper: JwtHelperService,
    private storage: StorageService,
    private api: ApiService
  ) {}

  /**
   * Localiza os dados da conta
   * @param branch Agência
   * @param account Número da conta
   * @returns Dados da conta
   */
  searchAccount(
    branch: string,
    account: string
  ): Observable<Payload<SearchAccount>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .get<Payload<SearchAccount>>(
            `${this.url}/Account/${branch}/${account}`,
            httpOptions
          )
          .pipe(
            map((response) => {
              if (response != null && response.code == 200 && response.data) {
                this.storage.setSearchAccount(response.data);
              }
              return response;
            })
          );
      })
    );
  }

  /**
   * Faz o login na conta
   * @param request
   * @returns
   */
  signIn(
    accountId: string,
    request: AuthenticateRequest
  ): Observable<Payload<Auth>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const body = new HttpParams()
          .set('operator', request.operator)
          .set('password', request.password)
          .set('expires', request.expires);
        const headers = new HttpHeaders()
          .set(
            AuthenticationService.TOKEN_HEADER_KEY,
            `${token.token_type} ${token.access_token}`
          )
          .set('Content-Type', 'application/x-www-form-urlencoded');
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<Auth>>(
            `${this.url}/Account/${accountId}/SignIn`,
            body,
            httpOptions
          )
          .pipe(
            map((response) => {
              if (response != null && response.code == 200 && response.data) {
                this.storage.setAuth(response.data);
              }
              return response;
            })
          );
      })
    );
  }

  /**
   * Faz o login no sistema
   * @param request
   * @returns
   */
  token(request: GetTokenRequest): Observable<Payload<Auth>> {

    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', request.clientId)
      .set('client_secret', request.clientSecret)
      .set('expires', `${request.expires}`);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('api-version', '2.0');

    const httpOptions = { headers: headers };

    return this.api
      .post<Payload<Auth>>(`${this.url}/Token`, body, httpOptions)
      .pipe(
        map((response) => {
          if (response != null && response.code == 200) {
            this.storage.setAuth(response.data!);
          }
          return response;
        })
      );
  }

  /**
   * Atualiza o token
   * @param request
   * @returns
   */
  refreshToken(request: RefreshTokenRequest): Observable<Payload<Auth>> {
    const body = new HttpParams()
      .set('token', request.token)
      .set('expires', `${request.expires}`);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.api
      .post<Payload<Auth>>(`${this.url}/RefreshToken`, body, {
        headers: headers,
      })
      .pipe(
        map((response: Payload<Auth>) => {
          if (response != null && response.code == 200) {
            this.storage.setAuth(response.data!);
            this.onLoad();
          }
          return response;
        })
      );
  }

  refreshToken_v2(request: RefreshTokenRequest): Observable<Payload<Auth>> {
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('token', request.token)
      .set('expires', `${request.expires}`);

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('api-version', '2.0');

    const httpOptions = { headers: headers };

    return this.api
      .post<Payload<Auth>>(`${this.url}/Token`, body, httpOptions)
      .pipe(
        map((response: Payload<Auth>) => {
          if (response != null && response.code == 200) {
            this.storage.setAuth(response.data!);
            this.onLoad();
          }
          return response;
        })
      );
  }

  /**
   * Faz logoff no sistema
   * @returns
   */
  async signOut(): Promise<boolean> {
    if (
      this.storage.auth?.access_token &&
      !this.jwtHelper.isTokenExpired(this.storage.auth?.access_token)
    )
      try {
        await lastValueFrom(
          this.api.post<Payload<any>>(`${this.url}/SignOut`, null).pipe(
            map((response) => {
              return response;
            }),
            catchError((err, caught) => {
              console.error(err);
              return new Observable<Payload<any>>();
            })
          )
        );
        this.storage.removeAll();
      } catch {
        this.storage.removeAll();
        return false;
      }
    return true;
  }

  updatePassword(
    request: UpdateAuthenticationUserPasswordRequest
  ): Observable<Payload<any>> {
    const body = new HttpParams()
      .set('currentpassword', request.currentPassword)
      .set('newpassword', request.newPassword)
      .set('confirmpassword', request.newPassword);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const httpOptions = { headers: headers };
    return this.api
      .put<Payload<any>>(`${this.url}/User`, body, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  checkRoles(roles: Array<string>): boolean {
    if (roles?.length > 0) {
      let models = this.storage.roles;
      let result = roles.map((value) => {
        return models!.includes(value);
      });
      return result.includes(true);
    }
    return true;
  }

  async onLoad(): Promise<boolean> {
    try {
      const user = lastValueFrom(
        this.api.get<AuthUser>(`${this.url}/Account`).pipe(
          map((model) => {
            this.storage.setUser(model);
          })
        )
      );
      await Promise.all([user]);
      return true;
    } catch (e) {
      return false;
    }
  }

  get menus(): Observable<Array<AuthMenu>> {
    return this.api.get<Array<AuthMenu>>(`${this.url}/Menus`);
  }

  a2f(otp: string): Observable<Payload<any>> {
    const body = new HttpParams().set('otp', otp);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = { headers: headers };
    return this.api
      .post<Payload<any>>(`${this.url}/A2F`, body, httpOptions)
      .pipe(
        map((response) => {
          switch (response.code) {
            case 200:
              this.storage.setA2F(true);
              break;
            default:
              this.storage.setA2F(false);
              break;
          }
          return response;
        })
      );
  }

  a2fQrCode(type: string, size: number): string {
    const timestamp = new Date().getTime();
    return this.api.url(
      `${this.url}/User/A2F/${type}/${size}?at=${this.api.auth?.access_token}&${timestamp}`
    );
  }
}
