import {
  AccountAddressModel,
  AccountAnswerableModel,
  AccountContactModel,
  AccountJuridicalModel,
  AccountNaturalModel,
  AccountPhoneModel,
} from '@app/core';
import { environment } from '@environment';
import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions';
import { initialRegister } from '../models';
import { state } from '@angular/animations';
import { ACTIONS_SUBJECT_PROVIDERS } from '@ngrx/store/src/actions_subject';

export const registerReducer = createReducer(
  initialRegister,
  on(actions.register, (state, action) => {
    return {
      ...state,
      ...action,
    };
  }),
  on(actions.registerClear, (state) => {
    return {
      ...initialRegister,
    };
  }),
  on(actions.updateAccount, (state, action) => {
    return {
      ...state,
      ...action,
    };
  }),
  on(actions.files, (state, action) => {
    return {
      ...state,
      files: action.files,
    };
  }),
  // Natural
  on(actions.natural, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountNaturalModel()),
        ...action.data,
      },
    };
  }),
  on(actions.naturalTermOfUse, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountNaturalModel()),
        ...action.data,
      },
      termofuse: action.termofuse,
    };
  }),
  on(actions.naturalTaxId, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountNaturalModel()),
        productId: environment.accountNaturalId ?? '',
        branchId: environment.branchId,
        taxId: action.taxId,
        type: 'CC',
        personType: 'N',
        nationality: 'BR',
        tags: ['internetbank'],
      },
      authorize: action.authorize,
      referralId: action.referralId,
    };
  }),
  on(actions.naturalData, (state, action) => ({
    ...state,
    account: {
      ...(state.account ?? new AccountNaturalModel()),
      id: action.id,
      idPerson: action.personId,
      status: action.status,
      name: action.name,
      birth: action.birth,
      nationality: action.nationality,
      mother: action.mother,
      gender: action.gender,
      pep: action.pep,
      phones: [
        {
          ...(state.account?.phones[0] ?? new AccountPhoneModel()),
          id: action.phoneId,
          ddi: '055',
          ddd: `0${action.phone!.substring(0, 2)}`,
          number: action.phone!.substring(2, action.phone!.length),
          type: 'CELLPHONE',
          isMain: true,
          validated: action.phoneValidated
        },
      ],
      contacts: [
        {
          ...(state.account?.contacts[0] ?? new AccountContactModel()),
          id: action.contactId,
          email: action.contact!,
          type: 'PERSONAL',
          isMain: true,
          validated: action.contactValidated
        },
      ],
    },
  })),
  on(actions.naturalAddress, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountNaturalModel()),
        addresses: [
          {
            ...(state.account?.addresses[0] ?? new AccountAddressModel()),
            id: action.id,
            type: 'RESIDENTIAL',
            zipCode: action.zipCode,
            street: action.street,
            number: action.number,
            complement: action.complement,
            neighborhood: action.neighborhood,
            city: action.city,
            state: action.state,
            country: action.country,
          },
        ],
      },
    };
  }),
  // Juridical
  on(actions.juridical, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountJuridicalModel()),
        ...action.data,
      },
    };
  }),
  on(actions.juridicalTermOfUse, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountJuridicalModel()),
        ...action.data,
      },
      answerable: {
        ...(state.answerable ?? new AccountAnswerableModel()),
        ...(action.answerable ?? new AccountAnswerableModel()),
      },
      termofuse: action.termofuse,
    };
  }),
  on(actions.juridicalTaxId, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountJuridicalModel()),
        productId: environment.accountJuridicalId ?? '',
        branchId: environment.branchId,
        type: 'CC',
        personType: 'J',
        taxId: action.taxId,
        referralId: action.referralId,
        tags: ['internetbank'],
      },
      authorize: action.authorize,
    };
  }),
  on(actions.juridicalData, (state, action) => {
    return {
      ...state,
      idAccount: action.id,
      idPerson: action.personId,
      account: {
        ...(state.account ?? new AccountJuridicalModel()),
        id: action.id,
        personId: action.personId,
        status: action.status,
        acceptedTerm: action.acceptedTerm,
        name: action.name,
        birth: action.birth,
        tradeName: action.tradeName,
        taxRegime: action.taxRegime,
        legalNature: action.legalNature,
        registration: action.registration,
        phones: [
          {
            ...(state.account?.phones[0] ?? new AccountPhoneModel()),
            id: action.phoneId,
            ddi: '055',
            ddd: `0${action.phone!.substring(0, 2)}`,
            number: action.phone!.substring(2, action.phone!.length),
            type: 'COMMERCIAL',
            isMain: true,
            validated: action.phoneValidated
          },
        ],
        contacts: [
          {
            ...(state.account?.contacts[0] ?? new AccountContactModel()),
            id: action.contactId,
            email: action.contact!,
            type: 'COMMERCIAL',
            isMain: true,
            validated: action.contactValidated
          },
        ],
      },
    };
  }),
  on(actions.juridicalAddress, (state, action) => {
    return {
      ...state,
      account: {
        ...(state.account ?? new AccountJuridicalModel()),
        addresses: [
          {
            ...(state.account?.addresses[0] ?? new AccountAddressModel()),
            id: action.id,
            type: 'COMMERCIAL',
            zipCode: action.zipCode,
            street: action.street,
            number: action.number,
            complement: action.complement,
            neighborhood: action.neighborhood,
            city: action.city,
            state: action.state,
            country: action.country,
          },
        ],
      },
    };
  }),
  on(actions.updateAccountAnswerable, (state, action) => {
    return {
      ...state,
      idPersonAnswerable: action.idPersonAnswerable,
      loading: false,
    };
  }),
  on(actions.answerable, (state, action) => {
    return {
      ...state,
      answerable: {
        ...(state.answerable ?? new AccountAnswerableModel()),
        ...(action ?? new AccountAnswerableModel()),
      },
    };
  }),
  on(actions.answerableTaxId, (state, action) => {
    return {
      ...state,
      answerable: {
        ...(state.answerable ?? new AccountAnswerableModel()),
        taxId: action.taxId,
        authorize: action.authorize,
      },
    };
  }),
  on(actions.answerableAccount, (state, action) => {
    return {
      ...state,
      answerable: {
        ...(state.answerable ?? new AccountAnswerableModel()),
        accountId: action.accountId,
        personId: action.personId,
      },
    };
  }),
  on(actions.answerableData, (state, action) => {
    return {
      ...state,
      answerable: {
        ...(state.answerable ?? new AccountAnswerableModel()),
        personId: action.personId,
        name: action.name,
        birth: action.birth,
        mother: action.mother,
        nationality: action.nationality,
        gender: action.gender,
        pep: action.pep,
        phones: [
          {
            ...(state.answerable?.phones[0] ?? new AccountPhoneModel()),
            id: action.phoneId,
            ddi: '055',
            ddd: `0${action.phone.substring(0, 2)}`,
            number: action.phone.substring(2, action.phone.length),
            type: 'CELLPHONE',
            isMain: true,
            validated: action.phoneValidated
          },
        ],
        contacts: [
          {
            ...(state.answerable?.contacts[0] ?? new AccountContactModel()),
            id: action.contactId,
            email: action.contact,
            type: 'PERSONAL',
            isMain: true,
            validated: action.contactValidated
          },
        ],
      },
    };
  }),
  on(actions.answerableAddress, (state, action) => {
    return {
      ...state,
      answerable: {
        ...(state.answerable ?? new AccountAnswerableModel()),
        addresses: [
          {
            ...(state.answerable?.addresses[0] ?? new AccountAddressModel()),
            type: 'RESIDENTIAL',
            zipCode: action.zipCode,
            street: action.street,
            number: action.number,
            complement: action.complement,
            neighborhood: action.neighborhood,
            city: action.city,
            state: action.state,
            country: action.country,
          },
        ],
      },
    };
  })
);
