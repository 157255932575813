<div [formGroup]="form">
  <div class="row">
    <div class="col-sm-2">
      <div class="form-group mb-3">
        <label for="zipCode">{{'labels.zipcode' | translate}}</label>
        <input id="zipCode" class="form-control" type="text" placeholder="{{'placeholders.zipcode' | translate}}"
          formControlName="zipCode" [app-validated]="form" mask="00000-000" (ngModelChange)="zipCodeChange($event);" />
        <div
          *ngIf="form.controls['zipCode'].invalid && (form.controls['zipCode'].dirty || form.controls['zipCode'].touched)">
          <small class="text-danger"
            *ngIf="form.controls['zipCode'].hasError('required')">{{'messages.error_zipcode' | translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group mb-3">
        <label for="type">{{'labels.type' | translate}}</label>
        <ng-select id="type" formControlName="type" [app-validated]="form"
        placeholder="{{'lists.type_address.select' | translate}}"
        [items]="addressTypes | async" bindValue="key">
        <ng-template
          ng-option-tmp ng-label-tmp let-item="item">{{'lists.type_address.' + item.key | translate}}
        </ng-template>
        </ng-select>
        <div *ngIf="form.controls['type'].touched">
          <small class="text-danger" *ngIf="form.controls['type'].hasError('required')">{{'messages.error_address_type' |
            translate}}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group mb-3">
        <label for="street">{{'labels.street' | translate}}</label>
        <input id="street" class="form-control" type="text" placeholder="{{'placeholders.street' | translate}}"
          formControlName="street" [app-validated]="form" maxlength="128" />
        <div
          *ngIf="form.controls['street'].invalid && (form.controls['street'].dirty || form.controls['street'].touched)">
          <small class="text-danger"
            *ngIf="form.controls['street'].hasError('required')">{{'messages.error_street' | translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-2">
      <div class="form-group mb-3">
        <label for="number">{{'labels.number' | translate}}</label>
        <input id="number" class="form-control" type="text" placeholder="{{'placeholders.number' | translate}}"
          formControlName="number" [app-validated]="form" maxlength="16" />
        <div
          *ngIf="form.controls['number'].invalid && (form.controls['number'].dirty || form.controls['number'].touched)">
          <small class="text-danger"
            *ngIf="form.controls['number'].hasError('required')">{{'messages.required_number' | translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="form-group mb-3">
        <label for="complement">{{'labels.complement' | translate}}</label>
        <input id="complement" class="form-control" type="text"
          placeholder="{{'placeholders.complement' | translate}}" formControlName="complement" maxlength="32" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-5">
      <div class="form-group mb-3">
        <label for="neighborhood">{{'labels.neighborhood' | translate}}</label>
        <input id="neighborhood" class="form-control" type="text"
          placeholder="{{'placeholders.neighborhood' | translate}}" formControlName="neighborhood"
          [app-validated]="form" maxlength="64" />
        <div
          *ngIf="form.controls['neighborhood'].invalid && (form.controls['neighborhood'].dirty || form.controls['neighborhood'].touched)">
          <small class="text-danger"
            *ngIf="form.controls['neighborhood'].hasError('required')">{{'messages.error_neighborhood' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="form-group mb-3">
        <label for="city">{{'labels.city' | translate}}</label>
        <input id="city" class="form-control" type="text" placeholder="{{'placeholders.city' | translate}}"
          formControlName="city" [app-validated]="form" maxlength="64" />
        <div *ngIf="form.controls['city'].invalid && (form.controls['city'].dirty || form.controls['city'].touched)">
          <small class="text-danger" *ngIf="form.controls['city'].hasError('required')">{{'messages.error_city' |
            translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-2">
      <div class="form-group mb-3">
        <label for="state">{{'labels.state' | translate}}</label>
        <input id="state" class="form-control" type="text" placeholder="{{'placeholders.state' | translate}}"
          formControlName="state" [app-validated]="form" maxlength="2" />
        <div *ngIf="form.controls['state'].invalid && (form.controls['state'].dirty || form.controls['state'].touched)">
          <small class="text-danger" *ngIf="form.controls['state'].hasError('required')">{{'messages.error_state'
            | translate}}</small>
        </div>
      </div>
    </div>

    <div class="col-sm-2">
      <div class="form-group mb-3">
        <label for="country">{{'labels.country' | translate}}</label>
        <ng-select id="country" clearable="false" formControlName="country" [app-validated]="form">
          <ng-option value="">{{'lists.country.select' | translate}}</ng-option>
          <ng-option value="BR">{{'lists.country.brazil' | translate}}</ng-option>
        </ng-select>
        <div *ngIf="form.controls['country'].touched">
          <small class="text-danger"
            *ngIf="form.controls['country'].hasError('required')">{{'messages.error_country' | translate}}</small>
        </div>
      </div>
    </div>
  </div>
  <app-loading [start]="loading"></app-loading>
</div>
