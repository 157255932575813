import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularInputFocusModule } from 'angular-input-focus';
import {
  LAZYLOAD_IMAGE_HOOKS,
  LazyLoadImageModule,
  ScrollHooks,
} from 'ng-lazyload-image';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from 'ngx-mask';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgxTagsInputBoxModule } from 'ngx-tags-input-box';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';

import {
  BoxLayoutComponent,
  FooterComponent,
  HeaderComponent,
  MainLayoutComponent,
  SidebarComponent,
} from './layouts';

import {
  AttachmentViewComponent,
  AttachmentsComponent,
  BreadcrumbComponent,
  GridCountComponent,
  IconStatusComponent,
  InternalMenuComponent,
  LoadingComponent,
  PaginationComponent,
  ProductsBillingsBankSlipsViewComponent,
  ProductsBillingsPIXViewComponent,
  ProductsBillingsQrCodeViewComponent,
  ProductsBillingsViewComponent,
  TermsOfUseComponent,
  TransactioReceiveReceiptComponent,
  TransactionAccountPartyComponent,
  TransactionBillingReceiptComponent,
  TransactionMerchantPartyComponent,
  TransactionPayerPartyComponent,
  TransactionPaymentFeeReceiptComponent,
  TransactionPaymentReceiptComponent,
  TransactionReceiptComponent,
  TransactionReceiveFeeReceiptComponent,
  TransactionReceiverPartyComponent,
  TransactionTransferReceiptComponent,
  TwoFactorComponent,
  VirtualKeyboardComponent,
} from './components';

import {
  AddressComponent,
  ChangePasswordComponent,
  ContactComponent,
  PersonJuridicalComponent,
  PersonNaturalComponent,
  PhoneComponent,
  ProfileComponent,
  UserComponent,
} from './forms';

import { ServiceWorkerModule } from '@angular/service-worker';
import { QRCodeModule } from 'angularx-qrcode';
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { SvgComponent } from './components/icon/icon.component';
import { TransactionApproveComponent } from './components/transactions/approve/approve.component';
import { currencyMaskConfig, toastrConfig } from './configs';
import { CalendarControl, MessageControl, CalendarRangeControl } from './controls';
import { MessageValidateControl } from './controls/message-validate.controls';
import {
  AccountMaskDirective,
  CheckRoleDirective,
  ControlValidatedDirective,
  CopyInputDirective,
  CopyValueDirective,
  DropZoneDirective,
  SeePasswordDirective,
  TaxIdMaskDirective,
} from './directives';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { AccountFormat, CurrencyFormat, TaxIdFormat } from './pipes';
import { DateAgoPipe } from './pipes/date-ago-format.pipe';

export const ISOLATED_MODULES = [
  ToastrModule.forRoot(toastrConfig),
  NgxCurrencyModule.forRoot(currencyMaskConfig),
  ServiceWorkerModule,
  QRCodeModule,
];

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,

  TranslateModule,
  NgxTagsInputBoxModule,
  NgxDocViewerModule,
  LazyLoadImageModule,
  ClipboardModule,
  NgChartsModule,
  NgSelectModule,
  NgxPopperjsModule,

  NgxMaskDirective,
  NgxMaskPipe,

  NgbModule,

  ToastContainerModule,
  AngularInputFocusModule,
];

const COMPONENTS = [
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  BoxLayoutComponent,
  MainLayoutComponent,
  BreadcrumbComponent,
  LoadingComponent,
  PaginationComponent,
  GridCountComponent,
  AttachmentsComponent,
  AttachmentViewComponent,
  PersonNaturalComponent,
  PersonJuridicalComponent,
  PhoneComponent,
  ContactComponent,
  AddressComponent,
  UserComponent,
  ProfileComponent,
  ChangePasswordComponent,
  TwoFactorComponent,
  TermsOfUseComponent,
  VirtualKeyboardComponent,
  // Receipt
  TransactionReceiptComponent,
  TransactionAccountPartyComponent,
  TransactionPayerPartyComponent,
  TransactionReceiverPartyComponent,
  TransactionMerchantPartyComponent,
  TransactionTransferReceiptComponent,
  TransactionPaymentReceiptComponent,
  TransactionBillingReceiptComponent,
  TransactionPaymentFeeReceiptComponent,
  TransactionReceiveFeeReceiptComponent,
  TransactioReceiveReceiptComponent,

  TransactionApproveComponent,

  IconStatusComponent,

  //Products
  ProductsBillingsViewComponent,
  ProductsBillingsQrCodeViewComponent,
  ProductsBillingsPIXViewComponent,
  ProductsBillingsBankSlipsViewComponent,

  SvgComponent,
  //Navs
  InternalMenuComponent,
];

const CONTROLS = [CalendarControl, MessageControl, MessageValidateControl, CalendarRangeControl];

const DIRECTIVES = [
  SeePasswordDirective,
  ControlValidatedDirective,
  CheckRoleDirective,
  DropZoneDirective,
  CopyInputDirective,
  CopyValueDirective,
  TaxIdMaskDirective,
  AccountMaskDirective,
  OnlyNumbersDirective,
  UppercaseDirective
];

const PIPES = [CurrencyFormat, AccountFormat, TaxIdFormat, DateAgoPipe];

const PROVIDERS = [
  { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  { provide: NgChartsConfiguration, useValue: { generateColors: false } },
  provideEnvironmentNgxMask({ validation: true }),
];

@NgModule({
  imports: [...MODULES, ...ISOLATED_MODULES],
  exports: [...MODULES, ...COMPONENTS, ...CONTROLS, ...DIRECTIVES, ...PIPES],
  declarations: [...COMPONENTS, ...CONTROLS, ...DIRECTIVES, ...PIPES],
  providers: [...PROVIDERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
