import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment } from './attachment.model';
import { FileModel } from './file.model';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
})
export class AttachmentsComponent implements OnInit {
    @Input('add-check-role') addCheckRole: Array<string> = [];
    @Input('view-check-role') viewCheckRole: Array<string> = [];
    @Input('delete-check-role') deleteCheckRole: Array<string> = [];
  
    files: Array<FileModel> = [];
  
    @Input() attachments: Array<Attachment> = [];
  
    uploadStarted: boolean = false;
  
    @Output() uploadFiles: EventEmitter<Array<FileModel>> = new EventEmitter<
      Array<FileModel>
    >();
    @Output() deleteFile: EventEmitter<Attachment> =
      new EventEmitter<Attachment>();
    @Output() visualizeItem: EventEmitter<Attachment> =
      new EventEmitter<Attachment>();
  
    constructor() {}
  
    ngOnInit(): void {}
  
    onFileDropped(files: Array<File>): void {
      [...files].forEach((element) => {
        this.files = [...this.files, new FileModel(element)];
      });
      this.uploadStarted = false;
    }
  
    formatBytes(bytes: number, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  
    onDeleteFile(value: FileModel): void {
      if (!value) return;
      const files = this.files;
      const aux: Array<FileModel> = [];
      files.forEach((file: FileModel, index: number) => {
        if (file !== value) aux.push(file);
      });
      this.files = aux;
    }
  
    onUploadFiles(): void {
      this.uploadStarted = true;
      const files = this.files;
      files.forEach((file) => {
        file.uploadCompletedCallback = (value: FileModel) =>
          setTimeout(() => this.onDeleteFile(value), 5000);
      });
      this.uploadFiles.emit(files);
    }
  
    onVisualizeItem(value: Attachment): void {
      this.visualizeItem.emit(value);
    }
  
    onDeleteItem(value: Attachment): void {
      this.deleteFile.emit(value);
    }
  }
  