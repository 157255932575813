import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { ApiService } from '../api.service';
import { AuthenticationService } from '../authentications';
import { Payload, PayloadList } from '../models';
import {
  AcceptTermAccountRequest,
  AccountAcquirerTransactionModel,
  AccountAcquirerTransactionScheduleModel,
  AccountAnswerableModel,
  AccountBAnswerableModel,
  AccountBalanceModel,
  AccountConfigModel,
  AccountCredentialModel,
  AccountLimitsModel,
  AccountModel,
  AccountPersonFileModel,
  AccountPIXKeyModel,
  AccountTransactionModel,
  AccountWebhookModel,
  ConnectDeviceAccountRequest,
  CreateAccountAnswerableRequest,
  CreateAccountCredentialRequest,
  CreateAccountJuridicalRequest,
  CreateAccountNaturalRequest,
  CreateAccountPIXKeyRequest,
  ExportAccountTransactionsRequest,
  GetAccountAcquirerTransactionSchedulesRequest,
  GetAccountAcquirerTransactionsRequest,
  GetAccountAnswerablesRequest,
  GetAccountCredentialsRequest,
  GetAccountPersonFileRequest,
  GetAccountPIXKeysRequest,
  GetAccountsRequest,
  GetAccountTransactionsRequest,
  GetAccountWebhooksRequest,
  SearchAccountsRequest,
  UpdateAccountAnswerableRequest,
  UpdateAccountJuridicalRequest,
  UpdateAccountLimitsRequest,
  UpdateAccountNaturalRequest,
  UpdateAccountWebhookRequest,
} from './models';
import { GetMessagesRequest } from './models/get-messages-request';
import { MessageModel } from './models/message.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private get url(): string {
    return `/api/Accounts`;
  }

  constructor(private api: ApiService) {}

  get(pagination: GetAccountsRequest): Observable<PayloadList<AccountModel>> {
    return this.api
      .get<PayloadList<AccountModel>>(`${this.url}?${pagination.parameters}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  search(request: SearchAccountsRequest): Observable<Array<AccountModel>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .get<Array<AccountModel>>(
            `${this.url}/Search?${request.parameters}`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  getById(accountId: string): Observable<AccountModel> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .get<AccountModel>(`${this.url}/${accountId}`, httpOptions)
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  balance(accountId: string): Observable<AccountBalanceModel> {
    return this.api
      .get<AccountBalanceModel>(`${this.url}/${accountId}/Balance`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //#region [ Natural ]

  createPN(request: CreateAccountNaturalRequest): Observable<Payload<string>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<string>>(`${this.url}/Natural`, request, httpOptions)
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  updatePN(
    accountId: string,
    request: UpdateAccountNaturalRequest
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .put<Payload<any>>(
            `${this.url}/${accountId}/Natural`,
            request,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  //#endregion  [ Natural ]

  //#region [ Juridical ]

  createPJ(
    request: CreateAccountJuridicalRequest
  ): Observable<Payload<string>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<string>>(`${this.url}/Juridical`, request, httpOptions)
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  updatePJ(
    accountId: string,
    request: UpdateAccountJuridicalRequest
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .put<Payload<any>>(
            `${this.url}/${accountId}/Juridical`,
            request,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  //#endregion  [ Juridical ]

  //#region [ Answerables ]

  getAnswerables(
    accountId: string,
    pagination: GetAccountAnswerablesRequest
  ): Observable<PayloadList<AccountAnswerableModel>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .get<PayloadList<AccountAnswerableModel>>(
            `${this.url}/${accountId}/Answerables?${pagination.parameters}`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  getAnswerableById(
    accountId: string,
    personId: string
  ): Observable<AccountAnswerableModel> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .get<AccountAnswerableModel>(
            `${this.url}/${accountId}/Answerables/${personId}`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  createAnswerable(
    accountId: string,
    request: CreateAccountAnswerableRequest
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<any>>(
            `${this.url}/${accountId}/Answerables`,
            request,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  createAnswerableB(
    id: string,
    request: CreateAccountAnswerableRequest
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/${id}/Answerables`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateAnswerable(
    accountId: string,
    personId: string,
    request: UpdateAccountAnswerableRequest
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .put<Payload<any>>(
            `${this.url}/${accountId}/Answerables/${personId}`,
            request,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  deleteAnswerable(
    accountId: string,
    personId: string
  ): Observable<Payload<any>> {
    return this.api
      .delete<Payload<any>>(`${this.url}/${accountId}/Answerables/${personId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //#endregion  [ Answerables ]

  start(accountId: string): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<any>>(
            `${this.url}/${accountId}/Start`,
            null,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  acceptTerm(
    accountId: string,
    request: AcceptTermAccountRequest
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<any>>(
            `${this.url}/${accountId}/AcceptTerm`,
            request,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  phoneValidateSend(
    accountId: string,
    phoneId: string
  ): Observable<Payload<number>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<number>>(
            `${this.url}/${accountId}/Phone/${phoneId}/Validate`,
            null,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  phoneValidate(
    accountId: string,
    phoneId: string,
    tokenNumber: string
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<any>>(
            `${this.url}/${accountId}/Phone/${phoneId}/Validate/${tokenNumber}`,
            null,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  contactValidateSend(
    accountId: string,
    contactId: string
  ): Observable<Payload<number>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<number>>(
            `${this.url}/${accountId}/Contact/${contactId}/Validate`,
            null,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  contactValidate(
    accountId: string,
    contactId: string,
    tokenNumber: string
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .post<Payload<any>>(
            `${this.url}/${accountId}/Contact/${contactId}/Validate/${tokenNumber}`,
            null,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
  }

  resetPassword(accountId: string, personId: string): Observable<Payload<any>> {
    return this.api
      .delete<Payload<any>>(
        `${this.url}/${accountId}/People/${personId}/ResetPassword`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  startWorkflow(accountId: string): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/${accountId}/Workflow`, null)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  image(accountId: string, personId: string): string {
    const timestamp = new Date().getTime();
    return this.api.url(
      `${this.url}/${accountId}/People/${personId}/Image?at=${this.api.auth?.access_token}&${timestamp}`
    );
  }

  uploadImage(
    accountId: string,
    personId: string,
    file: File
  ): Observable<Payload<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    const httpOptions = { headers: headers };
    return this.api
      .put<Payload<any>>(
        `${this.url}/${accountId}/People/${personId}/Image`,
        formData,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getFiles(
    accountId: string,
    personId: string,
    pagination: GetAccountPersonFileRequest
  ): Observable<PayloadList<AccountPersonFileModel>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api
          .get<PayloadList<AccountPersonFileModel>>(
            `${this.url}/${accountId}/People/${personId}/Files?${pagination.parameters}`,
            httpOptions
          )
          .pipe(
            map((response) => {
              return response;
            })
          );
      })
    );
    // return this.api
    //   .get<PayloadList<AccountPersonFileModel>>(
    //     `${this.url}/${accountId}/People/${personId}/Files?${pagination.parameters}`
    //   )
    //   .pipe(
    //     map((response) => {
    //       return response;
    //     })
    //   );
  }

  file(fileId: string): string {
    const timestamp = new Date().getTime();
    return this.api.url(
      `${this.url}/People/Files/${fileId}?at=${this.api.auth?.access_token}&${timestamp}`
    );
  }

  uploadFile(
    accountId: string,
    personId: string,
    name: string,
    file: File
  ): Observable<Payload<any>> {
    return this.api.token().pipe(
      switchMap((token) => {
        const formData: FormData = new FormData();
        formData.append('name', name);
        formData.append('file', file, file.name);
        const headers = new HttpHeaders().set(
          AuthenticationService.TOKEN_HEADER_KEY,
          `${token.token_type} ${token.access_token}`
        );
        const httpOptions = { headers: headers };
        return this.api.put<Payload<any>>(
          `${this.url}/${accountId}/People/${personId}/Files`,
          formData,
          httpOptions
        );
      })
    );
  }

  deleteFile(fileId: string): Observable<Payload<any>> {
    return this.api.delete<Payload<any>>(`${this.url}/People/Files/${fileId}`);
  }

  getTransactions(
    accountId: string,
    pagination: GetAccountTransactionsRequest
  ): Observable<PayloadList<AccountTransactionModel>> {
    return this.api.get<PayloadList<AccountTransactionModel>>(
      `${this.url}/${accountId}/Transactions?${pagination.parameters}`
    );
  }

  exportTransactions(
    accountId: string,
    type: string,
    query: ExportAccountTransactionsRequest
  ): Observable<Blob> {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.api.get<Blob>(
      `${this.url}/${accountId}/Transactions/Export/${type}?${query.parameters}`,
      httpOptions
    );
  }

  sendToken(accountId: string): Observable<Payload<number>> {
    return this.api
      .post<Payload<number>>(`${this.url}/${accountId}/Token`, null)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getLimits(accountId: string): Observable<AccountLimitsModel> {
    return this.api
      .get<AccountLimitsModel>(`${this.url}/${accountId}/Limits`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateLimits(
    accountId: string,
    request: UpdateAccountLimitsRequest
  ): Observable<Payload<any>> {
    return this.api
      .put<Payload<any>>(`${this.url}/${accountId}/Limits`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getCredentials(
    accountId: string,
    pagination: GetAccountCredentialsRequest
  ): Observable<PayloadList<AccountCredentialModel>> {
    return this.api
      .get<PayloadList<AccountCredentialModel>>(
        `${this.url}/${accountId}/Credentials?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  createCredential(
    accountId: string,
    request: CreateAccountCredentialRequest
  ): Observable<Payload<AccountCredentialModel>> {
    return this.api
      .post<Payload<AccountCredentialModel>>(
        `${this.url}/${accountId}/Credentials`,
        request
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteCredential(userId: string): Observable<Payload<any>> {
    return this.api
      .delete<Payload<any>>(`${this.url}/Credentials/${userId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getWebhooks(
    accountId: string,
    pagination: GetAccountWebhooksRequest
  ): Observable<PayloadList<AccountWebhookModel>> {
    return this.api
      .get<PayloadList<AccountWebhookModel>>(
        `${this.url}/${accountId}/Webhooks?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  updateWebhook(
    webhookId: string,
    model: UpdateAccountWebhookRequest
  ): Observable<Payload<any>> {
    return this.api
      .put<Payload<any>>(`${this.url}/Webhooks/${webhookId}`, model)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  downloadCertificate(accountId: string): Observable<Blob> {
    const httpOptions = { responseType: 'blob' as 'json' };
    return this.api.get<Blob>(
      `${this.url}/${accountId}/Webhooks/Certificate/Download`,
      httpOptions
    );
  }

  getAcquirerTransactions(
    accountId: string,
    pagination: GetAccountAcquirerTransactionsRequest
  ): Observable<PayloadList<AccountAcquirerTransactionModel>> {
    return this.api
      .get<PayloadList<AccountAcquirerTransactionModel>>(
        `${this.url}/${accountId}/Acquirers/Transactions?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAcquirerSchedules(
    accountId: string,
    pagination: GetAccountAcquirerTransactionSchedulesRequest
  ): Observable<PayloadList<AccountAcquirerTransactionScheduleModel>> {
    return this.api
      .get<PayloadList<AccountAcquirerTransactionScheduleModel>>(
        `${this.url}/${accountId}/Acquirers/Schedules?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Lista de chaves PIX
   * @param accountId
   * @param pagination
   * @returns
   */
  getPIXKeys(
    accountId: string,
    pagination: GetAccountPIXKeysRequest
  ): Observable<PayloadList<AccountPIXKeyModel>> {
    return this.api
      .get<PayloadList<AccountPIXKeyModel>>(
        `${this.url}/${accountId}/PIX/Keys?${pagination.parameters}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Cria uma nova chave PIX
   * @param accountId
   * @param request
   * @returns
   */
  createPIXKey(
    accountId: string,
    request: CreateAccountPIXKeyRequest
  ): Observable<Payload<string>> {
    return this.api
      .post<Payload<string>>(`${this.url}/${accountId}/PIX/Keys`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * @author Henrique Rodrigues
   * @version 1.0.0
   * Deleta a chave PIX
   * @param pixKeyId
   * @returns
   */
  deletePIXKey(pixKeyId: string): Observable<Payload<any>> {
    return this.api
      .delete<Payload<any>>(`${this.url}/PIX/Keys/${pixKeyId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   *
   * @param accountId
   * @returns
   */
  getConfig(accountId: string): Observable<AccountConfigModel> {
    return this.api
      .get<AccountConfigModel>(`${this.url}/${accountId}/Config`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Connecta o equipamento a conta
   * @param accountId
   * @returns
   */
  connectDevice(
    accountId: string,
    request: ConnectDeviceAccountRequest
  ): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/${accountId}/Devices`, request)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMessages(
    accountId: string,
    pagination: GetMessagesRequest
  ): Observable<PayloadList<MessageModel>> {
    return this.api.get<PayloadList<MessageModel>>(
      `${this.url}/${accountId}/Messages?${pagination.parameters}`
    );
  }

  markMessageRead(messageId: string): Observable<Payload<any>> {
    return this.api
      .post<Payload<any>>(`${this.url}/Messages/${messageId}`, null)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteMessage(messageId: string): Observable<Payload<any>> {
    return this.api.delete<Payload<any>>(`${this.url}/Messages/${messageId}`);
  }
}
