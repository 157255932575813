export class Pagination {
  pageNumber: number = 1;
  pageSize: number = 20;
  sort: string | null = null;
  search: string | null = null;

  get parameters(): string {
    let params = `pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`;
    if (this.sort) params += `&sort=${this.sort}`;
    if (this.search) params += `&search=${this.search}`;
    return params;
  }
}
