<ul class="nav nav-buttons">
  <ng-container *ngFor="let item of menus" [ngTemplateOutlet]="internalMenuItemRef"
    [ngTemplateOutletContext]="{$implicit: item, idx: i}"></ng-container>
</ul>

<ng-template #internalMenuItemRef let-item let-idx="idx">
  <li class="nav-item" ngbTooltip="{{item.name | translate}}" [check-role]="item.roles"
    *ngIf="item.enabled && (item.routerLink || item.click)">
    <a class="nav-link" aria-current="page" [routerLink]="[item.routerLink]" *ngIf="item.routerLink">
      <div class="card">
        <div class="card-header">
          <img [src]="item.image" class="card-img-top">
        </div>
        <div class="card-body">
          {{item.name | translate}}
        </div>
      </div>
    </a>

    <a class="nav-link" aria-current="page" role="button" (click)="item.click()" *ngIf="item.click">
      <div class="card">
        <div class="card-header">
          <img [src]="item.image" class="card-img-top">
        </div>
        <div class="card-body">
          {{item.name | translate}}
        </div>
      </div>
    </a>
  </li>
</ng-template>
