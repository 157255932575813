import { IndexedDB } from '@app/core/indexeddb';
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { merge } from 'lodash';
import { Register } from '../models';

function metaReducer(reducer: ActionReducer<Register>): ActionReducer<any> {
  let onInit = true;
  return (state: Register, action: Action): any => {
    const nextState = reducer(state, action);
    if (onInit) {
      onInit = false;
      const savedState = IndexedDB.register;
      return merge(nextState, savedState);
    }
    IndexedDB.set(
      'register',
      '709b5982-5080-4b34-a662-f385ab8e6be4',
      nextState
    );
    return nextState;
  };
}

export const registerMetaReducers: MetaReducer<any>[] = [];
